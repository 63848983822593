import {
  GET_CLAIMS_FAIL,
  GET_CLAIMS_SUCCESS,
  ADD_CLAIM_SUCCESS,
  ADD_CLAIM_FAIL,
  UPDATE_CLAIM_SUCCESS,
  UPDATE_CLAIM_FAIL,
  DELETE_CLAIM_SUCCESS,
  DELETE_CLAIM_FAIL,
} from './actionTypes';

const INIT_STATE = {
  products: [],
  product: {},
  claims: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true,
};

const Claim = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLAIMS_SUCCESS:
      return {
        ...state,
        claims: action.payload,
        loading: true,
      };

    case GET_CLAIMS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_CLAIM_SUCCESS:
      return {
        ...state,
        claims: [...state.claims, action.payload],
      };

    case ADD_CLAIM_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_CLAIM_SUCCESS:
      return {
        ...state,
        claims: state.claims.map((claim) =>
          claim.id + '' === action.payload.id + ''
            ? { claim, ...action.payload }
            : claim,
        ),
      };

    case UPDATE_CLAIM_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_CLAIM_SUCCESS:
      return {
        ...state,
        claims: state.claims.filter((claim) => claim.id !== action.payload),
      };

    case DELETE_CLAIM_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Claim;

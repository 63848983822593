import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { useTranslation } from 'react-i18next';

import {
  addNewGroup as onAddNewGroup,
  updateGroup as onUpdateGroup,
} from 'store/actions';

import { useMutation, useQueryClient } from 'react-query';
import { BillingName, Date, PaymentStatus } from './MemberTransferCol';

//redux
import { useDispatch, useSelector } from 'react-redux';
import GroupsModal from './MembersModal';

import { getAssignedTransfersApi } from 'api/group';
import { getTransferStatusCountsApi } from 'api/report';
import MainFilter from 'components/Common/MainFilter';
import { useAuth } from 'hooks/useAuth';
import { useRole } from 'hooks/useRole';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { setTransferSummary } from 'store/actions';
import { getSearchColumns, sumArray } from 'utils/tools';

function Group() {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { transferSummary } = useSelector((state) => ({
    transferSummary: state.reports.transfers,
  }));
  const {
    isFacilitator,
    isSiteCord,
    isCountyCoordinator,
    isClusterLeader,
    isFinance,
    isDirector,
    isAdmin,
    isSuperAdmin,
    isData,
    addresses,
  } = useRole();

  const [addressData, setAddressData] = useState({});

  const queryClient = useQueryClient();
  const dateFormat = 'DD-MM-YYYY';
  const [pagination, setPagination] = useState({
    pageSize: 10000,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: false,
    wardIds: getSearchColumns()?.find((t) => t.name === 'wardIds')?.value,
    searchColumns: [
      {
        name: 'endDate',
        value: moment().format(dateFormat),
      },
      {
        name: 'startDate',
        value: moment().startOf('month').format(dateFormat),
      },
    ],
  });

  const {
    mutate: mutateTransferCounts,
    isLoading: isLoadingCounts,
    data,
  } = useMutation((payload) => getTransferStatusCountsApi(payload), {
    onSuccess: (res) => {
      dispatch(setTransferSummary(res?.data));
    },
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateTransfers,
    isLoading: isLoadingGroups,
    data: memberTransfersData,
  } = useMutation((payload) => getAssignedTransfersApi(payload), {
    onSuccess: (res) => {
      res.pages > 0 && setPages(res.pages);
    },
    onSettled: () => {
      setLoading(false);
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const groupsCardData = [
    {
      title: 'New Transfer Requests',
      iconClass: 'bx bx-group',
      description: transferSummary.length
        ? sumArray(transferSummary, 'totalTransferRequests').toLocaleString()
        : 0,
    },
    {
      title: 'Approved Requests',
      iconClass: 'bx bx-trophy',
      description: transferSummary.length
        ? sumArray(transferSummary, 'totalApprovedRequests').toLocaleString()
        : 0,
    },
    {
      title: 'Rejected Requests',
      iconClass: 'bx bx-dislike',
      description: transferSummary.length
        ? sumArray(transferSummary, 'totalRejectedRequests').toLocaleString()
        : 0,
    },
  ];

  //meta title
  document.title = 'Groups | SUN Welfare Administration System';

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [order, setGroup] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: order?.orderId || '',
      billingName: order?.billingName || '',
      orderdate: order?.orderdate || '',
      total: order?.total || '',
      paymentStatus: order?.paymentStatus || 'Paid',
      badgeclass: order?.badgeclass || 'success',
      paymentMethod: order?.paymentMethod || 'Mastercard',
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Group Id')
        .required('Please Enter Your Group Id'),
      billingName: Yup.string().required('Please Enter Your Billing Name'),
      orderdate: Yup.string().required('Please Enter Your Group Date'),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Amount')
        .required('Total Amount'),
      paymentStatus: Yup.string().required('Please Enter Your Payment Status'),
      badgeclass: Yup.string().required('Please Enter Your Badge Class'),
      paymentMethod: Yup.string().required('Please Enter Your Payment Method'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateGroup = {
          id: order?.id || 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        dispatch(onUpdateGroup(updateGroup));
        validation.resetForm();
      } else {
        const newGroup = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values['orderId'],
          billingName: values['billingName'],
          orderdate: values['orderdate'],
          total: values['total'],
          paymentStatus: values['paymentStatus'],
          paymentMethod: values['paymentMethod'],
          badgeclass: values['badgeclass'],
        };
        // save new order
        dispatch(onAddNewGroup(newGroup));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {
    mutateTransferCounts({
      fromDate: moment().startOf('month').format('DD-MM-YYYY'),
      toDate: moment().format('DD-MM-YYYY'),
      address: addressData,
    });
  }, [addressData]);

  const getAreaIds = (key) =>
    getSearchColumns()?.find((t) => t.name === key)?.value;

  useEffect(() => {
    mutateTransfers({
      areaId: isFacilitator
        ? getAreaIds('wardIds')
        : isCountyCoordinator
        ? getAreaIds('countyId')
        : isClusterLeader
        ? getAreaIds('regionId')
        : isSiteCord
        ? getAreaIds('constituencyId')
        : '',
    });
  }, [pagination]);

  const onChangePagination = ({ page, limit }, filters, sorter) => {
    let searchColumns = isEmpty(filters)
      ? pagination.searchColumns
      : [
          ...pagination.searchColumns,
          filters?.id && {
            id: filters?.id,
          },
          filters?.applicationNumber && {
            applicationNumber: filters?.applicationNumber,
          },
        ];
    setPagination({
      ...pagination,
      pageNumber: page || pagination.pageNumber,
      pageSize: limit || pagination.pageSize,
      searchColumns,
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setGroup(null);
    } else {
      setModal(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
      },
      {
        Header: 'DATE',
        accessor: 'requestDateTime',
        filterable: true,
        Cell: (cellProps) => {
          const dateValue = cellProps.value;
          const formattedDate = dateValue
            ? moment(dateValue).format('YYYY MMM DD h:mm A')
            : '';
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: 'CURRENT GROUP',
        accessor: 'fromGroupName',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'FROM WARD',
        accessor: 'fromAddress.wardName',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'TRANSFER TO',
        accessor: 'toGroupName',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'TO WARD',
        accessor: 'destAddress.wardName',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'REASON',
        accessor: 'reason',
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: 'MEMBER NAME',
        accessor: 'memberName',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        },
      },
    ],
    [],
  );

  const onSearch = (data) => {
    const searchColumns = [];
    if (!isEmpty(data)) {
      if (isFacilitator) {
        searchColumns.push({ name: 'wardId', value: address?.wardId });
      }
      if (isSiteCord && data.wardId) {
        searchColumns.push(data?.wardId);
      } else {
        if (
          (isClusterLeader ||
            isFinance ||
            isDirector ||
            isAdmin ||
            isSuperAdmin) &&
          data?.regionId
        ) {
          searchColumns.push(data?.regionId);
        }
        if (data.wardId) {
          searchColumns.push(data?.wardId);
        }
        if (data.constituencyId) {
          searchColumns.push(data?.constituencyId);
        }
        if (data.countyId) {
          searchColumns.push(data?.countyId);
        }
      }
      data.startDate && searchColumns.push(data.startDate);
      data.endDate && searchColumns.push(data.endDate);
    }

    setPagination({ ...pagination, searchColumns });
    let add = {};
    searchColumns.forEach((a, i) => {
      add[a.name] = a.value;
    });
    setAddressData(add);
  };

  return (
    <React.Fragment>
      <GroupsModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Member Transfers" />
          <Row>
            {/* Reports Render */}
            {groupsCardData.map((report, key) => (
              <Col md="4" key={'_col_' + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{report.title}</p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              'bx ' + report.iconClass + ' font-size-24'
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <MainFilter onSearch={onSearch} showDateFilter />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={memberTransfersData || []}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    hasCreate={!isData}
                    hasSearch
                    pages={pages}
                    isPagination={true}
                    filterable={false}
                    iscustomPageSizeOptions={true}
                    // showEdit
                    loading={isLoadingGroups}
                    showView
                    resource="memberTransfer"
                    addText="New Transfer"
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    paginationData={pagination}
                    onChangePagination={onChangePagination}
                    searchByNames={[
                      {
                        value: 'email',
                        text: t('common.email'),
                      },
                      {
                        value: 'idNumber',
                        text: t('common.idNumber'),
                      },
                      {
                        value: 'applicationNumber',
                        text: t('common.applicationNumber'),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? 'Edit Group' : 'Add Group'}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Group Id</Label>
                      <Input
                        name="orderId"
                        type="text"
                        placeholder="Insert Group Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.orderId || ''}
                        invalid={
                          validation.touched.orderId &&
                          validation.errors.orderId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.orderId &&
                      validation.errors.orderId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.orderId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ''}
                        invalid={
                          validation.touched.billingName &&
                          validation.errors.billingName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.billingName &&
                      validation.errors.billingName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.billingName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Group Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="orderdate"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) =>
                          validation.setFieldValue(
                            'orderdate',
                            moment(date[0]).format('DD MMMM, YYYY'),
                          )
                        }
                        value={validation.values.orderdate}
                      />
                      {validation.touched.orderdate &&
                      validation.errors.orderdate ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.orderdate}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ''}
                        invalid={
                          validation.touched.total && validation.errors.total
                            ? true
                            : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">
                          {validation.errors.total}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentStatus || ''}
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus &&
                      validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentStatus}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ''}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass &&
                      validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.badgeclass}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentMethod || ''}
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod &&
                      validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentMethod}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Group.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default Group;

import {
  GET_COMMUNICATIONS,
  GET_COMMUNICATIONS_FAIL,
  GET_COMMUNICATIONS_SUCCESS,
  ADD_NEW_COMMUNICATION,
  ADD_COMMUNICATION_SUCCESS,
  ADD_COMMUNICATION_FAIL,
  UPDATE_COMMUNICATION,
  UPDATE_COMMUNICATION_SUCCESS,
  UPDATE_COMMUNICATION_FAIL,
  DELETE_COMMUNICATION,
  DELETE_COMMUNICATION_SUCCESS,
  DELETE_COMMUNICATION_FAIL,
} from './actionTypes';

export const getCommunications = () => ({
  type: GET_COMMUNICATIONS,
});

export const getCommunicationsSuccess = (orders) => ({
  type: GET_COMMUNICATIONS_SUCCESS,
  payload: orders,
});

export const getCommunicationsFail = (error) => ({
  type: GET_COMMUNICATIONS_FAIL,
  payload: error,
});

export const addNewCommunication = (order) => ({
  type: ADD_NEW_COMMUNICATION,
  payload: order,
});

export const addCommunicationSuccess = (order) => ({
  type: ADD_COMMUNICATION_SUCCESS,
  payload: order,
});

export const addCommunicationFail = (error) => ({
  type: ADD_COMMUNICATION_FAIL,
  payload: error,
});

export const updateCommunication = (order) => ({
  type: UPDATE_COMMUNICATION,
  payload: order,
});

export const updateCommunicationSuccess = (order) => ({
  type: UPDATE_COMMUNICATION_SUCCESS,
  payload: order,
});

export const updateCommunicationFail = (error) => ({
  type: UPDATE_COMMUNICATION_FAIL,
  payload: error,
});

export const deleteCommunication = (order) => ({
  type: DELETE_COMMUNICATION,
  payload: order,
});

export const deleteCommunicationSuccess = (order) => ({
  type: DELETE_COMMUNICATION_SUCCESS,
  payload: order,
});

export const deleteCommunicationFail = (error) => ({
  type: DELETE_COMMUNICATION_FAIL,
  payload: error,
});

import { get, post } from './utils';

export async function getAllRegionsApi(data) {
  return get(`/api/public/um/region`);
}

export async function getAllCountiesApi(data) {
  return get(`/api/public/um/county/country`, data);
}

export async function getAllCountiesByRegionApi(data) {
  return get(`/api/public/um/county`, data);
}

export async function getConstituencyApi(data) {
  return get(`/api/public/um/constituency`, data);
}

export async function getAllWardsApi(data) {
  return get(`/api/public/um/ward`, data);
}
export async function getAllRelationshipsApi(data) {
  return get(`/api/public/um/setup/relationship`, data);
}

export async function getMemberStatusCountsApi(data) {
  return post(`/api/report/member/counts`, data);
}

export async function getDependentsStatusCountsApi(data) {
  return post(`/api/report/member/dependentsApplications`, data);
}

export async function getGroupStatusCountsApi(data) {
  return post(`/api/report/group/counts`, data);
}

export async function getPaymentsStatusCountsApi(data) {
  return post(`/api/report/transaction/counts`, data);
}

export async function getClaimStatusCountsApi(data) {
  return post(`/api/report/claim/counts`, data);
}

export async function getApplicationStatusCountsApi(data) {
  return post(`/api/report/application/status/counts`, data);
}

export async function getTransferStatusCountsApi(data) {
  return post(`/api/report/transfer/status/counts`, data);
}

export async function getInvoicesCountsApi(data) {
  return post(`/api/report/transaction/invoices/counts`, data);
}

export async function getCommunicationCountsApi(data) {
  return post(`/api/report/communication/counts`, data);
}

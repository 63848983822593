import {
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  ADD_INVOICE_SUCCESS,
  ADD_INVOICE_FAIL,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAIL,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAIL,
} from './actionTypes';

const INIT_STATE = {
  products: [],
  product: {},
  transactions: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true,
};

const Transaction = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        loading: true,
      };

    case GET_INVOICES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_INVOICE_SUCCESS:
      return {
        ...state,
        transactions: [...state.transactions, action.payload],
      };

    case ADD_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        transactions: state.transactions.map((transaction) =>
          transaction.id + '' === action.payload.id + ''
            ? { transaction, ...action.payload }
            : transaction,
        ),
      };

    case UPDATE_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        transactions: state.transactions.filter(
          (transaction) => transaction.id !== action.payload,
        ),
      };

    case DELETE_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Transaction;

import {
  GET_RISK_CLASSS,
  GET_RISK_CLASSS_FAIL,
  GET_RISK_CLASSS_SUCCESS,
  ADD_NEW_RISK_CLASS,
  ADD_RISK_CLASS_SUCCESS,
  ADD_RISK_CLASS_FAIL,
  UPDATE_RISK_CLASS,
  UPDATE_RISK_CLASS_SUCCESS,
  UPDATE_RISK_CLASS_FAIL,
  DELETE_RISK_CLASS,
  DELETE_RISK_CLASS_SUCCESS,
  DELETE_RISK_CLASS_FAIL,
} from './actionTypes';

export const getRiskClasss = () => ({
  type: GET_RISK_CLASSS,
});

export const getRiskClasssSuccess = (orders) => ({
  type: GET_RISK_CLASSS_SUCCESS,
  payload: orders,
});

export const getRiskClasssFail = (error) => ({
  type: GET_RISK_CLASSS_FAIL,
  payload: error,
});

export const addNewRiskClass = (order) => ({
  type: ADD_NEW_RISK_CLASS,
  payload: order,
});

export const addRiskClassSuccess = (order) => ({
  type: ADD_RISK_CLASS_SUCCESS,
  payload: order,
});

export const addRiskClassFail = (error) => ({
  type: ADD_RISK_CLASS_FAIL,
  payload: error,
});

export const updateRiskClass = (order) => ({
  type: UPDATE_RISK_CLASS,
  payload: order,
});

export const updateRiskClassSuccess = (order) => ({
  type: UPDATE_RISK_CLASS_SUCCESS,
  payload: order,
});

export const updateRiskClassFail = (error) => ({
  type: UPDATE_RISK_CLASS_FAIL,
  payload: error,
});

export const deleteRiskClass = (order) => ({
  type: DELETE_RISK_CLASS,
  payload: order,
});

export const deleteRiskClassSuccess = (order) => ({
  type: DELETE_RISK_CLASS_SUCCESS,
  payload: order,
});

export const deleteRiskClassFail = (error) => ({
  type: DELETE_RISK_CLASS_FAIL,
  payload: error,
});

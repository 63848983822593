import React from 'react';
import ReactApexChart from 'react-apexcharts';
import getChartColorsArray from '../../../components/Common/ChartsDynamicColor';

const chartapex = ({ dataColors }) => {
  const apaexlineChartColors = getChartColorsArray(dataColors);

  const series = [
    { name: 'High - 2023', data: [26, 24, 32, 36] },
    { name: 'Low - 2023', data: [14, 11, 16, 12] },
  ];
  const options = {
    chart: {
      height: 380,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: apaexlineChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: 'straight',
    },
    series: [
      {
        name: 'High - 2024',
        data: [26, 24, 32, 36],
      },
      {
        name: 'Low - 2024',
        data: [14, 11, 16, 12],
      },
    ],
    title: {
      text: 'Final Quater',
      align: 'left',
      style: {
        fontWeight: '500',
      },
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'],
        opacity: 0.2,
      },
      borderColor: '#f1f1f1',
    },
    markers: {
      style: 'inverted',
      size: 6,
    },
    xaxis: {
      categories: ['Sep', 'Oct', 'Nov', 'Dec'],
      title: {
        text: 'Month',
      },
    },
    yaxis: {
      title: {
        text: 'Count',
      },
      min: 5,
      max: 40,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="380"
      className="apex-charts"
    />
  );
};

export default chartapex;

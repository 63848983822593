import React, { useState, useEffect } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormFeedback,
  FormGroup,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import logo from '../../assets/images/logo-dark2.png';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRole } from 'hooks/useRole';
import { getMemberByIdApi } from 'api/member';
import {
  getTransferByIdApi,
  memberTransferApproveApi,
  memberTransferRejectApi,
  getAllGroupApi,
} from 'api/group';
import { useNavigate } from 'react-router-dom';
import { getSearchColumns, sumArray } from 'utils/tools';
import Swal from 'sweetalert2';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const MemberTransferDetails = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [memberData, setMemberData] = useState({});
  const { addresses } = useRole();
  const [transferData, setTransferData] = useState({});
  const { id } = useParams();

  const {
    mutate: mutateGroups,
    isLoading: isLoadingGroups,
    data: groupsData,
  } = useMutation((payload) => getAllGroupApi(payload), {
    onSuccess: (res) => {
      console.log(res);
    },
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  useEffect(() => {
    const fetchTransferDetails = async () => {
      try {
        const response = await getTransferByIdApi(id);
        setTransferData(response);
        const memberResponse = await getMemberByIdApi(response?.memberId);
        setMemberData(memberResponse);
      } catch (error) {
        console.error('Error fetching receipt details:', error);
      }
    };

    fetchTransferDetails();
  }, [id]);

  useEffect(() => {
    addresses?.length &&
      mutateGroups({
        pageSize: 2000,
        pageNumber: 0,
        wardIds: getSearchColumns()?.find((t) => t?.name === 'wardIds')?.value,
        sortBy: 'name',
        searchColumns: [],
      });
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupId: '',
      comments: '',
    },
    validationSchema: Yup.object({
      groupId: Yup.string().required('Please Select Group'),
      comments: Yup.string().required('Please Add Comments'),
    }),
    onSubmit: async (values) => {
      let transferData = {
        transferRequestId: id,
        toGroupId: values.groupId,
        comments: values.comments,
      };

      const res = await memberTransferApproveApi(transferData);
      if (res.status === 'Success') {
        setShowPopup(true);
      } else {
      }
    },
  });

  const handleApprove = async () => {
    try {
      await memberTransferApproveApi({
        transferRequestId: id,
        toGroupId: validation.values.groupId,
        comments: validation.values.comments,
      });
      Swal.fire({
        title: 'Approved!',
        text: 'The member transfer has been approved successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate(`/member-transfers`);
      });
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'There was a problem approving the transfer.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      console.log(error);
    }
  };

  const handleReject = async () => {
    try {
      await memberTransferRejectApi({
        transferRequestId: id,
        toGroupId: validation.values.groupId,
        comments: validation.values.comments,
      });
      Swal.fire({
        title: 'Rejected!',
        text: 'The member transfer has been rejected.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate(`/member-transfers`);
      });
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'There was a problem rejecting the transfer.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      console.log(error);
    }
  };

  const groupOptions =
    groupsData?.list?.map((item) => ({
      value: item.id,
      label: item.name,
    })) || [];

  //meta title
  document.title =
    'Member Transfer Details | SUN Welfare Administration System';

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs title="Receipts" breadcrumbItem="Receipt Details" />
        <Card style={{ maxWidth: '60%', margin: '0 auto' }}>
          <CardBody>
            <Row>
              {/* Logo */}
              <Col md={4}>
                <img
                  src={logo}
                  alt="Company Logo"
                  style={{ maxWidth: '200px' }}
                />
              </Col>
              {/* Title and Date */}
              <Col md={4} className="text-center">
                <h4>TRANSFER REQUEST</h4>
                <h5>STATUS : {transferData?.status}</h5>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="text-center mb-4">
                <div>
                  <h5>{`${memberData.firstName} ${memberData.lastName}`}</h5>
                  <h6>Transfer To:</h6>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div>
                  <h6>Member Information:</h6>

                  <p>Mobile No: {memberData.mobileNumber1}</p>
                  <p>Email Address: {memberData.email}</p>
                  {memberData?.address && (
                    <>
                      <p>Ward: {memberData?.address?.wardName}</p>
                      <p>
                        constituency: {memberData?.address?.constituencyName}
                      </p>
                    </>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <h5>Policy Status: {memberData.status}</h5>
                  <p>Transfer Reason: {transferData.reason}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Form
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label for="groupId">Select Group</Label>
                    <Select
                      id="groupId"
                      name="groupId"
                      options={groupOptions}
                      value={groupOptions.find(
                        (option) => option.value === validation.values.groupId,
                      )}
                      onChange={(selectedOption) =>
                        validation.setFieldValue(
                          'groupId',
                          selectedOption?.value,
                        )
                      }
                      onBlur={validation.handleBlur}
                      className={
                        validation.touched.groupId && validation.errors.groupId
                          ? 'is-invalid'
                          : ''
                      }
                    />
                    {validation.touched.groupId &&
                      validation.errors.groupId && (
                        <FormFeedback type="invalid">
                          {validation.errors.groupId}
                        </FormFeedback>
                      )}
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <div className="mb-3">
                    <FormGroup>
                      <Label for="comments">Comments</Label>
                      <Input
                        name="comments"
                        type="text"
                        className="form-control"
                        id="comments"
                        placeholder="Enter Comments"
                        value={validation.values.comments}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.comments &&
                          validation.errors.comments
                        }
                      />
                      {validation.touched.comments &&
                        validation.errors.comments && (
                          <FormFeedback>
                            {validation.errors.comments}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </div>
                </Col>
                <Col md={12} className="text-end">
                  {transferData?.status === 'New' && (
                    <>
                      <Button
                        type="button"
                        color="primary"
                        className="me-4"
                        onClick={handleApprove}
                      >
                        <i className="bx bx-check"></i> Approve
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        onClick={handleReject}
                      >
                        <i className="bx bx-x"></i> Reject
                      </Button>
                    </>
                  )}
                </Col>
              </Form>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default MemberTransferDetails;

import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Table,
  Input,
  Label,
  UncontrolledTooltip,
  Row,
} from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  MemberId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from './MemberCol';
import { useRole } from 'hooks/useRole';
import { updateMember } from 'api/member';
import { useMutation, useQueryClient } from 'react-query';
import avatar1 from '../../assets/images/users/avatar-1.jpg';
import profileImg from '../../assets/images/profile-img.png';
import { useParams } from 'react-router-dom';
import TableContainer from '../../components/Common/TableContainer';
import { getAllTransactionApi } from 'api/transaction';
import { useNavigate } from 'react-router-dom';
import {
  getMemberByIdApi,
  getNextOfKinByIdApi,
  getDependantsByIdApi,
  editNextOfKin,
} from 'api/member';
import {
  getAllRelationshipsApi,
  getClaimStatusCountsApi,
  getInvoicesCountsApi,
  getPaymentsStatusCountsApi,
} from 'api/report';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import AddDependantModal from './AddDependantModal';
import AddNextOfKinModal from './AddNextOfKinModal';
import Swal from 'sweetalert2';
import { allowedEmails } from 'configs/constants';

const MemberDetails = () => {
  const queryClient = useQueryClient();
  const {
    isFacilitator,
    isSiteCord,
    isCountyCoordinator,
    isClusterLeader,
    isFinance,
    isDirector,
    isAdmin,
    isSuperAdmin,
    isData,
    address,
  } = useRole();
  const [dependent, setDependent] = useState({});
  const [paymentCount, setPaymentCounts] = useState([]);
  const [invoiceCount, setInvoiceCounts] = useState([]);
  const [claimsCounts, setClaimsCounts] = useState([]);
  const [showAddDep, setShowAddDeb] = useState(false);
  const [showAddNext, setShowAddNext] = useState(false);
  const [memberData, setMemberData] = useState({});
  const [nextOfKinData, setNextOfKinData] = useState({});
  const [dependantsData, setDependantsData] = useState([]);
  const [latestTransaction, setLatestTransaction] = useState([]);
  const [pages, setPages] = useState(0);
  const { id } = useParams();
  const [hasEdit, setHasEdit] = useState({});
  const [showEditNextOfKin, setShowEditNextOfKin] = useState(false);
  const navigate = useNavigate();
  const [nextOfKinForm, setNextOfKinForm] = useState({
    memberId: id,
    firstName: '',
    lastName: '',
    dob: moment(nextOfKinData.dob).format('DD-MM-YYYY'),
    gender: '',
    email: '',
    mobileNumber: '',
    relationship: '',
    identificationNumber: '',
  });

  const {
    mutate: mutateRelationships,
    isLoading: isLoadingRelationships,
    data: relationshipsData,
  } = useMutation((payload) => getAllRelationshipsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('/public/um/setup/relationship');
    },
  });
  const { mutate: mutatePaymentCounts, data: paymentCounts } = useMutation(
    (payload) => getPaymentsStatusCountsApi(payload),
    {
      onSuccess: (res) => {
        setPaymentCounts(res.data || []);
      },
      onSettled: () => {
        queryClient.invalidateQueries('get-all-counties');
      },
    },
  );

  const { mutate: mutateClaimCounts, data: claimCounts } = useMutation(
    (payload) => getClaimStatusCountsApi(payload),
    {
      onSuccess: (res) => {
        // setClaimCounts(res.data || []);
      },
      onSettled: () => {
        queryClient.invalidateQueries('get-all-counties');
      },
    },
  );

  const { mutate: mutateInvoiceCounts, data: invoiceCounts } = useMutation(
    (payload) => getInvoicesCountsApi(payload),
    {
      onSuccess: (res) => {
        setInvoiceCounts(res.data || []);
      },
      onSettled: () => {
        queryClient.invalidateQueries('get-all-counties');
      },
    },
  );

  useEffect(() => {
    if (nextOfKinData.length > 0) {
      const kin = nextOfKinData[0];
      setNextOfKinForm({
        memberId: id,
        firstName: kin.firstName,
        lastName: kin.lastName,
        dob: moment(kin.dob, 'DD-MM-YYYY').format('DD-MM-YYYY'),
        gender: kin.gender,
        email: kin.email,
        mobileNumber: kin.mobileNumber,
        relationship: kin.relationship,
      });
    }
  }, [nextOfKinData]);

  const toggleEditNextOfKinModal = () =>
    setShowEditNextOfKin(!showEditNextOfKin);

  const { mutate: submitEditNextOfKin, isLoading: isSubmitting } = useMutation(
    editNextOfKin,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getMemberByIdApi');
        setShowEditNextOfKin(false);
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Request Submitted For Approval',
          timer: 2000,
          showConfirmButton: false,
        });
      },
      onError: (error) => {
        console.error('Error editing next of kin:', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong while updating the next of kin details.',
          footer: 'Please try again later.',
        });
      },
    },
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNextOfKinForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = () => {
    submitEditNextOfKin(nextOfKinForm);
  };

  const fetchData = async () => {
    try {
      // Fetch member details
      const memberResponse = await getMemberByIdApi(id);
      setHasEdit({
        ...hasEdit,
        firstName: { value: memberResponse.firstName },
        middleName: { value: memberResponse.middleName },
        lastName: { value: memberResponse.lastName },
      });
      setMemberData(memberResponse);

      // Fetch next of kin details
      const nextOfKinResponse = await getNextOfKinByIdApi({
        memberId: id,
        status: 'Active',
      });
      setNextOfKinData(nextOfKinResponse);

      // Fetch dependent details
      const dependentResponse = await getDependantsByIdApi(id);
      setDependantsData(dependentResponse);
      setPages(dependentResponse.length);

      // Fetch transactions
      const transactionResponse = await getAllTransactionApi({
        pageSize: 6,
        pageNumber: 0,
        sortBy: 'id',
        searchColumns: [{ name: 'memberId', value: id }],
      });
      setLatestTransaction(transactionResponse?.list);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    mutateRelationships();
    mutateClaimCounts({
      fromDate: moment().startOf('year').format('DD-MM-YYYY'),
      toDate: moment().format('DD-MM-YYYY'),
      memberId: id,
      address: {},
    });
    mutatePaymentCounts({
      fromDate: moment().startOf('year').format('DD-MM-YYYY'),
      toDate: moment().format('DD-MM-YYYY'),
      memberId: id,
      address: {},
    });
    mutateInvoiceCounts({
      fromDate: moment().startOf('year').format('DD-MM-YYYY'),
      toDate: moment().format('DD-MM-YYYY'),
      memberId: id,
      address: {},
    });
  }, [id]);

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: true,
    searchColumns: [],
  });

  //meta title
  document.title = 'Add Member | SUN Welfare Administration System';

  const columns = useMemo(
    () => [
      {
        Header: '#',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: 'FULL NAMES',
        accessor: (row) => `${row.firstName || ''} ${row.lastName || ''}`,
        width: '150px',
        style: {
          textAlign: 'center',
          width: '10%',
          background: '#0000',
        },
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        },
      },
      {
        Header: 'Relationship',
        accessor: 'relationship',
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: 'Age(Yrs)',
        accessor: 'dob',
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          const dateValue = moment(cellProps.value, 'DD-MM-YYYY');
          const formattedDate = cellProps.value
            ? moment().diff(dateValue, 'year')
            : 'No DOB Set';
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: 'Birth Certificate No',
        accessor: 'birthCertificationNumber',
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        hidden: false,
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        },
      },
      {
        Header: 'Phone Number',
        accessor: 'mobileNumber',
        disableFilters: true,
        Cell: (cellProps) => {
          return <PaymentMethod {...cellProps} />;
        },
      },
    ],
    [],
  );

  const Transactioncolumns = useMemo(
    () => [
      {
        Header: '#',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: 'Transaction ID',
        accessor: 'transactionNumber',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <MemberId {...cellProps} />;
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'Date',
        accessor: 'transactionDateTime',
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          const formattedDate = moment(cellProps.value).format('DD-MM-YYYY');
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: 'Payment Status',
        accessor: 'status',
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        },
      },
      {
        Header: 'Payment Method',
        accessor: 'paymentMode',
        disableFilters: true,
        Cell: (cellProps) => {
          return <PaymentMethod {...cellProps} />;
        },
      },
    ],
    [],
  );

  const handleViewTransaction = (record) => {
    if (record.receiptId) {
      navigate(`/receipts/${record.receiptId}`);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Receipt Not Found',
        text: 'The receipt is missing or invalid.',
      });
    }
  };

  const handleShowAddNext = () => {
    setShowAddNext(!showAddNext);
  };

  const handleShowAddDep = () => {
    setDependent({});
    setShowAddDeb(true);
  };

  const editDependent = (data) => {
    setDependent(data);
    setShowAddDeb(true);
  };

  // Calculate total payments
  const totalPremiums = useMemo(() => {
    return paymentCount.reduce((acc, curr) => acc + curr.totalPremiums, 0);
  }, [paymentCount]);

  // Calculate total invoices
  const totalInvoices = useMemo(() => {
    return invoiceCount.reduce((acc, curr) => acc + curr.totalInvoices, 0);
  }, [invoiceCount]);

  // Calculate total claims
  const totalClaims = useMemo(() => {
    return claimsCounts.reduce((acc, curr) => acc + curr.totalClaims, 0);
  }, [claimsCounts]);

  const onEditMember = async (field) => {
    setHasEdit({
      ...hasEdit,
      [field]: { edit: true, value: memberData[field] },
    });
  };

  const handleEdit = async (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setHasEdit({ ...hasEdit, [name]: { edit: true, value } });
  };

  const handleUpdateMember = async (e) => {
    try {
      const data = {
        ...memberData,
        notify: false,
      };
      if (hasEdit?.firstName?.edit) {
        data['firstName'] = hasEdit?.firstName?.value;
      }
      if (hasEdit?.middleName?.edit) {
        data['middleName'] = hasEdit?.middleName?.value;
      }
      if (hasEdit?.lastName?.edit) {
        data['lastName'] = hasEdit?.lastName?.value;
      }
      if (hasEdit?.email?.edit) {
        data['email'] = hasEdit?.email?.value;
      }
      if (hasEdit?.identificationNumber?.edit) {
        data['identificationNumber'] = hasEdit?.identificationNumber?.value;
      }
      if (hasEdit?.mobileNumber1?.edit) {
        data['mobileNumber1'] = hasEdit?.mobileNumber1?.value;
      }
      await updateMember(data)
        .then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Member Updated',
            text: res.message,
          });
          fetchData();
          setHasEdit({});
        })
        .catch((e) => {
          Swal.fire({
            icon: 'error',
            title: 'Member Update Failed.',
            text: e.message || 'An error occurred during update',
          });
        });
    } catch (err) {
      console.error('Error updating member:', err);
    }
  };

  const allowEdit = isAdmin || isSuperAdmin || isData || (allowedEmails.includes(localStorage.getItem('shofco_email')));
  const allowKinEdit = isAdmin || isSuperAdmin || isData;

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs title="Members" breadcrumbItem="Member Details" />
        <Row>
          <Col xl="4" className="mt-4">
            <Link to="/members">
              <Button type="button" color="primary" className="btn">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
            <Card>
              <CardBody>
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">SUN WELFARE</h5>
                        <p>Member Details</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Full Name :</th>
                        <td>
                          {!hasEdit?.firstName?.edit &&
                            !hasEdit?.middleName?.edit &&
                            !hasEdit?.lastName?.edit ? (
                            `${memberData?.firstName || ''} ${memberData?.middleName || ''
                            } ${!memberData.lastName ||
                              memberData.lastName === 'NULL'
                              ? ''
                              : memberData.lastName
                            }`
                          ) : (
                            <div>
                              {allowEdit && (
                                <FormGroup className="mb-2">
                                  <Input
                                    name="firstName"
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    placeholder="First Name"
                                    onChange={handleEdit}
                                    value={hasEdit?.firstName?.value || ''}
                                  />
                                </FormGroup>
                              )}
                              {allowEdit && (
                                <FormGroup className="mb-2">
                                  <Input
                                    name="middleName"
                                    type="text"
                                    className="form-control"
                                    id="middleName"
                                    placeholder="Middle Name"
                                    onChange={handleEdit}
                                    value={hasEdit?.middleName?.value || ''}
                                  />
                                </FormGroup>
                              )}
                              {allowEdit && (
                                <FormGroup className="mb-2">
                                  <Input
                                    name="lastName"
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    placeholder="Last Name"
                                    onChange={handleEdit}
                                    value={hasEdit?.lastName?.value || ''}
                                  />
                                </FormGroup>
                              )}
                            </div>
                          )}
                          {allowEdit && (
                            <Button
                              color="link"
                              className="text-success p-0 m-0"
                              onClick={() => {
                                onEditMember('firstName');
                                onEditMember('middleName');
                                onEditMember('lastName');
                              }}
                            >
                              <i
                                className="mdi mdi-pencil font-size-18"
                                id="editFullNameTooltip"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="editFullNameTooltip"
                              >
                                Edit Full Name
                              </UncontrolledTooltip>
                            </Button>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">SHOFCO ID :</th>
                        <td>{memberData?.shofcoId || ''}</td>
                      </tr>
                      <tr>
                        <th scope="row">ID Number:</th>
                        <td>
                          {!hasEdit?.identificationNumber?.edit ? (
                            memberData?.identificationNumber || ''
                          ) : (
                            <div />
                          )}
                          {hasEdit?.identificationNumber?.edit && allowEdit ? (
                            <FormGroup className="mb-3">
                              <Input
                                name="identificationNumber"
                                type="text"
                                className="form-control"
                                id="identificationNumber"
                                placeholder="Enter Id Number"
                                onChange={handleEdit}
                                value={hasEdit?.identificationNumber?.value}
                              />
                            </FormGroup>
                          ) : (
                            <div />
                          )}
                        </td>
                        {allowEdit && (
                          <Button
                            color="link"
                            className="text-success p-0 m-0"
                            onClick={() => onEditMember('identificationNumber')}
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="edittooltip"
                            >
                              Edit
                            </UncontrolledTooltip>
                          </Button>
                        )}
                      </tr>
                      <tr>
                        <th scope="row">Mobile :</th>
                        <td>
                          {!hasEdit?.mobileNumber1?.edit ? (
                            memberData?.mobileNumber1
                          ) : (
                            <div />
                          )}
                          {hasEdit?.mobileNumber1?.edit && allowEdit ? (
                            <FormGroup className="mb-3">
                              <Input
                                name="mobileNumber1"
                                type="text"
                                className="form-control"
                                id="mobileNumber1"
                                placeholder="Enter Primary Mobile Number"
                                onChange={(e) => handleEdit(e)}
                                value={hasEdit?.mobileNumber1?.value}
                              />
                            </FormGroup>
                          ) : (
                            <div />
                          )}
                        </td>
                        {allowEdit && (
                          <Button
                            color="link"
                            className="text-success p-0 m-0"
                            onClick={() => onEditMember('mobileNumber1')}
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="edittooltip"
                            >
                              Edit
                            </UncontrolledTooltip>
                          </Button>
                        )}
                      </tr>
                      <tr>
                        <th scope="row">E-mail :</th>
                        <td>
                          {!hasEdit?.email?.edit ? memberData?.email : <div />}
                          {hasEdit?.email?.edit && allowEdit ? (
                            <FormGroup className="mb-3">
                              <Input
                                name="email"
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Enter Email"
                                onChange={(e) => handleEdit(e)}
                                value={hasEdit?.email?.value || ''}
                              />
                            </FormGroup>
                          ) : (
                            <div />
                          )}
                        </td>
                        {allowEdit && (
                          <Button
                            color="link"
                            className="text-success p-0 m-0"
                            onClick={() => onEditMember('email')}
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="edittooltip"
                            >
                              Edit
                            </UncontrolledTooltip>
                          </Button>
                        )}
                      </tr>
                      <tr>
                        <th scope="row">Group Name :</th>
                        <td>{memberData?.groupName || ''}</td>
                      </tr>
                      <tr>
                        <th scope="row">Location :</th>
                        <td>
                          {memberData?.addresses
                            ? `${memberData?.addresses?.[0].wardName}, ${memberData?.addresses?.[0].constituencyName}`
                            : ''}
                        </td>
                      </tr>
                      {!isEmpty(hasEdit) && allowEdit && (
                        <tr>
                          <td />
                          <td rowSpan={2}>
                            <Button
                              block
                              type="button"
                              color="primary"
                              onClick={handleUpdateMember}
                            >
                              SAVE
                            </Button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Row>
                    <Col md="8">
                      <div className="mb-4 h4 card-title">
                        Next Of Kin Information
                      </div>
                    </Col>
                    <Col md="4">
                      {allowKinEdit && <Button
                        block
                        type="button"
                        color="primary"
                        onClick={toggleEditNextOfKinModal}
                      >
                        Edit Next of Kin
                      </Button>
                      }
                    </Col>
                  </Row>
                  <Col>
                    {!nextOfKinData.length && (
                      <Button
                        block
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-10"
                        onClick={handleShowAddNext}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add Next Of Kin
                      </Button>
                    )}
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      {nextOfKinData.length > 0 ? (
                        <>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{`${nextOfKinData[0]?.firstName || ''} ${nextOfKinData[0]?.lastName || ''
                              }`}</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{nextOfKinData[0].mobileNumber || ''}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{nextOfKinData[0].email || ''}</td>
                          </tr>
                          <tr>
                            <th scope="row">Relationship :</th>
                            <td>{nextOfKinData[0].relationship || ''}</td>
                          </tr>
                          <tr>
                            <th scope="row">Status :</th>
                            <td>{nextOfKinData[0].status || ''}</td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td colSpan="2">No Next of Kin data available</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl="8" className="mt-4">
            <Row>
              <Col md="4">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Invoices</p>
                        <h4 className="mb-0">{totalInvoices}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className="bx bx-file font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Total Payment</p>
                        <h4 className="mb-0">
                          KES. {totalPremiums.toFixed(2)}
                        </h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className="bx bx-dollar-circle font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Claims</p>
                        <h4 className="mb-0">{totalClaims}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className="bx bx-briefcase-alt font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <Row className="justify-between">
                  <Col>
                    <div className="mb-4 h4 card-title">
                      Dependants Information
                    </div>
                  </Col>
                  <Col>
                    <Button
                      block
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={handleShowAddDep}
                    >
                      <i className="mdi mdi-plus me-1" />
                      Add Dependant
                    </Button>
                  </Col>
                </Row>
                <TableContainer
                  columns={columns}
                  pages={pages}
                  data={dependantsData || []}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isPagination={true}
                  iscustomPageSizeOptions={false}
                  customPageSize={4}
                  showEdit
                  showDelete
                  handleEdit={editDependent}
                  showView
                  paginationData={pagination}
                  onChangePagination={() => { }}
                  pagination="pagination pagination-rounded justify-content-end mb-2"
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="mb-4 h4 card-title">Latest Transaction</div>
                <TableContainer
                  columns={Transactioncolumns}
                  pages={pages}
                  data={latestTransaction}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isPagination={true}
                  iscustomPageSizeOptions={false}
                  customPageSize={4}
                  showView
                  handleView={handleViewTransaction}
                  paginationData={pagination}
                  onChangePagination={() => { }}
                  pagination="pagination pagination-rounded justify-content-end mb-2"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <AddDependantModal
          member={memberData}
          relationships={relationshipsData}
          isNew={dependent && dependent.dependentId ? false : true}
          initData={dependent}
          setVisible={() => setShowAddDeb(!showAddDep)}
          visible={showAddDep}
        />
        <AddNextOfKinModal
          member={memberData}
          relationships={relationshipsData}
          isNew={nextOfKinData && nextOfKinData.nextOfKinId ? false : true}
          initData={{}}
          setVisible={handleShowAddNext}
          visible={showAddNext}
        />
        <Modal isOpen={showEditNextOfKin} toggle={toggleEditNextOfKinModal}>
          <ModalHeader toggle={toggleEditNextOfKinModal}>
            Edit Next of Kin
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={nextOfKinForm.firstName}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={nextOfKinForm.lastName}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="dob">Date of Birth</Label>
                <Input
                  type="date"
                  name="dob"
                  id="dob"
                  value={nextOfKinForm?.dob}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="gender">Gender</Label>
                <Input
                  type="select"
                  name="gender"
                  id="gender"
                  value={nextOfKinForm.gender}
                  onChange={handleInputChange}
                >
                  <option>Male</option>
                  <option>Female</option>
                  <option>Intersex</option>
                  <option>Other</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={nextOfKinForm.email}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="mobileNumber">Mobile Number</Label>
                <Input
                  type="text"
                  name="mobileNumber"
                  id="mobileNumber"
                  value={nextOfKinForm.mobileNumber}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="gender">Gender</Label>
                <Input
                  type="select"
                  name="relationship"
                  id="relationship"
                  value={nextOfKinForm.relationship}
                  onChange={handleInputChange}
                >
                  <option>Mother</option>
                  <option>Father</option>
                  <option>Son</option>
                  <option>Daughter</option>
                  <option>In-law</option>
                  <option>Brother</option>
                  <option>Sister</option>
                  <option>Uncle</option>
                  <option>Aunt</option>
                  <option>Grandparent</option>
                  <option>Cousin</option>
                  <option>Other</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="relationship">identification Number</Label>
                <Input
                  type="number"
                  name="identificationNumber"
                  id="identificationNumber"
                  value={nextOfKinForm.identificationNumber}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : 'Save Changes'}
            </Button>
            <Button color="secondary" onClick={toggleEditNextOfKinModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  );
};

export default MemberDetails;

import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-dark2.png';
import { useMutation, useQueryClient } from 'react-query';
import {
  getClaimDetailsByIdApi,
  approveClaimApi,
  rejectClaimApi,
  getClaimAttachmentsByIdApi,
} from 'api/claim';
import { useAuth } from 'hooks/useAuth';

const ClaimDetail = () => {
  const { id } = useParams();
  const roleName = localStorage.getItem('shofco_role');
  const roleId = localStorage.getItem('shofco_role_id');
  const queryClient = useQueryClient();
  const [attachmentData, setAttachmentData] = useState([]);
  const [comments, setComments] = useState('');
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const {
    mutate: mutateClaim,
    isLoading: isLoadingApplication,
    data: claimData,
  } = useMutation((payload) => getClaimDetailsByIdApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  useEffect(() => {
    const fetchClaimData = async () => {
      try {
        mutateClaim({
          claimId: id,
        });
        const attachmentsResponse = await getClaimAttachmentsByIdApi(id);
        setAttachmentData(attachmentsResponse);
      } catch (error) {
        console.error('Error fetching claim details:', error);
      }
    };

    fetchClaimData();
  }, [id, mutateClaim]);

  const {
    mutate: mutateApproveClaim,
    isLoading: isLoadingApprove,
    data: approveData,
  } = useMutation((payload) => approveClaimApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateRejectClaim,
    isLoading: isLoadingReject,
    data: rejectData,
  } = useMutation((payload) => rejectClaimApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const handleApprove = () => {
    toggleModal();
  };

  const handleReject = () => {
    toggleModal();
  };

  const handleSubmit = () => {
    if (comments.trim() !== '') {
      if (modal) {
        if (comments !== '') {
          if (modal === 'approve') {
            mutateApproveClaim({
              claimId: id,
              comments: comments,
            });
          } else if (modal === 'reject') {
            mutateRejectClaim({
              claimId: id,
              comments: comments,
            });
          }
        }
        toggleModal();
      }
    }
  };

  //meta title
  document.title = 'Claim Details | SUN Welfare Administration System';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Claims" breadcrumbItem="Claim Details" />
          <Row>
            {isLoadingApplication && <div>LoadingIndicator...</div>}
            {!isLoadingApplication && (
              <Col lg="12">
                <Link to="/claims">
                  <Button
                    type="button"
                    color="primary"
                    className="btn text-end"
                  >
                    <i className="bx bx-arrow-back"></i> Back
                  </Button>
                </Link>
                <Card style={{ maxWidth: '70%', margin: '0 auto' }}>
                  <CardBody>
                    {/* <Row className="mb-5">
                        <Col className="text-end">
                          {roleName !== claimData?.claimStatus?.destinationRole ? (
                            <>
                              <Button type="button" color="primary" onClick={() => { setModal("approve") }} className="me-4">
                                <i className="bx bx-check"></i> Approve
                              </Button>
                              <Button type="button" color="primary" onClick={() => { setModal("reject") }}>
                                <i className="bx bx-check"></i> Reject
                              </Button>
                            </>
                          ) : (
                            <span>Claim processed</span>
                          )}
                        </Col>
                      </Row> */}
                    <Row className="mb-5">
                      <Col className="text-end">
                        {console.log(
                          'Checking roles:',
                          claimData?.claimStatus?.destinationRoles,
                        )}
                        {claimData?.claimStatus?.destinationRoles.some(
                          (role) => {
                            const match = role == roleId;
                            console.log(
                              `Role Name: ${role}, roleName: ${roleName}, Match: ${match}`,
                            );
                            return match;
                          },
                        ) ? (
                          <>
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => setModal('approve')}
                              className="me-4"
                            >
                              <i className="bx bx-check"></i> Approve
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => setModal('reject')}
                            >
                              <i className="bx bx-check"></i> Reject
                            </Button>
                          </>
                        ) : (
                          <span>Claim Processed.</span>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <div className="mb-4">
                          <img
                            src={logo}
                            alt="Company Logo"
                            style={{ maxWidth: '200px' }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <h5>Claim Status</h5>
                        <p>
                          {roleName === claimData?.claimStatus?.destinationRole
                            ? claimData?.claimStatus?.status
                            : 'Reported'}
                        </p>
                      </Col>
                      <Col md="4">
                        <h5>Date Reported</h5>
                        <p>
                          {claimData?.reportedDateTime
                            ? moment(claimData.reportedDateTime).format(
                                'MMM DD, YYYY HH:mm',
                              )
                            : 'Data not found'}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Row>
                          <h4>Member Details</h4>
                          <Col md="6">
                            <p>Fullnames:</p>
                            <p>Mobile:</p>
                            <p>Email:</p>
                            <p>Location:</p>
                          </Col>
                          <Col md="6" style={{ fontWeight: 'bold' }}>
                            <p>
                              {claimData?.member
                                ? `${claimData?.member.firstName || ''} ${
                                    claimData?.member?.lastName || ''
                                  }`
                                : ''}
                            </p>
                            <p>
                              {claimData?.member
                                ? `${claimData?.member.mobileNumber1 || ''}`
                                : ''}
                            </p>
                            <p>
                              {claimData?.member
                                ? `${claimData?.member.email || ''}`
                                : ''}
                            </p>
                            <p>
                              {claimData?.member?.address
                                ? `${
                                    claimData?.member?.address?.wardName || ''
                                  }, ${
                                    claimData?.member?.address
                                      ?.constituencyName || ''
                                  }`
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <h4>Next Of Kin Details</h4>
                          <Col md="6">
                            <p>Fullnames:</p>
                            <p>Mobile:</p>
                            <p>Email:</p>
                            <p>Relationship:</p>
                          </Col>
                          <Col md="6" style={{ fontWeight: 'bold' }}>
                            <p>
                              {claimData?.nextOfKin
                                ? `${claimData?.nextOfKin?.firstName || ''} ${
                                    claimData?.nextOfKin?.lastName || ''
                                  }`
                                : ''}
                            </p>
                            <p>
                              {claimData?.nextOfKin
                                ? `${claimData?.nextOfKin?.mobileNumber || ''}`
                                : ''}
                            </p>
                            <p>
                              {claimData?.nextOfKin
                                ? `${claimData?.nextOfKin?.email || ''}`
                                : ''}
                            </p>
                            <p>
                              {claimData?.nextOfKin
                                ? `${claimData?.nextOfKin?.relationship || ''}`
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <h4>Deceased Details</h4>
                          <Col md="6">
                            <p>Fullnames:</p>
                            <p>Date of Death:</p>
                          </Col>
                          <Col md="6" style={{ fontWeight: 'bold' }}>
                            <p>
                              {claimData?.deathCertificate
                                ? `${
                                    claimData?.deathCertificate?.firstName || ''
                                  } ${
                                    claimData?.deathCertificate?.lastName || ''
                                  }`
                                : ''}
                            </p>
                            <p>
                              {claimData?.deathCertificate
                                ? `${moment(
                                    claimData?.deathCertificate?.deathDate,
                                  ).format('MMM DD, YYYY')}`
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md="12">
                            <h5>Attachments:</h5>
                            <ol>
                              {attachmentData.map((attachment) => (
                                <li className="mb-2" key={attachment.id}>
                                  {attachment.contentType.startsWith(
                                    'image/',
                                  ) ? (
                                    <div>
                                      <span className="me-3">
                                        Name: <strong>{attachment.type}</strong>
                                      </span>
                                      <a
                                        href={attachment.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {attachment.name}
                                      </a>
                                    </div>
                                  ) : (
                                    <div>
                                      <span className="me-3">
                                        Name: <strong>{attachment.type}</strong>
                                      </span>
                                      <span>
                                        <a
                                          href={attachment.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {attachment.name}
                                        </a>
                                      </span>
                                    </div>
                                  )}
                                </li>
                              ))}
                            </ol>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Modal isOpen={modal} toggle={toggleModal}>
                      <ModalHeader toggle={toggleModal}>
                        Enter Comments
                      </ModalHeader>
                      <ModalBody>
                        <Label for="comments">Comments:</Label>
                        <Input
                          type="textarea"
                          id="comments"
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                          placeholder="Enter comments..."
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={handleSubmit}>
                          Submit
                        </Button>{' '}
                        <Button color="secondary" onClick={toggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClaimDetail;

import {
  SET_CLAIM_SUMMARY,
  SET_GROUP_SUMMARY,
  SET_MEMBERS_SUMMARY,
  SET_APPLICATIONS_SUMMARY,
  SET_PAYMENTS_SUMMARY,
  SET_TRANSFER_SUMMARY,
} from './actionTypes';

const INIT_STATE = {
  claims: [],
  applications: [],
  group: {},
  transfers: [],
  members: [],
  payments: [],
};

const reports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_APPLICATIONS_SUMMARY:
      return {
        ...state,
        applications: action.payload,
      };

    case SET_TRANSFER_SUMMARY:
      return {
        ...state,
        transfers: action.payload,
      };

    case SET_PAYMENTS_SUMMARY:
      return {
        ...state,
        payments: action.payload,
      };

    case SET_MEMBERS_SUMMARY:
      return {
        ...state,
        members: action.payload,
      };

    case SET_CLAIM_SUMMARY:
      return {
        ...state,
        claims: action.payload,
      };

    case SET_GROUP_SUMMARY:
      return {
        ...state,
        group: action.payload,
      };
    default:
      return state;
  }
};

export default reports;

import {
  GET_RISK_CLASSS_FAIL,
  GET_RISK_CLASSS_SUCCESS,
  ADD_RISK_CLASS_SUCCESS,
  ADD_RISK_CLASS_FAIL,
  UPDATE_RISK_CLASS_SUCCESS,
  UPDATE_RISK_CLASS_FAIL,
  DELETE_RISK_CLASS_SUCCESS,
  DELETE_RISK_CLASS_FAIL,
} from './actionTypes';

const INIT_STATE = {
  products: [],
  product: {},
  riskClasses: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true,
};

const RiskClass = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RISK_CLASSS_SUCCESS:
      return {
        ...state,
        riskClasss: action.payload,
        loading: true,
      };

    case GET_RISK_CLASSS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_RISK_CLASS_SUCCESS:
      return {
        ...state,
        riskClasss: [...state.riskClasss, action.payload],
      };

    case ADD_RISK_CLASS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_RISK_CLASS_SUCCESS:
      return {
        ...state,
        riskClasss: state.riskClasss.map((riskClass) =>
          riskClass.id + '' === action.payload.id + ''
            ? { riskClass, ...action.payload }
            : riskClass,
        ),
      };

    case UPDATE_RISK_CLASS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_RISK_CLASS_SUCCESS:
      return {
        ...state,
        riskClasss: state.riskClasss.filter(
          (riskClass) => riskClass.id !== action.payload,
        ),
      };
    case DELETE_RISK_CLASS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default RiskClass;

import {
  GET_APPLICATIONS,
  GET_APPLICATIONS_FAIL,
  GET_APPLICATIONS_SUCCESS,
  ADD_NEW_APPLICATION,
  ADD_APPLICATION_SUCCESS,
  ADD_APPLICATION_FAIL,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_FAIL,
  DELETE_APPLICATION,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAIL,
} from './actionTypes';

export const getApplications = (data) => {
  return {
    type: 'GET_APPLICATIONS',
    payload: data,
  };
};

export const getApplicationsSuccess = (applications) => ({
  type: GET_APPLICATIONS_SUCCESS,
  payload: applications,
});

export const getApplicationsFail = (error) => ({
  type: GET_APPLICATIONS_FAIL,
  payload: error,
});

export const addNewApplication = (application) => ({
  type: ADD_NEW_APPLICATION,
  payload: application,
});

export const addApplicationSuccess = (application) => ({
  type: ADD_APPLICATION_SUCCESS,
  payload: application,
});

export const addApplicationFail = (error) => ({
  type: ADD_APPLICATION_FAIL,
  payload: error,
});

export const updateApplication = (application) => ({
  type: UPDATE_APPLICATION,
  payload: application,
});

export const updateApplicationSuccess = (application) => ({
  type: UPDATE_APPLICATION_SUCCESS,
  payload: application,
});

export const updateApplicationFail = (error) => ({
  type: UPDATE_APPLICATION_FAIL,
  payload: error,
});

export const deleteApplication = (application) => ({
  type: DELETE_APPLICATION,
  payload: application,
});

export const deleteApplicationSuccess = (application) => ({
  type: DELETE_APPLICATION_SUCCESS,
  payload: application,
});

export const deleteApplicationFail = (error) => ({
  type: DELETE_APPLICATION_FAIL,
  payload: error,
});

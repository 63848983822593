import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  Form,
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import withRouter from 'components/Common/withRouter';

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';

import avatar from '../../assets/images/users/avatar-1.jpg';
// actions
import { editProfile, resetProfileFlag } from '../../store/actions';
import { StoreContext } from '../../hooks/useStore';
import { useMutation, useQueryClient } from 'react-query';
import { useRole } from 'hooks/useRole';
import { getAppUserByIdApi } from 'api/user';
import { sendEmailForMFA, resetPasswordApi } from 'api/auth';

const UserProfile = () => {
  document.title = 'Profile | SUN Welfare Administration System';
  const { address } = useRole();
  const queryClient = useQueryClient();
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [idx, setIdx] = useState(1);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const userId = localStorage.getItem('userId');
  const [userDetails, setUserDetails] = useState(null);

  const { state } = useContext(StoreContext);
  const stateRole = state.user?.role || localStorage.getItem('shofco_role');
  const stateEmail = state.user?.email || localStorage.getItem('shofco_email');

  const selectProfileState = (state) => state.Profile;
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    error: profile.error,
    success: profile.success,
  }));

  const { error, success } = useSelector(ProfileProperties);

  const {
    mutate: mutateUserDetails,
    isLoading: isLoadingUserDetails,
    data: userDetailsData,
  } = useMutation((id) => getAppUserByIdApi(userId), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'));
      if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        setName(obj.displayName);
        setEmail(obj.email);
        setIdx(obj.uid);
        setRole(obj.role);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === 'fake' ||
        process.env.REACT_APP_DEFAULTAUTH === 'jwt'
      ) {
        setName(obj.username);
        setEmail(obj.email);
        setIdx(obj.uid);
        setRole(obj.role);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  console.log(userDetailsData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: name || '',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Please Enter Your UserName'),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    },
  });

  useEffect(() => {
    if (userId) {
      mutateUserDetails(userId);
    }
  }, [userId]);

  const handleResetPassword = () => {
    sendEmailForMFA(email);
    setShowResetPasswordModal(true);
  };

  const handleSubmitResetPassword = async (e) => {
    e.preventDefault();
    try {
      if (validation.values.newPassword !== validation.values.confirmPassword) {
        setPasswordsMatch(false);

        return;
      }
      setPasswordsMatch(true);
      await resetPasswordApi({
        userName: validation.values.email,
        password: validation.values.newPassword,
        confirmPassword: validation.values.confirmPassword,
        code: validation.values.otp,
      });
      setSuccessMessage('Password reset successful');
      setShowResetPasswordModal(false);
    } catch (error) {
      console.error('Password reset error:', error);
    }
  };

  if (isLoadingUserDetails) return <div>Loading...</div>;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="User" breadcrumbItem="Profile" />
          <Card className="my-3">
            <CardBody>
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}
              <Row>
                <img
                  src={userDetailsData?.profileImageUrl || avatar}
                  alt=""
                  className="avatar-md rounded-circle img-thumbnail mb-5 me-3"
                />
                <Row>
                  <Col md="6">
                    <div>
                      <h5 className="mb-3">{`${userDetailsData?.firstName} ${userDetailsData?.middleName || ''} ${userDetailsData?.lastName || ''}`}</h5>
                      <p className="text-muted mb-3">EMAIL: <span> <strong>{userDetailsData?.email || ''}</strong></span></p>
                      <p className="text-muted mb-3">ROLE: <span> <strong>{userDetailsData?.role || ''}</strong></span></p>
                      <p className="text-muted mb-3">
                        MOBILE: <span> <strong>{userDetailsData?.mobileNumber}</strong></span>
                      </p>
                    </div>
                  </Col>
                  <Col md="6">
                    <div>
                      <h5 className="mb-3">Address</h5>
                      <p className="text-muted mb-3">
                        County: <span> <strong>{userDetailsData?.addresses[0].countyName}</strong></span>
                      </p>
                      <p className="text-muted mb-3">
                        Constituency: <span> <strong>{userDetailsData?.addresses[0].constituencyName}</strong></span>
                      </p>
                      WARDS:
                      {
                        userDetailsData?.addresses?.map(a => (
                          <span key={a.id}> <strong><p className="text-muted mb-3" >
                            {a?.wardName}
                          </p>
                          </strong>
                          </span>
                        ))
                      }
                    </div>
                  </Col>
                </Row>
              </Row>
              <Row>
                <Col md={6}>
                  <Button color="primary" onClick={handleResetPassword}>
                    Reset Password
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>

      <Modal
        isOpen={showResetPasswordModal}
        toggle={() => setShowResetPasswordModal(!showResetPasswordModal)}
      >
        <ModalHeader
          toggle={() => setShowResetPasswordModal(!showResetPasswordModal)}
        >
          Reset Password
        </ModalHeader>
        <ModalBody>
          {successMessage && <Alert color="success">{successMessage}</Alert>}
          <h5 className="mb-4">
            Please check your email for a One Time Password (OTP).
          </h5>
          <Form onSubmit={handleSubmitResetPassword}>
            <div className="mb-3">
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                onChange={validation.handleChange}
                value={validation.values.email}
              />
            </div>
            <div className="mb-3">
              <Label for="newPassword">New Password</Label>
              <Input
                type="password"
                name="newPassword"
                id="newPassword"
                placeholder="Enter your new password"
                onChange={validation.handleChange}
                value={validation.values.newPassword}
              />
            </div>
            <div className="mb-3">
              <Label for="newPassword">Confirm Password</Label>
              <Input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Enter your new password"
                onChange={validation.handleChange}
                value={validation.values.confirmPassword}
              />
              {!passwordsMatch && (
                <FormFeedback>Passwords do not match</FormFeedback>
              )}
            </div>
            <div className="mb-3">
              <Label for="otp">OTP Code</Label>
              <Input
                type="text"
                name="otp"
                id="otp"
                placeholder="Enter the OTP code"
                onChange={validation.handleChange}
                value={validation.values.otp}
              />
            </div>
            <Button color="primary" type="submit" className="w-100">
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);

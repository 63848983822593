import { get, post, put } from './utils';

export async function getCurrentUserApi() {
  return post(`/api/link/me`);
}

export async function loginApi(data) {
  return post(`/api/auth/signin`, data);
}

export async function registerApi(data) {
  return post(`/api/auth/signup`, data);
}

export async function forgotPasswordApi(data) {
  return post(`/api/auth/forgot-password`, data);
}

export async function resetPasswordApi(data) {
  return put(`/api/auth/resetpassword`, data);
}

export async function changePasswordApi(data) {
  return put(`/api/auth/me/changePassword`, data);
}

export async function sendEmailForMFA(email) {
  return post(
    `/api/public/um/mfa/email/send?email=${encodeURIComponent(email)}`,
  );
}

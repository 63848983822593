/* INVOICES */
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAIL = 'GET_INVOICES_FAIL';

/**
 * add INVOICE
 */
export const ADD_NEW_INVOICE = 'ADD_NEW_INVOICE';
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';
export const ADD_INVOICE_FAIL = 'ADD_INVOICE_FAIL';

/**
 * Edit INVOICE
 */
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAIL = 'UPDATE_INVOICE_FAIL';

/**
 * Delete INVOICE
 */
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAIL = 'DELETE_INVOICE_FAIL';

import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  Col,
  Row,
  FormFeedback,
  FormGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { addDependant, updateDependant, uploadDepeMedia } from 'api/member';

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const AddDependantModal = ({
  visible,
  relationships,
  member,
  initData,
  isNew,
  setVisible,
}) => {
  const [file, setSelectedFile] = useState(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstName: initData?.firstName || '',
      lastName: initData?.lastName || '',
      email: initData?.email || '',
      relationship: initData?.relationship || '',
      mobileNumber: initData?.mobileNumber || '',
      gender: initData?.gender || '',
      dob: initData?.dob ? moment(initData?.dob).format('DD-MM-YYYY') : '',
      certificateofbirth: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please Enter First Name'),
      lastName: Yup.string().required('Please Enter Last Name'),
      relationship: Yup.string().required('Please Select Relationship'),
      gender: Yup.string().required('Please Select Gender'),
      dob: Yup.string().required('Please Select DOB'),
      // certificateofbirth: Yup.string().required("Please Select Birth Certificate file"),
    }),
    onSubmit: async ({ dob, ...values }, { resetForm }) => {
      let depId = null;
      if (isNew) {
        const data = {
          ...values,
          memberId: member?.memberId,
          dob: moment(dob).format('MM-DD-YYYY'),
        };
        await addDependant(data).then((res) => {
          if (res.status === 'Success') {
            depId = res?.data?.id;
            console.log(res);
            setVisible(false);
            resetForm();
          } else {
            // TODO SHOW ERROR
          }
        });
      } else {
        const data = {
          ...initData,
          ...values,
          dob: moment(dob).format('MM-DD-YYYY'),
        };
        await updateDependant(data).then((res) => {
          if (res.status === 'Success') {
            depId = res?.data?.id;
            setVisible(false);
            resetForm();
          } else {
            // TODO SHOW ERROR
          }
        });
      }
      depId && file && uploadDepeMedia(depId, file);
    },
  });

  const handleAcceptedFiles = (files) => {
    files.forEach((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setSelectedFile(files);
  };

  const onFileChange = (e) => {
    setSelectedFile(e.currentTarget.files[0]);
    validation.handleChange(e);
  };

  return (
    <Modal
      size="lg"
      isOpen={visible}
      toggle={setVisible}
      // className={className}
      // backdrop={backdrop}
      // keyboard={keyboard}
    >
      <ModalHeader toggle={setVisible}>
        {isNew ? 'Add New Dependent' : 'Edit Dependent'}
      </ModalHeader>
      <ModalBody>
        <Form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg="6">
              <FormGroup className="mb-3">
                <Label for={`firstName`}>First Name</Label>
                <Input
                  type="text"
                  name={`firstName`}
                  className="form-control"
                  id={`firstName`}
                  placeholder="Enter First Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstName || ''}
                  invalid={
                    validation.touched.firstName && validation.errors.firstName
                      ? true
                      : false
                  }
                />
                {validation.touched.firstName && validation.errors.firstName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.firstName}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="mb-3">
                <Label for={`lastName`}>Last Name</Label>
                <Input
                  type="text"
                  className="form-control"
                  name={`lastName`}
                  id={`lastName`}
                  placeholder="Enter Last Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastName || ''}
                  invalid={
                    validation.touched.lastName && validation.errors.lastName
                      ? true
                      : false
                  }
                />
                {validation.touched.lastName && validation.errors.lastName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lastName}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup className="mb-3">
                <Label for={`gender`}>Sex</Label>
                <Input
                  className="form-select"
                  id={`gender`}
                  type="select"
                  name="gender"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.gender || ''}
                  invalid={
                    validation.touched.gender && validation.errors.gender
                      ? true
                      : false
                  }
                >
                  <option defaultValue>Select Gender...</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                  <option value="Intersex">Intersex</option>
                  <option value="Other">Other</option>
                </Input>
                {validation.touched.gender && validation.errors.gender ? (
                  <FormFeedback type="invalid">
                    {validation.errors.gender}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="mb-3">
                <Label for={`dob`}>Date Of birth</Label>
                <Input
                  name={`dob`}
                  type="date"
                  className="form-control"
                  id={`dob`}
                  placeholder="Select Date of Birth"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.dob || ''}
                  // min={new Date(
                  //     new Date().setFullYear(new Date().getFullYear() - 20)
                  //   ).toISOString().split("T")[0]}
                  // invalid={
                  //     validation.touched.dob && validation.errors.dob ? true : false
                  // }
                />
                {validation.touched.dob && validation.errors.dob ? (
                  <FormFeedback type="invalid">
                    {validation.errors.dob}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup className="mb-3">
                <Label for={`mobileNumber`}>Phone Number</Label>
                <Input
                  type="text"
                  className="form-control"
                  id={`mobileNumber`}
                  name="mobileNumber"
                  placeholder="Enter Phone Number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mobileNumber || ''}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="mb-3">
                <Label for={`relationship`}>Relationship</Label>
                <Input
                  className="form-select"
                  id={`relationship`}
                  type="select"
                  name="relationship"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.relationship || ''}
                  invalid={
                    validation.touched.relationship &&
                    validation.errors.relationship
                      ? true
                      : false
                  }
                >
                  <option defaultValue value={''}>
                    Select Relationship...
                  </option>
                  {relationships &&
                    relationships
                      ?.filter((r) => r.type === 'Nuclear')
                      ?.map((item) => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                </Input>
                {validation.touched.relationship &&
                validation.errors.relationship ? (
                  <FormFeedback type="invalid">
                    {validation.errors.relationship}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="mb-3">
                <Label for={`email`}>Enter Email Address</Label>
                <Input
                  name={`email`}
                  type="text"
                  className="form-control"
                  id={`email`}
                  placeholder="Enter Email Address"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="certificateofbirth">
                  Upload Dependent Birth Certificate
                </Label>
                <Input
                  type="file"
                  name="certificateofbirth"
                  className="form-control"
                  id="certificateofbirth"
                  placeholder="Upload Dependent Birth Certificate"
                  value={validation.values.certificateofbirth || ''}
                  onChange={onFileChange}
                  onBlur={validation.handleBlur}
                  // invalid={
                  //     validation.touched.certificateofbirth && validation.errors.certificateofbirth ? true : false
                  // }
                />
                {/* {validation.touched.certificateofbirth && validation.errors.certificateofbirth ? (
                                    <FormFeedback type="invalid">{validation.errors.certificateofbirth}</FormFeedback>
                                ) : null} */}
              </div>
            </Col>
          </Row>
          <FormGroup className="mt-4">
            <Button type="submit" color="primary" block>
              SAVE DEPENDANT
            </Button>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={setVisible}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AddDependantModal.propTypes = {
  visible: PropTypes.bool,
  member: PropTypes.object,
  initData: PropTypes.object,
  setVisible: PropTypes.func,
  relationships: PropTypes.array,
};

export default AddDependantModal;

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import getChartColorsArray from '../../../components/Common/ChartsDynamicColor';

const Apaexlinecolumn = ({ dataColors, series, categories }) => {
  const apaexlineColumnColors = getChartColorsArray(dataColors);

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '65%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },

    colors: apaexlineColumnColors,
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: ' (thousand)',
      },
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '' + val + ' ';
        },
      },
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
};

export default Apaexlinecolumn;

import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  TabPane,
  Button,
  FormFeedback,
} from 'reactstrap';
import { updateMember } from 'api/member';

const CompleteRegister = ({ member }) => {
  useEffect(() => {}, []);

  return (
    <div className="row justify-content-center">
      <Col lg="6">
        <div className="text-center">
          <div className="mb-4">
            <i className="mdi mdi-check-circle-outline text-success display-4" />
          </div>
          <div>
            <h5>Submit Details</h5>
            <p className="text-muted">New Member has been succesfully added.</p>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default CompleteRegister;

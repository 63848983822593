import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/Common/withRouter';
import { useAuth } from 'hooks/useAuth';

const Logout = () => {
  const { user, logout } = useAuth();

  useEffect(() => {
    logout();
  }, []);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from 'yup';
import { useFormik } from 'formik';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { useNavigate } from 'react-router-dom';

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from 'store/actions';

import {
  UserId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from './UserCol';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import UsersModal from './UserModal';
import { useMutation, useQueryClient } from 'react-query';
import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from 'reactstrap';
import moment from 'moment';
import Spinners from 'components/Common/Spinner';
import { ToastContainer } from 'react-toastify';
import { getAllUsersApi } from 'api/user';
import { useRole } from 'hooks/useRole';
import MainFilter from 'components/Common/MainFilter';

function User() {
  const {
    isFacilitator,
    isSiteCord,
    isCountyCoordinator,
    isClusterLeader,
    isFinance,
    isDirector,
    isAdmin,
    isSuperAdmin,
    addresses,
    isData,
  } = useRole();
  const { t } = useTranslation();
  const address = addresses[0];
  const [addressData, setAddressData] = useState({});

  const [pages, setPages] = useState(0);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: false,
    searchColumns: [
      ...(isFacilitator
        ? [
            {
              name: 'wardIds',
              value: addresses.map((a) => a.wardId),
            },
          ]
        : []),
      ...(isCountyCoordinator
        ? [
            {
              name: 'countyId',
              value: addresses.map((a) => a.countyId),
            },
          ]
        : []),
      ...(isClusterLeader
        ? [
            {
              name: 'regionId',
              value: addresses.map((a) => a.regionId),
            },
          ]
        : []),
      ...(isSiteCord
        ? [
            {
              name: 'constituencyId',
              value: addresses.map((a) => a.constituencyId),
            },
          ]
        : []),
    ],
  });

  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [member, setUser] = useState(null);

  const queryClient = useQueryClient();

  const {
    mutate: mutateUsers,
    isLoading: isLoadingUsers,
    data: usersData,
  } = useMutation((payload) => getAllUsersApi(payload), {
    onSuccess: (res) => {
      res.pages > 0 && setPages(res.pages);
    },
    onSettled: () => {
      setLoading(false);
      queryClient.invalidateQueries('get-all-counties');
    },
  });
  //meta title
  document.title = 'Users | SUN Welfare Administration System';

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      memberId: (member && member.memberId) || '',
      firstName: (member && member.firstName) || '',
      lastName: (member && member.lastName) || '',
      role: (member && member.role) || '',
      paymentStatus: (member && member.paymentStatus) || 'Paid',
      badgeclass: (member && member.badgeclass) || 'success',
      status: (member && member.status) || 'Mastercard',
    },
    validationSchema: Yup.object({
      memberId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid User Id')
        .required('Please Enter Your User Id'),
      firstName: Yup.string().required('Please Enter Your Billing Name'),
      lastName: Yup.string().required('Please Enter Your User Date'),
      role: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Amount')
        .required('Total Amount'),
      paymentStatus: Yup.string().required('Please Enter Your Payment Status'),
      badgeclass: Yup.string().required('Please Enter Your Badge Class'),
      status: Yup.string().required('Please Enter Your Payment Method'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUser = {
          id: member ? member.id : 0,
          memberId: values.memberId,
          firstName: values.firstName,
          lastName: values.lastName,
          role: values.role,
          paymentStatus: values.paymentStatus,
          status: values.status,
          badgeclass: values.badgeclass,
        };
        // update member
        dispatch(onUpdateUser(updateUser));
        validation.resetForm();
      } else {
        const newUser = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          memberId: values['memberId'],
          firstName: values['firstName'],
          lastName: values['lastName'],
          role: values['role'],
          paymentStatus: values['paymentStatus'],
          status: values['status'],
          badgeclass: values['badgeclass'],
        };
        // save new member
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  useEffect(() => {
    mutateUsers({ ...pagination, pageNumber: pagination.pageNumber - 1 });
  }, [pagination]);

  const onChangePagination = ({ page, limit }, filters, sorter) => {
    let searchColumns = isEmpty(filters)
      ? pagination.searchColumns
      : [
          ...pagination.searchColumns,
          ...(filters?.firstName
            ? [
                {
                  name: 'firstName',
                  value: filters?.firstName,
                },
              ]
            : []),
          ...(filters?.lastName
            ? [
                {
                  name: 'lastName',
                  value: filters?.lastName,
                },
              ]
            : []),
          ...(filters?.email
            ? [
                {
                  name: 'email',
                  value: filters?.email,
                },
              ]
            : []),

          ...(filters?.mobileNumber
            ? [
                {
                  name: 'mobileNumber',
                  value: filters?.mobileNumber,
                },
              ]
            : []),
        ];
    console.log(searchColumns);
    setPagination({
      ...pagination,
      pageNumber: page || pagination.pageNumber,
      pageSize: limit || pagination.pageSize,
      searchColumns,
    });
  };
  const toggle = () => {
    if (modal) {
      setModal(false);
      setUser(null);
    } else {
      setModal(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
      },
      {
        Header: 'ID',
        accessor: 'id',
        width: '150px',
        style: {
          textAlign: 'center',
          width: '10%',
          background: '#0000',
        },
        filterable: true,
        Cell: (cellProps) => {
          return <UserId {...cellProps} />;
        },
      },
      {
        Header: 'FULL NAME',
        accessor: (row) =>
          `${row?.firstName || ''} ${row?.middleName || ''} ${
            row?.lastName || ''
          }`,
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'EMAIL',
        accessor: 'email',
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: 'ROLE',
        accessor: 'role',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: 'ADDRESSES',
        accessor: (row) => {
          return row?.addresses
            ?.map((r) => {
              return `${r.wardName}, ${r.constituencyName}, ${r.countyName} \n\n`;
            })
            .join('\n');
        },
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div style={{ whiteSpace: 'pre-wrap', lineHeight: '.6' }}>
              {cellProps.value}
            </div>
          );
        },
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        Cell: (cellProps) => {
          return <PaymentMethod {...cellProps} />;
        },
      },
    ],
    [],
  );

  const onSearch = (data) => {
    const searchColumns = [];
    if (!isEmpty(data)) {
      if (isFacilitator) {
        searchColumns.push({ name: 'wardId', value: address?.wardId });
      }
      if (isSiteCord && data?.wardId) {
        searchColumns.push(data?.wardId);
      } else {
        if (
          (isClusterLeader ||
            isFinance ||
            isDirector ||
            isAdmin ||
            isSuperAdmin) &&
          data?.regionId
        ) {
          searchColumns.push(data?.regionId);
        }
        if (data.wardId) {
          searchColumns.push(data?.wardId);
        }
        if (data.constituencyId) {
          searchColumns.push(data?.constituencyId);
        }
        if (data.countyId) {
          searchColumns.push(data?.countyId);
        }
      }
      data?.transactionNumber && searchColumns.push(data.transactionNumber);
      data?.startDate && searchColumns.push(data.startDate);
      data?.endDate && searchColumns.push(data.endDate);
      let add = {};
      searchColumns.forEach((a, i) => {
        add[a.name] = a.value;
      });
      setAddressData(add);
    }

    setPagination({ ...pagination, searchColumns });
  };

  return (
    <React.Fragment>
      <UsersModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Users" />

          <MainFilter
            pagination={pagination}
            resource="users"
            onSearch={onSearch}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={usersData?.list || []}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    hasCreate
                    isPagination={true}
                    filterable={false}
                    iscustomPageSizeOptions={true}
                    showDelete
                    hasSearch
                    showEdit
                    showView
                    loading={isLoadingUsers}
                    pages={pages}
                    addText="Add New User"
                    resource="user"
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    paginationData={pagination}
                    onChangePagination={onChangePagination}
                    searchByNames={[
                      {
                        value: 'email',
                        text: t('common.email'),
                      },
                      // {
                      //   value: 'idNumber',
                      //   text: t('common.idNumber'),
                      // },
                      {
                        value: 'firstName',
                        text: t('users.firstName'),
                      },
                      {
                        value: 'lastName',
                        text: t('users.lastName'),
                      },
                      {
                        value: 'mobileNumber',
                        text: t('common.mobileNumber'),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? 'Edit User' : 'Add User'}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>User Id</Label>
                      <Input
                        name="memberId"
                        type="text"
                        placeholder="Insert User Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.memberId || ''}
                        invalid={
                          validation.touched.memberId &&
                          validation.errors.memberId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.memberId &&
                      validation.errors.memberId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.memberId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="firstName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstName || ''}
                        invalid={
                          validation.touched.firstName &&
                          validation.errors.firstName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.firstName &&
                      validation.errors.firstName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.firstName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>User Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="lastName"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) =>
                          validation.setFieldValue(
                            'lastName',
                            moment(date[0]).format('DD MMMM, YYYY'),
                          )
                        }
                        value={validation.values.lastName}
                      />
                      {validation.touched.lastName &&
                      validation.errors.lastName ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.lastName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="role"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.role || ''}
                        invalid={
                          validation.touched.role && validation.errors.role
                            ? true
                            : false
                        }
                      />
                      {validation.touched.role && validation.errors.role ? (
                        <FormFeedback type="invalid">
                          {validation.errors.role}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentStatus || ''}
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus &&
                      validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentStatus}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ''}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass &&
                      validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.badgeclass}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="status"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ''}
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
User.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default User;

import {
  GET_GROUPS,
  GET_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  ADD_NEW_GROUP,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAIL,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
} from './actionTypes';

export const getGroups = () => ({
  type: GET_GROUPS,
});

export const getGroupsSuccess = (orders) => ({
  type: GET_GROUPS_SUCCESS,
  payload: orders,
});

export const getGroupsFail = (error) => ({
  type: GET_GROUPS_FAIL,
  payload: error,
});

export const addNewGroup = (order) => ({
  type: ADD_NEW_GROUP,
  payload: order,
});

export const addGroupSuccess = (order) => ({
  type: ADD_GROUP_SUCCESS,
  payload: order,
});

export const addGroupFail = (error) => ({
  type: ADD_GROUP_FAIL,
  payload: error,
});

export const updateGroup = (order) => ({
  type: UPDATE_GROUP,
  payload: order,
});

export const updateGroupSuccess = (order) => ({
  type: UPDATE_GROUP_SUCCESS,
  payload: order,
});

export const updateGroupFail = (error) => ({
  type: UPDATE_GROUP_FAIL,
  payload: error,
});

export const deleteGroup = (order) => ({
  type: DELETE_GROUP,
  payload: order,
});

export const deleteGroupSuccess = (order) => ({
  type: DELETE_GROUP_SUCCESS,
  payload: order,
});

export const deleteGroupFail = (error) => ({
  type: DELETE_GROUP_FAIL,
  payload: error,
});

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import withRouter from 'components/Common/withRouter';
import { useAuth } from 'hooks/useAuth';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { loginUser, socialLogin } from '../../store/actions';
import profile from 'assets/images/profile-img.png';
import logo from 'assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import { resetPasswordApi, forgotPasswordApi } from 'api/auth';
import Swal from 'sweetalert2';

const Login = (props) => {
  const { isAuthenticated, login, loginError, loading, lerror } = useAuth();
  const { t } = useTranslation();
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  // const address = localStorage.getItem('addresses') && JSON.parse(localStorage.getItem('addresses'))?.wardId;

  document.title = 'Login | SUN Welfare Administration System';

  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: async (values) => {
      try {
        let loginData = {
          userName: values.email,
          password: values.password,
        };
        login(loginData);
      } catch (error) {
        console.error('Error during login:', error);
      }
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      props.router.navigate('/dashboard');
    }
  }, [isAuthenticated]);

  const selectLoginState = (state) => state.Login;
  const LoginProperties = createSelector(selectLoginState, (login) => ({
    error: login.error,
  }));

  const { error } = useSelector(LoginProperties);

  const signIn = (type) => {
    dispatch(socialLogin(type, props.router.navigate));
  };

  const socialResponse = (type) => {
    signIn(type);
  };

  const forgotPasswordValidation = useFormik({
    initialValues: {
      email: '',
      newPassword: '',
      otp: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email'),
    }),
    onSubmit: async (values) => {
      if (!otpSent) {
        const resp = await forgotPasswordApi({
          email: values.email,
          userType: 'user',
        });
        console.log(resp);
        if (resp.status === 'Success') {
          setOtpSent(true);
          Swal.fire({
            icon: 'success',
            title: 'OTP Sent',
            text: 'An OTP has been sent to your email/mobile number. Please check your inbox.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error Sending Otp',
            text:
              resp.message ||
              'Failed to send OTP. Please contact support if the issue persists.',
          });
        }

        return;
      }
      try {
        const response = await resetPasswordApi({
          userName: values.email,
          password: values.newPassword,
          code: values.otp,
        });
        if (response?.status === 'Success') {
          setResetSuccess(true);
          setOtpSent(false);
        }

        if (response.status === 'Success') {
          Swal.fire({
            icon: 'success',
            title: 'Password Changed',
            text: 'Password has been successfully changed!',
            timer: 2000,
            showConfirmButton: false,
            willClose: () => {
              setShowForgotPasswordModal(false);
              setOtpSent(false);
              forgotPasswordValidation.resetForm();
            },
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text:
              response.message ||
              'Failed to change password. Please try again.',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred. Please try again.',
        });
        console.error('Error during forgot password:', error);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-secondary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="p-4">
                        <span className="font-size-14 fw-bolder">
                          {t('auth.welcome')}
                        </span>
                        <p>Welfare Administration System</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {lerror && <Alert color="danger">{lerror}</Alert>}

                      {loginError && (
                        <Alert color="danger">
                          {loginError?.code === 401
                            ? 'Invalid Credentials'
                            : loginError?.message}
                        </Alert>
                      )}

                      {resetSuccess && (
                        <Alert color="success">
                          {'Password reset Successful.'}
                        </Alert>
                      )}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation?.values?.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation?.values?.password || ''}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                    <div className="text-center mt-2">
                      <p className="text-muted mb-0">
                        <Link
                          to="#"
                          onClick={() => setShowForgotPasswordModal(true)}
                        >
                          Forgot Password?
                        </Link>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Forgot Password Modal */}
      <Modal
        isOpen={showForgotPasswordModal}
        toggle={() => setShowForgotPasswordModal(!showForgotPasswordModal)}
      >
        <ModalHeader
          toggle={() => setShowForgotPasswordModal(!showForgotPasswordModal)}
        >
          Forgot Password
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={forgotPasswordValidation.handleSubmit}>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
              onChange={forgotPasswordValidation.handleChange}
              onBlur={forgotPasswordValidation.handleBlur}
              value={forgotPasswordValidation.values.email}
              invalid={
                forgotPasswordValidation.touched.email &&
                !!forgotPasswordValidation.errors.email
              }
            />
            {forgotPasswordValidation.touched.email &&
              forgotPasswordValidation.errors.email && (
                <FormFeedback>
                  {forgotPasswordValidation.errors.email}
                </FormFeedback>
              )}

            {/* New Password field */}
            {otpSent && (
              <div className="mt-3">
                <Label for="newPassword">New Password</Label>
                <Input
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  placeholder="Enter your new password"
                  onChange={forgotPasswordValidation.handleChange}
                  onBlur={forgotPasswordValidation.handleBlur}
                  value={forgotPasswordValidation.values.newPassword}
                  invalid={
                    forgotPasswordValidation.touched.newPassword &&
                    !!forgotPasswordValidation.errors.newPassword
                  }
                />
                {forgotPasswordValidation.touched.newPassword &&
                  forgotPasswordValidation.errors.newPassword && (
                    <FormFeedback>
                      {forgotPasswordValidation.errors.newPassword}
                    </FormFeedback>
                  )}
              </div>
            )}

            {/* OTP field */}
            {otpSent && (
              <div className="mt-3">
                <Label for="otp">OTP Code</Label>
                <Input
                  type="text"
                  name="otp"
                  id="otp"
                  placeholder="Enter the OTP code"
                  onChange={forgotPasswordValidation.handleChange}
                  onBlur={forgotPasswordValidation.handleBlur}
                  value={forgotPasswordValidation.values.otp}
                  invalid={
                    forgotPasswordValidation.touched.otp &&
                    !!forgotPasswordValidation.errors.otp
                  }
                />
                {forgotPasswordValidation.touched.otp &&
                  forgotPasswordValidation.errors.otp && (
                    <FormFeedback>
                      {forgotPasswordValidation.errors.otp}
                    </FormFeedback>
                  )}
              </div>
            )}

            <Button color="primary" type="submit" className="mt-3" block>
              {otpSent ? 'Reset Password' : 'Send OTP'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};

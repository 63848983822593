import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  UncontrolledAlert,
} from 'reactstrap';
import { useMutation, useQueryClient } from 'react-query';
import { getAllSubRolesApi } from 'api/role';
import {
  getAllRegionsApi,
  getAllCountiesByRegionApi,
  getConstituencyApi,
  getAllWardsApi,
} from 'api/report';
import { addAppUser, updateAppUser, getAppUserByIdApi } from 'api/user';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import Select from 'react-select';
import { Link, useParams } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useNavigate } from 'react-router-dom';

const AddUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const isNew = isEmpty(id);
  const [error, setError] = useState(undefined);

  //meta title
  document.title = 'Add User | SUN Welfare Administration System';

  const {
    mutate: mutateUser,
    isLoading: isLoadingUser,
    data: user,
  } = useMutation((payload) => getAppUserByIdApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('/public/um/role/');
    },
  });

  const {
    mutate: mutateSubRoles,
    isLoading: isLoadingSubRoles,
    data: subRolesData,
  } = useMutation((payload) => getAllSubRolesApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('/public/um/role/');
    },
  });

  const {
    mutate: mutateRegions,
    isLoading: isLoadingRegions,
    data: regionsData,
  } = useMutation((payload) => getAllRegionsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateCounty,
    isLoading: isLoadingCounty,
    data: countiesData,
  } = useMutation((payload) => getAllCountiesByRegionApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateSubCounty,
    isLoading: isLoadingSubCounty,
    data: subCountiesData,
  } = useMutation((payload) => getConstituencyApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateWards,
    isLoading: isLoadingWards,
    data: wardsData,
  } = useMutation((payload) => getAllWardsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const [selectedFiles, setselectedFiles] = useState([]);

  // State to manage selected options
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Handle changes in selected options
  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );

    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  useEffect(() => {
    if (!isNew) {
      mutateUser(id);
    }
    mutateSubRoles({ roleId: 2 });
    mutateRegions({ countryId: 1 });
  }, [isNew]);

  useEffect(() => {
    user?.address?.regionId &&
      mutateCounty({ regionId: user?.address?.regionId });
    user?.address?.countyId &&
      mutateSubCounty({
        countyId: user?.address?.countyId,
      });
    user?.address?.constituencyId &&
      mutateWards({ constituencyId: user?.address?.constituencyId });
  }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      role: user?.role || '',
      mobileNumber: user?.mobileNumber || '',
      gender: user?.gender || '',
      dob: user?.dob ? moment(user?.dob).format('DD-MM-YYYY') : '',
      regionId: user?.address?.regionId || '',
      countyId: user?.address?.countyId || '',
      constituencyId: user?.address?.constituencyId || '',
      wardId: user?.address?.wardId || '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please Enter First Name'),
      lastName: Yup.string().required('Please Enter Last Name'),
      email: Yup.string()
        .email('Please enter a valid email address')
        .required('Email is required'),
      mobileNumber: Yup.string().required('Please Enter Mobile Number'),
      gender: Yup.string().required('Please Select Gender'),
      role: Yup.string().required('Please Select User Role'),
      dob: Yup.string().required('Please Select DOB'),
      regionId: Yup.string().required('Please Select Region'),
      countyId: Yup.string().required('Please Select County'),
      constituencyId: Yup.string().required('Please Select SubCounty'),
      wardId: Yup.string().required('Please Select Ward'),
    }),
    onSubmit: async (values) => {
      let data = {
        ...user,
        ...values,
        dob: moment(values.dob).format('DD-MM-YYYY'),
        addresses: [
          {
            regionId: values.regionId,
            countyId: values.countyId,
            constituencyId: values.constituencyId,
            wardId: values.wardId,
          },
        ],
      };
      if (isNew) {
        await addAppUser(data)
          .then((res) => {
            if (res.status === 'Success') {
              navigate('/users');
            } else {
              console.log(res);
              setError(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await updateAppUser(data).then((res) => {
          if (res.status === 'Success') {
            navigate('/users');
          } else {
            // TODO SHOW ERROR
          }
        });
      }
    },
  });

  const handleRegionChange = (e) => {
    const regionId = e.target.value;
    validation.setFieldValue('regionId', regionId);
    mutateCounty({
      regionId,
    });
  };

  const handleCountyChange = (e) => {
    const countyId = e.target.value;
    validation.setFieldValue('countyId', countyId);
    mutateSubCounty({
      countyId,
    });
  };

  const handleSubCountyChange = (e) => {
    const constituencyId = e.target.value;
    validation.setFieldValue('constituencyId', constituencyId);
    mutateWards({
      constituencyId,
    });
  };

  const handleAddWard = async () => {
    if (!validation.values.wardId) {
      return validation.setFieldError('wardId', 'TEST ERROR');
    }
    if (!isNew) {
      console.log(user);

      let userData = {
        ...user,
        addresses: [
          ...(user?.addresses || {}),
          {
            regionId: validation.values.regionId,
            countyId: validation.values.countyId,
            constituencyId: validation.values.constituencyId,
            wardId: validation.values.wardId,
          },
        ],
      };
      console.log(userData);
      await updateAppUser(userData).then((res) => {
        if (res.status === 'Success') {
          mutateUser(userData?.id);
        } else {
          // TODO SHOW ERROR
        }
      });
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs title="Users" breadcrumbItem="Add New User" />
        <Link to="/users">
          <Button type="button" color="primary" className="btn">
            <i className="bx bx-arrow-back"></i> Back
          </Button>
        </Link>
        <Row>
          <Col>
            {error && (
              <UncontrolledAlert color="danger">
                {error?.message}
              </UncontrolledAlert>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <CardTitle>{t('users.personalInformation')}</CardTitle>
                <p className="card-title-desc mb-4">
                  Fill all information below
                </p>

                <Form
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col md="4" sm="6">
                      <FormGroup>
                        <Label htmlFor="firstName">First Name</Label>
                        <Input
                          id="firstName"
                          name="firstName"
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName || ''}
                          invalid={
                            validation.touched.firstName &&
                            validation.errors.firstName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstName &&
                        validation.errors.firstName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstName}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="6">
                      <FormGroup>
                        <Label htmlFor="middleName">Middle Name</Label>
                        <Input
                          id="middleName"
                          name="middleName"
                          type="text"
                          className="form-control"
                          placeholder="Middle Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.middleName || ''}
                          invalid={
                            validation.touched.gender &&
                            validation.errors.middleName
                              ? true
                              : false
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="6">
                      <FormGroup>
                        <Label htmlFor="lastName">Last Name</Label>
                        <Input
                          id="lastName"
                          name="lastName"
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastName || ''}
                          invalid={
                            validation.touched.lastName &&
                            validation.errors.lastName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastName &&
                        validation.errors.lastName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastName}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="mobileNumber">Mobile Number</Label>
                        <Input
                          id="mobileNumber"
                          name="mobileNumber"
                          type="text"
                          className="form-control"
                          placeholder="Mobile Number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mobileNumber || ''}
                          invalid={
                            validation.touched.mobileNumber &&
                            validation.errors.mobileNumber
                              ? true
                              : false
                          }
                        />
                        {validation.touched.mobileNumber &&
                        validation.errors.mobileNumber ? (
                          <FormFeedback type="invalid">
                            {validation.errors.mobileNumber}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row></Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="role">Select Role</Label>
                        <Input
                          name="role"
                          type="select"
                          className="select2"
                          placeholder="Select Role"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.role || ''}
                          invalid={
                            validation.touched.role && validation.errors.role
                              ? true
                              : false
                          }
                        >
                          <option key={''}>Select User Role</option>
                          {subRolesData &&
                            subRolesData.map(({ id, name }) => (
                              <option value={name} key={id}>
                                {name}
                              </option>
                            ))}
                        </Input>
                        {validation.touched.role && validation.errors.role ? (
                          <FormFeedback type="invalid">
                            {validation.errors.role}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="dob">Select DOB</Label>
                        <Input
                          name="dob"
                          type="date"
                          className="form-control"
                          id="dob"
                          placeholder="Select Date of Birth"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.dob || ''}
                          invalid={
                            validation.touched.dob && validation.errors.dob
                              ? true
                              : false
                          }
                        />
                        {validation.touched.dob && validation.errors.dob ? (
                          <FormFeedback type="invalid">
                            {validation.errors.dob}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="gender">Select Gender</Label>
                        <Input
                          type="select"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="gender"
                          id="gender"
                          value={validation.values.gender || ''}
                          invalid={
                            validation.touched.gender &&
                            validation.errors.gender
                              ? true
                              : false
                          }
                        >
                          <option defaultValue>Select Gender...</option>
                          <option value="Female">Female</option>
                          <option value="Male">Male</option>
                          <option value="Intersex">Intersex</option>
                          <option value="Other">Other</option>
                        </Input>
                        {validation.touched.gender &&
                        validation.errors.gender ? (
                          <FormFeedback type="invalid">
                            {validation.errors.gender}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="gender">WARDS</Label>
                        <Select
                          isMulti // Allows multiple selections
                          options={user?.addresses.map((r) => ({
                            label: r.wardName,
                            value: r.wardId,
                          }))} // Pass the options to the dropdown
                          value={user?.addresses.map((r) => ({
                            label: r.wardName,
                            value: r.wardId,
                          }))} // Set the selected options
                          onChange={handleChange} // Handle selection changes
                          isClearable // Enables the clear option
                          placeholder="Select fruits..." // Placeholder text
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="regionId">Select Region</Label>
                        <Input
                          name="regionId"
                          type="select"
                          className="select2"
                          placeholder="Select Region"
                          onChange={handleRegionChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.regionId || ''}
                          invalid={
                            validation.touched.regionId &&
                            validation.errors.regionId
                              ? true
                              : false
                          }
                        >
                          <option key={''}>Select Region</option>
                          {regionsData &&
                            regionsData.map(({ id, name }) => (
                              <option value={id} key={id}>
                                {name}
                              </option>
                            ))}
                        </Input>
                        {validation.touched.regionId &&
                        validation.errors.regionId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.regionId}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="role">Select County</Label>
                        <Input
                          name="countyId"
                          type="select"
                          className="select2"
                          placeholder="Select County"
                          onChange={handleCountyChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.countyId || ''}
                          invalid={
                            validation.touched.countyId &&
                            validation.errors.countyId
                              ? true
                              : false
                          }
                        >
                          <option key={''}>Select Region</option>
                          {countiesData &&
                            countiesData.map(({ id, name }) => (
                              <option value={id} key={id}>
                                {name}
                              </option>
                            ))}
                        </Input>
                        {validation.touched.countyId &&
                        validation.errors.countyId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.countyId}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="role">Select SubCounty</Label>
                        <Input
                          name="constituencyId"
                          type="select"
                          className="select2"
                          placeholder="Select SubCounty"
                          onChange={handleSubCountyChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.constituencyId || ''}
                          invalid={
                            validation.touched.constituencyId &&
                            validation.errors.constituencyId
                              ? true
                              : false
                          }
                        >
                          <option key={''}>Select SubCounty</option>
                          {subCountiesData &&
                            subCountiesData.map(({ id, name }) => (
                              <option value={id} key={id}>
                                {name}
                              </option>
                            ))}
                        </Input>
                        {validation.touched.constituencyId &&
                        validation.errors.constituencyId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.constituencyId}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="role">Select Ward</Label>
                        <Input
                          name="wardId"
                          type="select"
                          className="select2"
                          placeholder="Select Ward"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.wardId || ''}
                          invalid={
                            validation.touched.wardId &&
                            validation?.errors.wardId
                              ? true
                              : false
                          }
                        >
                          <option key={''}>Select Ward</option>
                          {wardsData?.map(({ id, name }) => (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          ))}
                        </Input>
                        {validation.touched.wardId &&
                        validation.errors.wardId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.wardId}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          block
                          onClick={handleAddWard}
                          color="secondary"
                          className="btn"
                        >
                          ADD WARD
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2">
                    <Button block type="submit" color="primary" className="btn">
                      {t('button.submit')}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddUser;

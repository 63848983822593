import React, { useState, useEffect } from 'react';

import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  FormFeedback,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
} from 'reactstrap';

import classnames from 'classnames';
import { Link } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import * as Yup from 'yup';
import LoadingSpinner from '../Claim/loadingSpinner';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  getAllRegionsApi,
  getAllCountiesByRegionApi,
  getConstituencyApi,
  getAllWardsApi,
  getAllRelationshipsApi,
} from 'api/report';
import { addGroup, memberTransferRequestApi } from 'api/group';
import { getMemberByIdNumberApi } from 'api/member';
import { getMemberPolicyApi } from 'api/policy';
import Swal from 'sweetalert2';

const AddMemberTransfer = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [address, setAddress] = useState({});
  const [idNumber, setIdNumber] = useState(undefined);
  const [search, setSearch] = useState(false);
  const [member, setMember] = useState(null);
  const [comments, setComments] = useState('');
  const [reason, setReason] = useState('');
  //meta title
  document.title = 'Form Wizard | SUN Welfare Administration System';

  const { mutate: mutatePolicies, isLoading: isLoadingPolicies } = useMutation(
    (payload) => getMemberPolicyApi(payload),
    {
      onSettled: () => {
        queryClient.invalidateQueries('get-all-counties');
      },
    },
  );

  const {
    mutate: mutateRegions,
    isLoading: isLoadingRegions,
    data: regionsData,
  } = useMutation((payload) => getAllRegionsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateCounty,
    isLoading: isLoadingCounty,
    data: countiesData,
  } = useMutation((payload) => getAllCountiesByRegionApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateSubCounty,
    isLoading: isLoadingSubCounty,
    data: subCountiesData,
  } = useMutation((payload) => getConstituencyApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateWards,
    isLoading: isLoadingWards,
    data: wardsData,
  } = useMutation((payload) => getAllWardsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const [activeTab, setactiveTab] = useState(1);
  const [activeTabVartical, setoggleTabVertical] = useState(1);

  const [passedSteps, setPassedSteps] = useState([1]);
  const [passedStepsVertical, setPassedStepsVertical] = useState([1]);

  useEffect(() => {
    mutateRegions({ countryId: 1 });
  }, []);

  useEffect(() => {
    mutatePolicies();
  }, []);

  const validationIdNo = useFormik({
    enableReinitialize: true,
    initialValues: {
      idNumber: '',
    },
    validationSchema: Yup.object({
      idNumber: Yup.string().required('Please National ID Number/Passport'),
    }),
    onSubmit: async (values) => {
      const idNumber = values.idNumber;
      setIdNumber(idNumber);
      console.log(idNumber);
      await getMemberByIdNumberApi(idNumber).then((member) => {
        setMember(member);
        setSearch(true);
      });
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: '',
      comments: '',
      regionId: '',
      countyId: '',
      constituencyId: '',
      wardId: '',
    },
    validationSchema: Yup.object({
      comments: Yup.string().required('Please Enter Comments'),
      reason: Yup.string().required('Please Enter Transfer Reason'),
      regionId: Yup.string().required('Please Select Region'),
      countyId: Yup.string().required('Please Select County'),
      constituencyId: Yup.string().required('Please Select SubCounty'),
      wardId: Yup.string().required('Please Select Ward'),
    }),
    onSubmit: async (values) => {
      let transferData = {
        memberId: member?.memberId,
        comments: values.comments,
        reason: values.reason,
        destAddress: {
          regionId: values.regionId,
          countyId: values.countyId,
          constituencyId: values.constituencyId,
          wardId: values.wardId,
        },
      };

      const res = await memberTransferRequestApi(transferData);
      if (res.status === 'Success') {
        Swal.fire({
          title: 'Success!',
          text: 'Request Initiated Successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          navigate('/member-transfers');
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to initiate transfer. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    },
  });

  const handleRegionChange = (e) => {
    const regionId = e.target.value;
    validation.setFieldValue('regionId', regionId);
    mutateCounty({
      regionId,
    });
  };

  const handleCountyChange = (e) => {
    const countyId = e.target.value;
    validation.setFieldValue('countyId', countyId);
    mutateSubCounty({
      countyId,
    });
  };

  const handleSubCountyChange = (e) => {
    const constituencyId = e.target.value;
    validation.setFieldValue('constituencyId', constituencyId);
    mutateWards({
      constituencyId,
    });
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const onChangeIdNumber = (value) => {
    setMember(null);
    setIdNumber(value);
    setSearch(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Member Transfer"
            breadcrumbItem="New Member Transfer"
          />

          <Row>
            <Link to="/member-transfers">
              <Button type="button" color="primary" className="btn">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="card-title mb-4">New Member Transfer</h4>
                  </div>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1);
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Member Transfer
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          {isLoadingPolicies && <LoadingSpinner />}
                          <Form
                            autoComplete="off"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validationIdNo.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="basicpill-pancard-input5">
                                    Principal Member National ID Number
                                  </Label>
                                  <Input
                                    id="idNumber"
                                    name="idNumber"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter principal ID number"
                                    onChange={(e) => {
                                      validationIdNo.handleChange(e);
                                      onChangeIdNumber(e.target.value);
                                    }}
                                    onBlur={validationIdNo.handleBlur}
                                    value={validationIdNo.values.idNumber || ''}
                                    invalid={
                                      validationIdNo.touched.idNumber &&
                                      validationIdNo.errors.idNumber
                                        ? true
                                        : false
                                    }
                                  />
                                  {validationIdNo.touched.idNumber &&
                                  validationIdNo.errors.idNumber ? (
                                    <FormFeedback type="invalid">
                                      {validationIdNo.errors.idNumber}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            {idNumber && search && !member && (
                              <h4 className="my-5 fs-5 text text-danger">
                                No member found with this ID Number
                              </h4>
                            )}

                            <Row>
                              <Col lg="4" sm="12" md="8">
                                <Button
                                  type="submit"
                                  color="primary"
                                  block
                                  className="mt-4 d-flex align-items-center justify-content-center"
                                >
                                  Search Member{' '}
                                  <i className="bx bx-search-alt search-icon fs-4 ms-2"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Form>

                          {member && (
                            <Row className="mt-5">
                              <Col>
                                <CardTitle className="h4">
                                  Principal Member
                                </CardTitle>
                                <p className="card-title-desc">
                                  Confirm that this is the member to send
                                  payment request to.
                                </p>

                                <div className="table-responsive">
                                  <Table className="table mb-0 table-striped">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Full Name</th>
                                        <th>Mobile Number</th>
                                        <th>ID Number</th>
                                        <th>Policy Status</th>
                                        <th>Current Group</th>
                                        <th>Current Address</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">1</th>
                                        <td>
                                          {`${member?.firstName} ${
                                            member?.middleName || ''
                                          } ${member?.lastName}`}
                                        </td>
                                        <td>{member?.mobileNumber1}</td>
                                        <td>{member?.identificationNumber}</td>
                                        <td>{member?.status}</td>
                                        <td>{member?.groupName}</td>
                                        <td>{`${member?.address?.wardName}, ${member?.address?.constituencyName}`}</td>
                                      </tr>
                                    </tbody>
                                  </Table>

                                  <CardTitle className="h3 mt-5">
                                    Destination Details
                                  </CardTitle>
                                  <Form
                                    autoComplete="off"
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      validation.handleSubmit();
                                      return false;
                                    }}
                                  >
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup>
                                          <Label htmlFor="regionId">
                                            Select Region
                                          </Label>
                                          <Input
                                            name="regionId"
                                            type="select"
                                            className="select2"
                                            placeholder="Select Region"
                                            onChange={handleRegionChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.regionId || ''
                                            }
                                            invalid={
                                              validation.touched.regionId &&
                                              validation.errors.regionId
                                                ? true
                                                : false
                                            }
                                          >
                                            <option key={''}>
                                              Select Region
                                            </option>
                                            {regionsData &&
                                              regionsData.map(
                                                ({ id, name }) => (
                                                  <option value={id} key={id}>
                                                    {name}
                                                  </option>
                                                ),
                                              )}
                                          </Input>
                                          {validation.touched.regionId &&
                                          validation.errors.regionId ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.regionId}
                                            </FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup>
                                          <Label htmlFor="countyId">
                                            Select County
                                          </Label>
                                          <Input
                                            name="countyId"
                                            type="select"
                                            className="select2"
                                            placeholder="Select County"
                                            onChange={handleCountyChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.countyId || ''
                                            }
                                            invalid={
                                              validation.touched.countyId &&
                                              validation.errors.countyId
                                                ? true
                                                : false
                                            }
                                          >
                                            <option key={''}>
                                              Select County
                                            </option>
                                            {countiesData &&
                                              countiesData.map(
                                                ({ id, name }) => (
                                                  <option value={id} key={id}>
                                                    {name}
                                                  </option>
                                                ),
                                              )}
                                          </Input>
                                          {validation.touched.countyId &&
                                          validation.errors.countyId ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.countyId}
                                            </FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup>
                                          <Label htmlFor="constituencyId">
                                            Select SubCounty
                                          </Label>
                                          <Input
                                            name="constituencyId"
                                            type="select"
                                            className="select2"
                                            placeholder="Select SubCounty"
                                            onChange={handleSubCountyChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values
                                                .constituencyId || ''
                                            }
                                            invalid={
                                              validation.touched
                                                .constituencyId &&
                                              validation.errors.constituencyId
                                                ? true
                                                : false
                                            }
                                          >
                                            <option key={''}>
                                              Select Sub-County
                                            </option>
                                            {subCountiesData &&
                                              subCountiesData.map(
                                                ({ id, name }) => (
                                                  <option value={id} key={id}>
                                                    {name}
                                                  </option>
                                                ),
                                              )}
                                          </Input>
                                          {validation.touched.constituencyId &&
                                          validation.errors.constituencyId ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.constituencyId}
                                            </FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup>
                                          <Label htmlFor="wardId">
                                            Select Ward
                                          </Label>
                                          <Input
                                            name="wardId"
                                            type="select"
                                            className="select2"
                                            placeholder="Select Ward"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.wardId || ''
                                            }
                                            invalid={
                                              validation.touched.wardId &&
                                              validation.errors.wardId
                                                ? true
                                                : false
                                            }
                                          >
                                            <option key={''}>
                                              Select Ward
                                            </option>
                                            {wardsData &&
                                              wardsData.map(({ id, name }) => (
                                                <option value={id} key={id}>
                                                  {name}
                                                </option>
                                              ))}
                                          </Input>
                                          {validation.touched.wardId &&
                                          validation.errors.wardId ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.wardId}
                                            </FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <div className="mb-3">
                                          <FormGroup>
                                            <Label for="reason">Reasons</Label>
                                            <Input
                                              name="reason"
                                              type="text"
                                              className="form-control"
                                              id="basicpill-phoneno-input3"
                                              placeholder="Enter Reason"
                                              value={validation.values.reason}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              invalid={
                                                validation.touched.reason &&
                                                validation.errors.reason
                                              }
                                            />
                                            {validation.touched.reason &&
                                              validation.errors.reason && (
                                                <FormFeedback>
                                                  {validation.errors.reason}
                                                </FormFeedback>
                                              )}
                                          </FormGroup>
                                        </div>
                                      </Col>
                                      <Col lg="6">
                                        <div className="mb-3">
                                          <FormGroup>
                                            <Label for="comments">
                                              Comments
                                            </Label>
                                            <Input
                                              name="comments"
                                              type="text"
                                              className="form-control"
                                              id="comments"
                                              placeholder="Enter Comments"
                                              value={validation.values.comments}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              invalid={
                                                validation.touched.comments &&
                                                validation.errors.comments
                                              }
                                            />
                                            {validation.touched.comments &&
                                              validation.errors.comments && (
                                                <FormFeedback>
                                                  {validation.errors.comments}
                                                </FormFeedback>
                                              )}
                                          </FormGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="text-end">
                                      <Col lg="12">
                                        <Button color="primary" type="submit">
                                          Initiate Transfer
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Form>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddMemberTransfer;

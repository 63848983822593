import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from 'yup';
import { useFormik } from 'formik';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { useTranslation } from 'react-i18next';
import {
  addNewGroup as onAddNewGroup,
  updateGroup as onUpdateGroup,
} from 'store/actions';

import { BillingName, Total, PaymentStatus, PaymentMethod } from './GroupCol';
import { useMutation, useQueryClient } from 'react-query';

//redux
import { useDispatch } from 'react-redux';
import GroupsModal from './GroupsModal';

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from 'reactstrap';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { getAllGroupApi } from 'api/group';
import { useRole } from 'hooks/useRole';
import { getGroupStatusCountsApi } from 'api/report';
import { getSearchColumns, sumArray } from 'utils/tools';
import MainFilter from 'components/Common/MainFilter';
import { delApi } from 'api/crud';
import { allowedEmails } from 'configs/constants';

function Group() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [pages, setPages] = useState(0);
  const [, setLoading] = useState(false);
  const { t } = useTranslation();

  const {
    isFacilitator,
    isSiteCord,
    isCountyCoordinator,
    isClusterLeader,
    isFinance,
    isDirector,
    isAdmin,
    isSuperAdmin,
    addresses,
  } = useRole();

  const [addressData, setAddressData] = useState({});

  const { mutate: mutateGroupCounts, data: groupSummary } = useMutation(
    (payload) => getGroupStatusCountsApi(payload),
    {
      onSettled: () => {
        queryClient.invalidateQueries('report/group/counts');
      },
    },
  );

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: false,
    wardIds: getSearchColumns()?.find((t) => t?.name === 'wardIds')?.value,
    searchColumns: [
      ...(isFacilitator && (!allowedEmails.includes(localStorage.getItem('shofco_email')))
        ? [
            {
              name: 'wardId',
              value: addresses[0]?.wardId,
            },
          ]
        : []),
      ...(isCountyCoordinator
        ? [
            {
              name: 'countyId',
              value: addresses[0]?.countyId,
            },
          ]
        : []),
      ...(isClusterLeader
        ? [
            {
              name: 'regionId',
              value: addresses[0]?.regionId,
            },
          ]
        : []),
      ...(isSiteCord
        ? [
            {
              name: 'constituencyId',
              value: addresses[0]?.constituencyId,
            },
          ]
        : []),
    ],
  });

  const {
    mutate: mutateGroups,
    isLoading: isLoadingGroups,
    data: groupsData,
  } = useMutation((payload) => getAllGroupApi(payload), {
    onSuccess: (res) => {
      res.pages > 0 && setPages(res.pages);
    },
    onSettled: () => {
      setLoading(false);
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const groupsCardData = [
    {
      title: 'Total Groups',
      iconClass: 'bx bx-group',
      description:
        sumArray(groupSummary?.data, 'totalGroups').toLocaleString() || 0,
    },
    {
      title: 'Active Groups',
      iconClass: 'bx bx-trophy',
      description:
        sumArray(groupSummary?.data, 'totalActiveGroups').toLocaleString() || 0,
    },
    {
      title: 'Inactive Groups',
      iconClass: 'bx bx-dislike',
      description:
        sumArray(groupSummary?.data, 'totalInactiveGroups').toLocaleString() ||
        0,
    },
  ];

  //meta title
  document.title = 'Groups | SUN Welfare Administration System';

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [order, setGroup] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || '',
      billingName: (order && order.billingName) || '',
      orderdate: (order && order.orderdate) || '',
      total: (order && order.total) || '',
      paymentStatus: (order && order.paymentStatus) || 'Paid',
      badgeclass: (order && order.badgeclass) || 'success',
      paymentMethod: (order && order.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Group Id')
        .required('Please Enter Your Group Id'),
      billingName: Yup.string().required('Please Enter Your Billing Name'),
      orderdate: Yup.string().required('Please Enter Your Group Date'),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Amount')
        .required('Total Amount'),
      paymentStatus: Yup.string().required('Please Enter Your Payment Status'),
      badgeclass: Yup.string().required('Please Enter Your Badge Class'),
      paymentMethod: Yup.string().required('Please Enter Your Payment Method'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateGroup = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        dispatch(onUpdateGroup(updateGroup));
        validation.resetForm();
      } else {
        const newGroup = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values['orderId'],
          billingName: values['billingName'],
          orderdate: values['orderdate'],
          total: values['total'],
          paymentStatus: values['paymentStatus'],
          paymentMethod: values['paymentMethod'],
          badgeclass: values['badgeclass'],
        };
        // save new order
        dispatch(onAddNewGroup(newGroup));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {
    mutateGroups({
      ...pagination,
      pageNumber: pagination.pageNumber - 1,
      wardIds: getSearchColumns()?.find((t) => t?.name === 'wardIds')?.value,
    });
  }, [pagination]);

  useEffect(() => {
    mutateGroupCounts({
      wardIds: getSearchColumns()?.find((t) => t?.name === 'wardIds')?.value,
      address: addresses[0],
    });
  }, []);

  const onChangePagination = ({ page, limit }, filters, sorter) => {
    let searchColumns = isEmpty(filters)
      ? pagination.searchColumns
      : [
          ...pagination.searchColumns,
          ...(filters?.groupName
            ? [
                {
                  name: 'groupName',
                  value: filters?.groupName,
                },
              ]
            : []),
          ...(filters?.groupNumber
            ? [
                {
                  name: 'groupNumber',
                  value: filters?.groupNumber,
                },
              ]
            : []),
        ];
    setPagination({
      ...pagination,
      pageNumber: page || pagination.pageNumber,
      pageSize: limit || pagination.pageSize,
      searchColumns,
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setGroup(null);
    } else {
      setModal(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
      },
      {
        Header: 'GROUP NAME',
        accessor: 'name',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'GROUP NUMBER',
        accessor: 'groupNumber',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'DATE CREATED',
        accessor: 'createdDate',
        filterable: true,
        Cell: (cellProps) => {
          const dateValue = cellProps.value;
          const formattedDate = dateValue
            ? moment(dateValue).format('YYYY MMM DD h:mm A')
            : '';
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: 'CONSTITUENCY',
        accessor: (row) => row?.address?.constituencyName || '',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: 'WARD',
        accessor: (row) => row?.address?.wardName || '',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: 'MEMBER COUNT',
        accessor: 'totalMembers',
        Cell: (cellProps) => {
          return <PaymentMethod {...cellProps} />;
        },
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        },
      },
    ],
    [],
  );

  const onSearch = (data) => {
    const searchColumns = [];
    if (isFacilitator) {
      searchColumns.push({ name: 'wardId', value: address?.wardId });
    }
    if (isSiteCord && data?.wardId) {
      searchColumns.push(data?.wardId);
    } else {
      if (
        (isClusterLeader ||
          isFinance ||
          isDirector ||
          isAdmin ||
          isSuperAdmin) &&
        data?.regionId
      ) {
        searchColumns.push(data?.regionId);
      }
      if (data.wardId) {
        searchColumns.push(data?.wardId);
      }
      if (data.constituencyId) {
        searchColumns.push(data?.constituencyId);
      }
      if (data.countyId) {
        searchColumns.push(data?.countyId);
      }
    }
    data?.startDate && searchColumns.push(data?.startDate);
    data?.endDate && searchColumns.push(data?.endDate);

    setPagination({ ...pagination, searchColumns });

    let add = {};
    searchColumns.forEach((a, i) => {
      add[a.name] = a.value;
    });
    setAddressData(add);
  };

  const handleDelete = async (id) => {
    await delApi('api/um/group?groupId=' + id);
    mutateGroups({ ...pagination, pageNumber: pagination.pageNumber - 1 });
  };

  return (
    <React.Fragment>
      <GroupsModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Groups" />
          <Row>
            {/* Reports Render */}
            {groupsCardData.map((report, key) => (
              <Col md="4" key={'_col_' + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{report.title}</p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              'bx ' + report.iconClass + ' font-size-24'
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <MainFilter onSearch={onSearch} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={groupsData?.list?.reverse() || []}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    hasCreate={true}
                    hasSearch
                    isPagination={true}
                    filterable={false}
                    iscustomPageSizeOptions={true}
                    showEdit={
                      !isFinance &&
                      !isCountyCoordinator &&
                      !isClusterLeader &&
                      !isSiteCord
                    }
                    showView
                    handleDelete={handleDelete}
                    showDelete={
                      !isFacilitator &&
                      !isFinance &&
                      !isCountyCoordinator &&
                      !isClusterLeader &&
                      !isSiteCord
                    }
                    loading={isLoadingGroups}
                    pages={pages}
                    resource="group"
                    addText="Add New Group"
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    paginationData={pagination}
                    onChangePagination={onChangePagination}
                    searchByNames={[
                      {
                        value: 'groupName',
                        text: t('groups.groupName'),
                      },
                      {
                        value: 'groupNumber',
                        text: t('groups.groupNumber'),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? 'Edit Group' : 'Add Group'}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Group Id</Label>
                      <Input
                        name="orderId"
                        type="text"
                        placeholder="Insert Group Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.orderId || ''}
                        invalid={
                          validation.touched.orderId &&
                          validation.errors.orderId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.orderId &&
                      validation.errors.orderId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.orderId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ''}
                        invalid={
                          validation.touched.billingName &&
                          validation.errors.billingName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.billingName &&
                      validation.errors.billingName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.billingName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Group Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="orderdate"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) =>
                          validation.setFieldValue(
                            'orderdate',
                            moment(date[0]).format('DD MMMM, YYYY'),
                          )
                        }
                        value={validation.values.orderdate}
                      />
                      {validation.touched.orderdate &&
                      validation.errors.orderdate ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.orderdate}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ''}
                        invalid={
                          validation.touched.total && validation.errors.total
                            ? true
                            : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">
                          {validation.errors.total}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentStatus || ''}
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus &&
                      validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentStatus}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ''}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass &&
                      validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.badgeclass}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentMethod || ''}
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod &&
                      validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentMethod}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Group.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default Group;

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

import {
  addNewTransaction as onAddNewTransaction,
  updateTransaction as onUpdateTransaction,
} from 'store/actions';

import { TransactionId, BillingName } from './ReceiptCol';

//redux
import { useDispatch } from 'react-redux';
import TransactionsModal from './ReceiptModal';

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from 'reactstrap';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { getAllReceiptsApi } from 'api/transaction';
import { getMemberByIdApi } from 'api/member';
import { useRole } from 'hooks/useRole';
import MainFilter from 'components/Common/MainFilter';
import { getPaymentsStatusCountsApi } from 'api/report';
import { useAuth } from 'hooks/useAuth';
import { getSearchColumns, sumArray } from 'utils/tools';

function Transaction() {
  const queryClient = useQueryClient();
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const {
    isFacilitator,
    isSiteCord,
    isClusterLeader,
    isFinance,
    isDirector,
    isAdmin,
    isSuperAdmin,
  } = useRole();

  const [addressData, setAddressData] = useState({});

  const dateFormat = 'DD-MM-YYYY';
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: false,
    wardIds: getSearchColumns()?.find((t) => t?.name === 'wardIds')?.value,
    searchColumns: [
      {
        name: 'endDate',
        value: moment().format(dateFormat),
      },
      {
        name: 'startDate',
        value: moment().startOf('month').format(dateFormat),
      },
    ],
  });

  const {
    mutate: mutateReceipts,
    isLoading: isLoadingReceipts,
    data: receiptsData,
  } = useMutation((payload) => getAllReceiptsApi(payload), {
    onSuccess: (res) => {
      res.pages > 0 && setPages(res.pages);
    },
    onSettled: () => {
      setLoading(false);
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const { mutate: mutateTransactionsCounts, data: transactionsCountsData } =
    useMutation((payload) => getPaymentsStatusCountsApi(payload), {
      onSuccess: (res) => {
        // dispatch(setPaymentSummary(res?.data));
      },
      onSettled: () => {
        queryClient.invalidateQueries('/report/application/status/counts');
      },
    });

  const transactionsCardData = [
    {
      title: 'Total Payments',
      iconClass: 'bx bx-wallet',
      description: transactionsCountsData?.data?.length
        ? sumArray(
            transactionsCountsData?.data,
            'totalPremiums',
          ).toLocaleString()
        : 0,
    },
    {
      title: 'Successful Transactions',
      iconClass: 'bx bx-check-circle',
      description: transactionsCountsData?.data?.length
        ? sumArray(
            transactionsCountsData?.data,
            'successfulTransactions',
          ).toLocaleString()
        : 0,
    },
    {
      title: 'Pending Transactions',
      iconClass: 'bx bx-x-circle',
      description: transactionsCountsData?.data?.length
        ? sumArray(
            transactionsCountsData?.data,
            'pendingTransactions',
          ).toLocaleString()
        : 0,
    },
    {
      title: 'Unsuccessful Transactions',
      iconClass: 'bx bx-x-circle',
      description: transactionsCountsData?.data?.length
        ? sumArray(
            transactionsCountsData?.data,
            'unSuccessfulTransactions',
          ).toLocaleString()
        : 0,
    },
  ];

  //meta title
  document.title = 'Transactions | SUN Welfare Administration System';

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [transaction, setTransaction] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      transactionId: (transaction && transaction.transactionId) || '',
      billingName: (transaction && transaction.billingName) || '',
      transactiondate: (transaction && transaction.transactiondate) || '',
      total: (transaction && transaction.total) || '',
      paymentStatus: (transaction && transaction.paymentStatus) || 'Paid',
      badgeclass: (transaction && transaction.badgeclass) || 'success',
      paymentMethod: (transaction && transaction.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      transactionId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Transaction Id')
        .required('Please Enter Your Transaction Id'),
      billingName: Yup.string().required('Please Enter Your Billing Name'),
      transactiondate: Yup.string().required(
        'Please Enter Your Transaction Date',
      ),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Amount')
        .required('Total Amount'),
      paymentStatus: Yup.string().required('Please Enter Your Payment Status'),
      badgeclass: Yup.string().required('Please Enter Your Badge Class'),
      paymentMethod: Yup.string().required('Please Enter Your Payment Method'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateTransaction = {
          id: transaction ? transaction.id : 0,
          transactionId: values.transactionId,
          billingName: values.billingName,
          transactiondate: values.transactiondate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update transaction
        dispatch(onUpdateTransaction(updateTransaction));
        validation.resetForm();
      } else {
        const newTransaction = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          transactionId: values['transactionId'],
          billingName: values['billingName'],
          transactiondate: values['transactiondate'],
          total: values['total'],
          paymentStatus: values['paymentStatus'],
          paymentMethod: values['paymentMethod'],
          badgeclass: values['badgeclass'],
        };
        // save new transaction
        dispatch(onAddNewTransaction(newTransaction));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  useEffect(() => {
    mutateReceipts({ ...pagination, pageNumber: pagination.pageNumber - 1 });
  }, [pagination]);

  useEffect(() => {
    mutateTransactionsCounts({
      fromDate: pagination.searchColumns.find((c) => c.name === 'startDate')
        .value,
      toDate: pagination.searchColumns.find((c) => c.name === 'endDate').value,
      wardIds: getSearchColumns()?.find((t) => t.name === 'wardIds')?.value,
      address: addressData,
    });
  }, [addressData]);

  const onChangePagination = ({ page, limit }, filters, sorter) => {
    let searchColumns = isEmpty(filters)
      ? []
      : [
          filters?.id && {
            id: filters?.id,
          },
          filters?.applicationNumber && {
            applicationNumber: filters?.applicationNumber,
          },
        ];
    setPagination({
      ...pagination,
      pageNumber: page || pagination.pageNumber,
      pageSize: limit || pagination.pageSize,
      searchColumns,
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setTransaction(null);
    } else {
      setModal(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
      },
      {
        Header: 'RECEIPT NUMBER',
        accessor: 'receiptNumber',
        width: '150px',
        style: {
          textAlign: 'center',
          width: '10%',
          background: '#0000',
        },
        filterable: true,
        Cell: (cellProps) => {
          return <TransactionId {...cellProps} />;
        },
      },
      {
        Header: 'AMOUNT',
        accessor: 'amount',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'MEMBER NAME',
        accessor: 'member',
        filterable: true,
        Cell: (cellProps) => {
          const member = cellProps.value;
          return (
            <span>{`${member?.firstName || ''} ${
              member?.lastName || ''
            }`}</span>
          );
        },
      },
      {
        Header: 'TRANSACTION NUMBER',
        accessor: 'transactionRefNumber',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: 'DATE',
        accessor: 'receiptDateTime',
        filterable: true,
        Cell: (cellProps) => {
          const formattedDate = moment(cellProps.value).format(
            'YYYY MMM DD h:mm A',
          );
          return <span>{formattedDate}</span>;
        },
      },
      // {
      //   Header: 'View Details',
      //   accessor: 'view',
      //   disableFilters: true,
      //   Cell: () => {
      //     return (
      //       <Button
      //         type="button"
      //         color="primary"
      //         className="btn-sm btn-rounded"
      //         onClick={toggleViewModal}
      //       >
      //         View Details
      //       </Button>);
      //   }
      // },
    ],
    [],
  );

  const onSearch = (data) => {
    const searchColumns = [];
    if (!isEmpty(data)) {
      if (isFacilitator) {
        searchColumns.push({ name: 'wardId', value: address?.wardId });
      }
      if (isSiteCord && data?.wardId) {
        searchColumns.push(data?.wardId);
      } else {
        if (
          (isClusterLeader ||
            isFinance ||
            isDirector ||
            isAdmin ||
            isSuperAdmin) &&
          data?.regionId
        ) {
          searchColumns.push(data?.regionId);
        }
        if (data.wardId) {
          searchColumns.push(data?.wardId);
        }
        if (data.constituencyId) {
          searchColumns.push(data?.constituencyId);
        }
        if (data.countyId) {
          searchColumns.push(data?.countyId);
        }
      }
      data?.transactionNumber && searchColumns.push(data.transactionNumber);
      data?.startDate && searchColumns.push(data.startDate);
      data?.endDate && searchColumns.push(data.endDate);
    }

    setPagination({ ...pagination, searchColumns });
    let add = {};
    searchColumns.forEach((a, i) => {
      add[a.name] = a.value;
    });
    setAddressData(add);
  };

  return (
    <React.Fragment>
      <TransactionsModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Receipts" />
          <Row>
            {/* Reports Render */}
            {transactionsCardData.map((report, key) => (
              <Col md="3" key={'_col_' + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{report.title}</p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              'bx ' + report.iconClass + ' font-size-24'
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <MainFilter
            onSearch={onSearch}
            showDateFilter
            showTransFilter
            resource="transaction"
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={receiptsData || []}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    // hasCreate
                    customPageSize={10}
                    loading={isLoadingReceipts}
                    isPagination={true}
                    filterable={false}
                    iscustomPageSizeOptions={true}
                    // showDelete
                    showView
                    pages={pages}
                    addText="Add New"
                    resource="transaction"
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    paginationData={pagination}
                    onChangePagination={onChangePagination}
                    searchByNames={[
                      {
                        value: 'email',
                        text: t('common.email'),
                      },
                      {
                        value: 'idNumber',
                        text: t('common.idNumber'),
                      },
                      {
                        value: 'applicationNumber',
                        text: t('applications.applicationNumber'),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? 'Edit Transaction' : 'Add Transaction'}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Transaction Id</Label>
                      <Input
                        name="transactionId"
                        type="text"
                        placeholder="Insert Transaction Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.transactionId || ''}
                        invalid={
                          validation.touched.transactionId &&
                          validation.errors.transactionId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.transactionId &&
                      validation.errors.transactionId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.transactionId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ''}
                        invalid={
                          validation.touched.billingName &&
                          validation.errors.billingName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.billingName &&
                      validation.errors.billingName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.billingName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Transaction Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="transactiondate"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) =>
                          validation.setFieldValue(
                            'transactiondate',
                            moment(date[0]).format('DD MMMM, YYYY'),
                          )
                        }
                        value={validation.values.transactiondate}
                      />
                      {validation.touched.transactiondate &&
                      validation.errors.transactiondate ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.transactiondate}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ''}
                        invalid={
                          validation.touched.total && validation.errors.total
                            ? true
                            : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">
                          {validation.errors.total}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentStatus || ''}
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus &&
                      validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentStatus}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ''}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass &&
                      validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.badgeclass}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentMethod || ''}
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod &&
                      validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentMethod}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Transaction.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default Transaction;

import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';
import reports from './reports/reducer';

import application from './applications/reducer';
import member from './members/reducer';
import transaction from './invoices/reducer';
import category from './categories/reducer';
import communication from './communications/reducer';
import product from './products/reducer';
import cover from './covers/reducer';
import riskClass from './risk-class/reducer';
import benefit from './benefits/reducer';
import benefitType from './benefit-types/reducer';
import user from './users/reducer';
import group from './groups/reducer';
import claim from './claims/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  application,
  transaction,
  member,
  user,
  group,
  claim,
  reports,
  communication,
  product,
  category,
  cover,
  riskClass,
  benefit,
  benefitType,
});

export default rootReducer;

import {
  GET_APPLICATIONS_FAIL,
  GET_APPLICATIONS_SUCCESS,
  ADD_APPLICATION_SUCCESS,
  ADD_APPLICATION_FAIL,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_FAIL,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAIL,
} from './actionTypes';

const INIT_STATE = {
  products: [],
  product: {},
  applications: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true,
};

const Application = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applications: action.payload,
        loading: true,
      };

    case GET_APPLICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_APPLICATION_SUCCESS:
      return {
        ...state,
        applications: [...state.applications, action.payload],
      };

    case ADD_APPLICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        applications: state.applications.map((order) =>
          order.id + '' === action.payload.id + ''
            ? { order, ...action.payload }
            : order,
        ),
      };

    case UPDATE_APPLICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        applications: state.applications.filter(
          (order) => order.id !== action.payload,
        ),
      };

    case DELETE_APPLICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Application;

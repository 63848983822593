import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const EcommerceAddProduct = () => {
  //meta title
  document.title = 'Add Product | SUN Welfare Administration System';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="User" breadcrumbItem="User Details" />
          <Row>
            <Link to="/users">
              <Button type="button" color="primary" className="btn">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EcommerceAddProduct;

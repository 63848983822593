import {
  GET_MEMBERS_FAIL,
  GET_MEMBERS_SUCCESS,
  ADD_MEMBER_SUCCESS,
  GET_MEMBER_FAIL,
  GET_MEMBER_SUCCESS,
  ADD_MEMBER_FAIL,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  SET_MEMBER,
  EDIT_MEMBER,
} from './actionTypes';

const INIT_STATE = {
  products: [],
  product: {},
  member: {},
  appMember: {
    source: 'Web',
    dependants: [
      {
        title: 'Dependant 1',
        firstName: '',
        lastName: '',
        gender: '',
        email: '',
        mobileNumber: '',
        relationship: '',
      },
    ],
    address: {},
    nextOfKin: {},
  },
  members: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true,
};

const Member = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.payload,
        loading: true,
      };

    case GET_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_MEMBER_SUCCESS:
      return {
        ...state,
        members: [...state.members, action.payload],
      };

    case GET_MEMBER_SUCCESS:
      return {
        ...state,
        member: action.payload,
        loading: false,
      };

    case GET_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.map((member) =>
          member.id + '' === action.payload.id + ''
            ? { member, ...action.payload }
            : member,
        ),
      };

    case UPDATE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.filter((member) => member.id !== action.payload),
      };

    case DELETE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case SET_MEMBER:
      return {
        ...state,
        member: action.payload,
      };
    case EDIT_MEMBER:
      if (action.payload.type === 'member') {
        return {
          ...state,
          appMember: { ...state.appMember, ...action.payload.data },
        };
      } else if (action.payload.type === 'address') {
        return {
          ...state,
          appMember: {
            ...state.appMember,
            address: { ...state.appMember.address, ...action.payload.data },
          },
        };
      } else if (action.payload.type === 'dependant') {
        return {
          ...state,
          appMember: { ...state.appMember, dependants: action.payload.data },
        };
      } else if (action.payload.type === 'nextOfKin') {
        return {
          ...state,
          appMember: {
            ...state.appMember,
            nextOfKin: { ...state.appMember.nextOfKin, ...action.payload.data },
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default Member;

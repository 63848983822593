import React from 'react';
import ReactApexChart from 'react-apexcharts';
import getChartColorsArray from '../../components/Common/ChartsDynamicColor';

const DonutChart = ({ dataColors, series }) => {
  const spineareaChartColors = getChartColorsArray(dataColors);

  // const series = [
  //     430, 289
  // ]

  const options = {
    labels: ['Female', 'Male'],

    colors: spineareaChartColors,
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: false,
      fontSize: '14px',
      offsetX: 0,
      offsetY: -10,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="350"
    />
  );
};

export default DonutChart;

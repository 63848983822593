import {
  GET_COMMUNICATIONS_FAIL,
  GET_COMMUNICATIONS_SUCCESS,
  ADD_COMMUNICATION_SUCCESS,
  ADD_COMMUNICATION_FAIL,
  UPDATE_COMMUNICATION_SUCCESS,
  UPDATE_COMMUNICATION_FAIL,
  DELETE_COMMUNICATION_SUCCESS,
  DELETE_COMMUNICATION_FAIL,
} from './actionTypes';

const INIT_STATE = {
  products: [],
  product: {},
  communications: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true,
};

const Communication = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMUNICATIONS_SUCCESS:
      return {
        ...state,
        communications: action.payload,
        loading: true,
      };

    case GET_COMMUNICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_COMMUNICATION_SUCCESS:
      return {
        ...state,
        communications: [...state.communications, action.payload],
      };

    case ADD_COMMUNICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_COMMUNICATION_SUCCESS:
      return {
        ...state,
        communications: state.communications.map((communication) =>
          communication.id + '' === action.payload.id + ''
            ? { communication, ...action.payload }
            : communication,
        ),
      };

    case UPDATE_COMMUNICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_COMMUNICATION_SUCCESS:
      return {
        ...state,
        communications: state.communications.filter(
          (communication) => communication.id !== action.payload,
        ),
      };
    case DELETE_COMMUNICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Communication;

import { get, post, put, del, getQueryString, requestWrapper } from './utils';

export async function useCustomSourceApi(
  method = 'GET',
  customSource,
  resource,
  data,
) {
  return requestWrapper(method, customSource)(`/${resource}`, data);
}

export async function getAllApi(resource, data) {
  return get(`/${resource}`, data);
}

export async function getDataByIdApi(resource, id, data) {
  return get(`/${resource}/${id}`, data);
}

export async function delApi(resource, id) {
  if (id) {
    return del(`/${resource}/${id}`);
  }
  return del(`/${resource}`);
}

export async function postApi(resource, data) {
  return post(`/${resource}`, data);
}

export async function putApi(resource, id, data) {
  return put(`/${resource}/${id}`, data);
}

export async function exportExcelApi(resource, data) {
  return get(`/${resource}/exportExcel`, data);
}

export const exportExcel = async (
  resource,
  query,
  onSuccess,
  onError,
  setExporting,
) => {
  const request = new XMLHttpRequest();

  const url = `${process.env.REACT_APP_SERVER_URL}/shofco/api/${resource}/export`;

  setExporting(true);
  // Set up the request
  request.open('POST', url);
  request.setRequestHeader('Content-Type', 'application/json');

  // Send the request with the query string in the request body
  // request.send(queryString);

  request.setRequestHeader(
    'Authorization',
    `Bearer ${localStorage.getItem('sessionToken')}`,
  );
  request.responseType = 'arraybuffer';
  request.onload = () => {
    if (request.status === 200) {
      // Try to find out the filename from the content disposition `filename` value
      const disposition = request.getResponseHeader('Content-Disposition');
      console.log(disposition);
      const matches = disposition.substring(
        disposition.indexOf('filename=') + 9,
        disposition.length,
      );
      const filename =
        matches != null && matches !== '' ? matches : `${resource}.csv`;
      // The actual download
      const blob = new Blob([request.response], {
        type: request.getResponseHeader('content-type'),
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Callback for success
      if (onSuccess) onSuccess();
    } else {
      // Handle any errors during the request
      if (onError) onError(request.statusText);
    }
    // Hide loading indicator after request completes
    setExporting(false);
  };

  request.onerror = () => {
    // Handle error case (e.g., show error message)
    if (onError) onError('An error occurred while exporting data.');

    // Hide loading indicator in case of an error
    setLoading(false);
  };

  request.send(JSON.stringify(query));
};

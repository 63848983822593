import { get, post } from './utils';

export async function getAllRoleApi(data) {
  return get(`/api/public/um/role/`);
}

export async function getAllSubRolesApi(data) {
  return get(`/api/public/um/role/sub`, data);
}

export async function getRoleByIdApi(data) {
  return get(`/api/public/um/role/all/1`);
}

import React, { useState, useEffect, useCallback } from 'react';
import {
  Row,
  Form,
  FormGroup,
  Spinner,
  Label,
  Col,
  Input,
  Button,
} from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';
import { exportExcel } from 'api/crud';
import {
  getAllRegionsApi,
  getAllCountiesByRegionApi,
  getConstituencyApi,
  getAllWardsApi,
} from 'api/report';
import { useRole } from 'hooks/useRole';

const MainFilter = ({
  onSearch = () => { },
  showDateFilter = false,
  showTransFilter = false,
  hasExport = false,
  pageSize,
  pagination,
  resource,
  onChangePagination = () => { },
}) => {
  const [exporting, setExporting] = useState(false);
  const queryClient = useQueryClient();
  const dateFormat = 'YYYY-MM-DD';

  const [addressData, setAddressData] = useState({
    // fromDate: moment().startOf('month').format(dateFormat),
    fromDate: moment().subtract(7, 'days').format(dateFormat),
    toDate: moment().format(dateFormat),
  });

  const [transactionCode, setTransactionCode] = useState(null);
  const [idNumber, setIdNumber] = useState(null);

  const { isFacilitator, isSiteCord, isCountyCoordinator, addresses } =
    useRole();
  const address = addresses[0];

  useEffect(() => {
    mutateRegions({
      countryId: 1,
    });
    const { regionId, countyId, constituencyId } = address;
    regionId && mutateCounty({ regionId });
    countyId && mutateSubCounty({ countyId });
    constituencyId && mutateWards({ constituencyId });
  }, []);

  const {
    mutate: mutateRegions,
    isLoading: isLoadingRegions,
    data: regionsData,
  } = useMutation((payload) => getAllRegionsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateCounty,
    isLoading: isLoadingCounty,
    data: countiesData,
  } = useMutation((payload) => getAllCountiesByRegionApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateSubCounty,
    isLoading: isLoadingSubCounty,
    data: subCountiesData,
  } = useMutation((payload) => getConstituencyApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateWards,
    isLoading: isLoadingWards,
    data: wardsData,
  } = useMutation((payload) => getAllWardsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const onChangeRegion = (e) => {
    const regionId = e.target.value;
    setAddressData({
      ...addressData,
      regionId,
      countryId: '',
      constituencyId: '',
      wardId: '',
    });
    if (!isEmpty(regionId)) {
      mutateCounty({ regionId });
    }
  };

  const onChangeCounty = (e) => {
    const countyId = e.target.value;
    setAddressData({
      ...addressData,
      countyId,
      constituencyId: '',
      wardId: '',
    });
    if (!isEmpty(countyId)) {
      mutateSubCounty({ countyId });
    }
  };

  const onChangeSubCounty = (e) => {
    const constituencyId = e.target.value;
    setAddressData({ ...addressData, constituencyId, wardId: '' });
    if (!isEmpty(constituencyId)) {
      mutateWards({ constituencyId });
    }
  };

  const handleReset = () => {
    setAddressData({
      fromDate: moment().startOf('month').format(dateFormat),
      toDate: moment().format(dateFormat),
    });
  };

  const handleChange = (e) => {
    setAddressData({ ...addressData, [e.target.name]: e.target.value });
  };

  const handleExport = async () => {
    await exportExcel(
      resource,
      { ...pagination, pageNumber: pagination.pageNumber - 1 },
      (res) => {
        // On success: You can reset or handle success here.
        console.log(res); // Clear any previous errors
      },
      (errorMessage) => {
        // On error: Display the error message
        console.log(errorMessage);
      },
      setExporting,
    );
  };

  const handleSubmit = () => {
    const dateFormat = 'DD-MM-YYYY';
    let search = {
      ...(showDateFilter
        ? {
          startDate: {
            name: 'startDate',
            value: moment(addressData.fromDate).format(dateFormat),
          },
        }
        : {}),
      ...(showDateFilter
        ? {
          endDate: {
            name: 'endDate',
            value: moment(addressData.toDate).format(dateFormat),
          },
        }
        : {}),
      ...(addressData.wardId
        ? { wardId: { name: 'wardId', value: addressData.wardId } }
        : {}),
      ...(addressData.constituencyId
        ? {
          constituencyId: {
            name: 'constituencyId',
            value: addressData.constituencyId,
          },
        }
        : {}),
      ...(addressData.countyId
        ? { countyId: { name: 'countyId', value: addressData.countyId } }
        : {}),
      ...(addressData.regionId
        ? { regionId: { name: 'regionId', value: addressData.regionId } }
        : {}),
      ...(transactionCode
        ? {
          transactionNumber: {
            name: 'transactionNumber',
            value: transactionCode,
          },
        }
        : {}),
    }
    onSearch(search);
  };

  const makeDebounceSearch = useCallback(
    debounce((value, key) => {
      onChangePagination(
        { limit: pagination?.pageSize, page: pagination?.pageNumber },
        {
          [key]: value,
        },
      );
    }, 1000),
    [],
  );

  const onChangeTransactionCode = async (e) => {
    const value = e.target.value;
    setTransactionCode(value);
    makeDebounceSearch(value, "transactionNumber");
  };

  const onChangeIdNumber = async (e) => {
    const value = e.target.value;
    setIdNumber(value);
    makeDebounceSearch(value, "idNumber");
  };

  return (
    <Form>
      <Row className="mb-2">
        <Col md={24} className="d-flex justify-content-end">
          <Row>
            {hasExport && (
              <Col>
                <Button
                  disabled={exporting}
                  size="sm"
                  block
                  color="success"
                  onClick={handleExport}
                  className="d-flex align-items-center justify-content-between"
                >
                  <>
                    <span className="me-2">Export</span>
                    {exporting ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      <i className="bx bxs-file font-size-24"></i>
                    )}
                  </>
                </Button>
              </Col>
            )}
            <>
              <Col>
                <Button block color="light" onClick={handleReset}>
                  Reset
                </Button>
                &nbsp;&nbsp;
              </Col>
              <Col>
                <Button
                  block
                  color="primary"
                  onClick={handleSubmit}
                  className="pl-5 pr-5"
                >
                  Search
                </Button>
              </Col>
            </>
          </Row>
        </Col>
      </Row>
      <Row>
        {!isFacilitator && !isSiteCord && !isCountyCoordinator && (
          <Col>
            <FormGroup className="mb-3">
              <Label for="regionId">Select Region</Label>
              <Input
                type="select"
                className="form-select"
                name="regionId"
                id="regionId"
                value={addressData?.regionId || ''}
                onChange={onChangeRegion}
              >
                <option defaultValue value={''}>
                  Select Region...
                </option>
                {regionsData &&
                  regionsData.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
        )}
        {!isFacilitator && !isSiteCord && !isCountyCoordinator && (
          <Col>
            <FormGroup className="mb-3">
              <Label for="countyId">Select County</Label>
              <Input
                type="select"
                className="form-select"
                id="countyId"
                name="countyId"
                value={addressData?.countyId || ''}
                onChange={onChangeCounty}
              >
                <option defaultValue value={''}>
                  Select County...
                </option>
                {countiesData &&
                  countiesData.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
        )}
        {!isFacilitator && !isSiteCord && (
          <Col>
            <FormGroup className="mb-3">
              <Label for="constituencyId">Select Constituency</Label>
              <Input
                type="select"
                className="form-select"
                name="constituencyId"
                id="constituencyId"
                value={addressData?.constituencyId || ''}
                onChange={onChangeSubCounty}
              >
                <option defaultValue value={''}>
                  Select Constituency...
                </option>
                {subCountiesData &&
                  subCountiesData.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
        )}
        {!isFacilitator && (
          <Col>
            <FormGroup className="mb-3">
              <Label for="wardId">Select Ward</Label>
              <Input
                type="select"
                className="form-select"
                id="wardId"
                name="wardId"
                value={addressData?.wardId || ''}
                onChange={(e) => handleChange(e)}
              >
                <option defaultValue value={''}>
                  Select Ward...
                </option>
                {wardsData?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        )}
        {showDateFilter && (
          <>
            <Col>
              <FormGroup className="mb-3">
                <Label for={`fromDate`}>Start Date</Label>
                <Input
                  name="fromDate"
                  type="date"
                  className="form-control"
                  id="fromDate"
                  placeholder="Select Start Date"
                  onChange={(e) => handleChange(e)}
                  value={addressData.fromDate}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-3">
                <Label for={`toDate`}>End Date</Label>
                <Input
                  name="toDate"
                  type="date"
                  className="form-control"
                  id="toDate"
                  placeholder="Select Start Date"
                  onChange={(e) => handleChange(e)}
                  value={addressData.toDate}
                />
              </FormGroup>
            </Col>
          </>
        )}
      </Row>
      {showTransFilter && (
        <Row>
          <Col md={5}>
            <FormGroup className="mb-3">
              <Label for={`transactionId`}>Transaction Code</Label>
              <Input
                name="transactionId"
                type="text"
                className="form-control"
                id="transactionId"
                placeholder="Enter Transaction Code"
                onChange={onChangeTransactionCode}
                value={transactionCode || ''}
              />
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup className="mb-3">
              <Label for={`transactionId`}>Account Number</Label>
              <Input
                name="idNumber"
                type="text"
                className="form-control"
                id="idNumber"
                placeholder="Enter Account Number"
                onChange={onChangeIdNumber}
                value={idNumber || ''}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default MainFilter;

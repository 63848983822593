import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Table,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import avatar1 from '../../assets/images/users/avatar-1.jpg';
import profileImg from '../../assets/images/profile-img.png';
import GroupMembers from '../../components/members/GroupMembers';
import { getGroupDetailsByIdApi } from 'api/group';
import { useSelector, useDispatch } from 'react-redux';
import { sumArray } from 'utils/tools';
import {
  getMemberStatusCountsApi,
  getPaymentsStatusCountsApi,
} from 'api/report';
import { setMemberSummary } from 'store/actions';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Button } from 'reactstrap';

const GroupDetails = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [groupData, setGroupData] = useState({});
  const { id } = useParams();

  const dateFormat = 'DD-MM-YYYY';

  const startDate = moment().startOf('month').format(dateFormat);
  const endDate = moment().format(dateFormat);
  // const {
  //   memberSummary,
  // } = useSelector((state) => ({
  //   memberSummary: state.reports.members,
  // }));

  const {
    mutate: mutateMemberCounts,
    isLoading: isLoadingProducts,
    data,
  } = useMutation((payload) => getMemberStatusCountsApi(payload), {
    onSuccess: (res) => {
      dispatch(setMemberSummary(res?.data));
    },
    onSettled: () => {
      queryClient.invalidateQueries('/report/member/counts');
    },
  });

  const { mutate: mutatePaymentCounts, data: paymentsSummary } = useMutation(
    (payload) => getPaymentsStatusCountsApi(payload),
    {
      onSuccess: (res) => {
        // dispatch(setPaymentSummary(res?.data));
      },
      onSettled: () => {
        queryClient.invalidateQueries('get-all-counties');
      },
    },
  );

  useEffect(() => {
    const fetchGroupDetails = async () => {
      try {
        const response = await getGroupDetailsByIdApi(id);
        setGroupData(response);
      } catch (error) {
        console.error('Error fetching group details:', error);
      }
    };

    fetchGroupDetails();

    mutateMemberCounts({
      fromDate: startDate,
      toDate: endDate,
      groupId: id,
      address: {},
    });
    mutatePaymentCounts({
      fromDate: startDate,
      toDate: endDate,
      groupId: id,
      address: {},
    });
  }, [id]);

  //meta title
  document.title = 'Group | Details';

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs title="Groups" breadcrumbItem="Group Details" />

        <Row>
          <Link to="/groups">
            <Button type="button" color="primary" className="btn">
              <i className="bx bx-arrow-back"></i> Back
            </Button>
          </Link>
          <Col xl="4" className="mt-4">
            <Card className="overflow-hidden">
              <div className="bg-primary-subtle">
                <Row>
                  <Col xs="7">
                    <div className="text-primary p-3">
                      <h5 className="text-primary">SUN WELFARE</h5>
                      <p>Group Details</p>
                    </div>
                  </Col>
                  <Col xs="5" className="align-self-end">
                    <img src={profileImg} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <Row>
                  <Col sm="4">
                    <div className="avatar-md profile-user-wid mb-4">
                      <img
                        src={avatar1}
                        alt=""
                        className="img-thumbnail rounded-circle"
                      />
                    </div>
                    <h5 className="font-size-15 text-truncate">
                      {groupData?.name}
                    </h5>
                    <p className="text-muted mb-0 text-truncate">
                      Members: {groupData?.totalMembers}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Group Information</CardTitle>
                <p className="text-muted mb-4">Here!</p>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Name :</th>
                        <td>{groupData.name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Group Number :</th>
                        <td>{groupData.groupNumber}</td>
                      </tr>
                      <tr>
                        <th scope="row">Status :</th>
                        <td>{groupData.status}</td>
                      </tr>
                      <tr>
                        <th scope="row">Location :</th>
                        <td>
                          {groupData.address
                            ? `${groupData.address.wardName}, ${groupData.address.constituencyName}`
                            : ''}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            {/* <Card>
                <CardBody>
                  <CardTitle className="mb-4">Group Leader Information</CardTitle>
                  <p className="text-muted mb-4">Here!</p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>

                        <>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>Dennis Baraka</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>07709707443</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>Dennis@gmail.com</td>
                          </tr>
                          <tr>
                            <th scope="row">Designation :</th>
                            <td>Group Leader</td>
                          </tr>
                        </>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card> */}
          </Col>
          <Col xl="8" className="mt-4">
            <Row>
              <Col>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Total</p>
                        <h4 className="mb-0">
                          {data?.data?.length
                            ? sumArray(data?.data, 'totalMembers')
                            : 0}
                        </h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className="bx bx-file font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Active</p>
                        <h4 className="mb-0">
                          {data?.data?.length
                            ? sumArray(data?.data, 'totalActiveMembers')
                            : 0}
                        </h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className="bx bx-file font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Pending</p>
                        <h4 className="mb-0">
                          {data?.data?.length
                            ? sumArray(data?.data, 'totalPendingMembers')
                            : 0}
                        </h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className="bx bx-file font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Default</p>
                        <h4 className="mb-0">
                          {data?.data?.length
                            ? sumArray(data?.data, 'totalDefaultMembers')
                            : 0}
                        </h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className="bx bx-file font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Payments</p>
                        <h4 className="mb-0">
                          {paymentsSummary?.data?.length
                            ? sumArray(paymentsSummary?.data, 'totalPremiums')
                            : 0}
                        </h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className="bx bx-file font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <GroupMembers groupId={id} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GroupDetails;

import React, { useState, useEffect } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Table,
  Input,
  Label,
  Row,
} from 'reactstrap';
import logo from '../../assets/images/logo-dark2.png';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getReceiptDetailsByIdApi } from 'api/transaction';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const ReceiptDetails = () => {
  const [receiptData, setReceiptData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchReceiptDetails = async () => {
      try {
        const response = await getReceiptDetailsByIdApi(id);
        setReceiptData(response);
      } catch (error) {
        console.error('Error fetching receipt details:', error);
      }
    };

    fetchReceiptDetails();
  }, [id]);

  const calculateTotal = () => {
    if (!receiptData.items || receiptData.items.length === 0) {
      return '0.00';
    }

    const subtotal = receiptData.invoiceDTO?.items.reduce(
      (acc, item) => acc + item.totalAmount,
      0,
    );
    const total =
      subtotal + (subtotal * receiptData.invoiceDTO?.taxAmount) / 100;
    return total.toFixed(2);
  };

  //meta title
  document.title = 'Add Product | SUN Welfare Administration System';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Receipts" breadcrumbItem="Receipt Details" />
          <Link to="/payments">
            <Button type="button" color="primary" className="btn">
              <i className="bx bx-arrow-back"></i> Back
            </Button>
          </Link>
          <Card style={{ maxWidth: '60%', margin: '0 auto' }}>
            <CardBody>
              <Row>
                {/* Logo */}
                <Col md={4}>
                  <img
                    src={logo}
                    alt="Company Logo"
                    style={{ maxWidth: '200px' }}
                  />
                </Col>
                {/* Title and Date */}
                <Col md={12} className="text-center">
                  <h4>PAYMENT RECEIPT</h4>
                  <p>
                    Date :{' '}
                    {moment(receiptData.receiptDateTime).format(
                      'YYYY MMM DD h:mm A',
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="text-center mb-4">
                  <h5>Receipt Number: {receiptData.receiptNumber}</h5>
                </Col>
              </Row>
              <Row>
                {/* Details */}
                <Col md={12}>
                  <h6>From: SUN WELFARE</h6>
                  <h6>Status: {receiptData.invoiceDTO?.status}</h6>
                  <h6>Amount Paid: KES {receiptData.amount}</h6>
                  <h6>Payment Method: MPESA</h6>
                </Col>
                {/* Items Table */}
                <Col md={12}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Cost Per Unit</th>
                        <th>Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {receiptData.invoiceDTO?.items?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.description}</td>
                          <td>{item.costPerUnit}</td>
                          <td>{item.totalAmount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="mt-4">
                {/* Subtotal and Total */}
                <Col md={10} className="text-end">
                  <h6>Subtotal:</h6>
                </Col>
                <Col md={2}>
                  <p>
                    {receiptData.invoiceDTO?.items
                      ? receiptData.invoiceDTO.items.reduce(
                          (subtotal, item) => subtotal + item.totalAmount,
                          0,
                        )
                      : '0.00'}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={10} className="text-end">
                  <h6>Total Amount:</h6>
                </Col>
                <Col md={2}>
                  <p>
                    {receiptData.invoiceDTO?.items
                      ? receiptData.invoiceDTO.items.reduce(
                          (total, item) => total + item.totalAmount,
                          0,
                        )
                      : '0.00'}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReceiptDetails;

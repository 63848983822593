import {
  GET_CLAIMS,
  GET_CLAIMS_FAIL,
  GET_CLAIMS_SUCCESS,
  ADD_NEW_CLAIM,
  ADD_CLAIM_SUCCESS,
  ADD_CLAIM_FAIL,
  UPDATE_CLAIM,
  UPDATE_CLAIM_SUCCESS,
  UPDATE_CLAIM_FAIL,
  DELETE_CLAIM,
  DELETE_CLAIM_SUCCESS,
  DELETE_CLAIM_FAIL,
} from './actionTypes';

export const getClaims = () => ({
  type: GET_CLAIMS,
});

export const getClaimsSuccess = (orders) => ({
  type: GET_CLAIMS_SUCCESS,
  payload: orders,
});

export const getClaimsFail = (error) => ({
  type: GET_CLAIMS_FAIL,
  payload: error,
});

export const addNewClaim = (order) => ({
  type: ADD_NEW_CLAIM,
  payload: order,
});

export const addClaimSuccess = (order) => ({
  type: ADD_CLAIM_SUCCESS,
  payload: order,
});

export const addClaimFail = (error) => ({
  type: ADD_CLAIM_FAIL,
  payload: error,
});

export const updateClaim = (order) => ({
  type: UPDATE_CLAIM,
  payload: order,
});

export const updateClaimSuccess = (order) => ({
  type: UPDATE_CLAIM_SUCCESS,
  payload: order,
});

export const updateClaimFail = (error) => ({
  type: UPDATE_CLAIM_FAIL,
  payload: error,
});

export const deleteClaim = (order) => ({
  type: DELETE_CLAIM,
  payload: order,
});

export const deleteClaimSuccess = (order) => ({
  type: DELETE_CLAIM_SUCCESS,
  payload: order,
});

export const deleteClaimFail = (error) => ({
  type: DELETE_CLAIM_FAIL,
  payload: error,
});

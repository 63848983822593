import { createContext, useReducer } from 'react';
import moment from 'moment';

const initialState = {
  user: null,
  configs: null,
  collapsed: 1,
  summaryData: {},
  accountError: null,
  isAuthenticated: !!localStorage.getItem('sessionToken'),
  startDate: moment().startOf('month').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  roleId: localStorage.getItem('shofco_role_id'),
};

function reducer(state, action) {
  switch (action.type) {
    case 'setCollapsed':
      state.collapsed = action?.collapsed;
      return state;
    case 'setSummaryData':
      state.summaryData = {
        ...state.summaryData,
        ...action?.summaryData,
      };
      return state;
    case 'registerGoogle':
      state.isAuthenticated = true;
      localStorage.setItem('sessionToken', action?.token);
      return state;
    case 'setUser':
      state.user = action.payload;
      state.isAuthenticated = true;
      return state;
    case 'register':
      state.loading = true;
      return state;
    case 'setFilterDates':
      state.startDate = action?.payload?.startDate || state.startDate;
      state.endDate = action?.payload?.endDate || state.endDate;
      return state;
    case 'registerSuccess':
      state.loading = false;
      // state.isAuthenticated = true;
      // localStorage.setItem('sessionToken', action?.payload?.accessToken);
      return state;
    case 'registerFail':
      state.loading = false;
      return state;
    case 'login':
      state.loading = true;
      return state;
    case 'loginSuccess':
      state.loading = false;
      state.isAuthenticated = true;
      state.roleId = action?.payload?.roleId;
      localStorage.setItem('shofco_username', action?.payload?.firstName);
      localStorage.setItem('shofco_email', action?.payload?.email);
      localStorage.setItem('shofco_role', action?.payload?.role);
      localStorage.setItem('shofco_role_id', action?.payload?.roleId);
      localStorage.setItem('sessionToken', action?.payload?.authToken);
      localStorage.setItem('userId', action?.payload?.userId);
      localStorage.setItem(
        'addresses',
        JSON.stringify(action?.payload?.addresses),
      );
      return state;
    case 'loginFail':
      state.loading = false;
      state.accountError = action?.payload;
      return state;
    case 'logout':
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem('sessionToken');
      localStorage.removeItem('shofco_username');
      localStorage.removeItem('shofco_email');
      localStorage.removeItem('shofco_role');
      localStorage.removeItem('shofco_role_id');
      localStorage.removeItem('addresses');
      localStorage.removeItem('user');
      localStorage.removeItem('userId');
      localStorage.removeItem('appUserId');
      localStorage.removeItem('appUserId');
      localStorage.removeItem('memberId');
      return state;
    case 'setConfigs':
      state.configs = action.configs;
      return state;
    default:
      throw new Error();
  }
}

export const useStore = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return [state, dispatch];
};

export const StoreContext = createContext({
  state: {},
  dispatch: () => {},
});

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from 'yup';
import { useFormik } from 'formik';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { useNavigate } from 'react-router-dom';

import {
  getRoles as onGetRoles,
  addNewRole as onAddNewRole,
  updateRole as onUpdateRole,
  deleteRole as onDeleteRole,
} from 'store/actions';

import {
  RoleId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from './RolesCol';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { createSelector } from 'reselect';
import RolesModal from './RoleModal';
import { getAllRoleApi } from 'api/role';

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from 'reactstrap';
import moment from 'moment';
import Spinners from 'components/Common/Spinner';
import { ToastContainer } from 'react-toastify';

function Role() {
  const [pages, setPages] = useState(0);
  //meta title
  document.title = 'Roles | SUN Welfare Administration System';

  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();

  const [pagination, setPagination] = useState({
    pageSize: 100,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: true,
    searchColumns: [],
  });

  const {
    mutate: mutateRoles,
    isLoading: isLoadingRoles,
    data: rolesData,
  } = useMutation((payload) => getAllRoleApi(payload), {
    onSuccess: (res) => {
      res.pages > 0 && setPages(res.pages);
    },
    onSettled: () => {
      setLoading(false);
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [member, setRole] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      memberId: (member && member.memberId) || '',
      billingName: (member && member.billingName) || '',
      memberdate: (member && member.memberdate) || '',
      total: (member && member.total) || '',
      paymentStatus: (member && member.paymentStatus) || 'Paid',
      badgeclass: (member && member.badgeclass) || 'success',
      paymentMethod: (member && member.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      memberId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Role Id')
        .required('Please Enter Your Role Id'),
      billingName: Yup.string().required('Please Enter Your Billing Name'),
      memberdate: Yup.string().required('Please Enter Your Role Date'),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Amount')
        .required('Total Amount'),
      paymentStatus: Yup.string().required('Please Enter Your Payment Status'),
      badgeclass: Yup.string().required('Please Enter Your Badge Class'),
      paymentMethod: Yup.string().required('Please Enter Your Payment Method'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateRole = {
          id: member ? member.id : 0,
          memberId: values.memberId,
          billingName: values.billingName,
          memberdate: values.memberdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update member
        dispatch(onUpdateRole(updateRole));
        validation.resetForm();
      } else {
        const newRole = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          memberId: values['memberId'],
          billingName: values['billingName'],
          memberdate: values['memberdate'],
          total: values['total'],
          paymentStatus: values['paymentStatus'],
          paymentMethod: values['paymentMethod'],
          badgeclass: values['badgeclass'],
        };
        // save new member
        dispatch(onAddNewRole(newRole));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  const selectRoleState = (state) => state.members;
  const RoleProperties = createSelector(selectRoleState, (Role) => ({
    members: Role.members,
    loading: Role.loading,
  }));

  useEffect(() => {
    mutateRoles({ ...pagination, pageNumber: pagination.pageNumber - 1 });
  }, [pagination]);

  const onChangePagination = ({ page, limit }, filters, sorter) => {
    console.log(filters);
    let searchColumns = isEmpty(filters)
      ? []
      : [
          filters?.id && {
            id: filters?.id,
          },
          filters?.applicationNumber && {
            applicationNumber: filters?.applicationNumber,
          },
        ];
    setPagination({
      ...pagination,
      pageNumber: page || pagination.pageNumber,
      pageSize: limit || pagination.pageSize,
      searchColumns,
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setRole(null);
    } else {
      setModal(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
      },
      {
        Header: 'ID',
        accessor: 'id',
        width: '150px',
        style: {
          textAlign: 'center',
          width: '10%',
          background: '#0000',
        },
        filterable: true,
        Cell: (cellProps) => {
          return <RoleId {...cellProps} />;
        },
      },
      {
        Header: 'ROLE NAME',
        accessor: 'name',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      // {
      //   Header: 'View Details',
      //   accessor: 'view',
      //   disableFilters: true,
      //   Cell: () => {
      //     return (
      //       <Button
      //         type="button"
      //         color="primary"
      //         className="btn-sm btn-rounded"
      //         onClick={toggleViewModal}
      //       >
      //         View Details
      //       </Button>);
      //   }
      // },
    ],
    [],
  );

  return (
    <React.Fragment>
      <RolesModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Roles" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={rolesData || []}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    // hasCreate
                    isPagination={true}
                    filterable={false}
                    iscustomPageSizeOptions={true}
                    showDelete
                    showEdit
                    showView
                    loading={isLoadingRoles}
                    pages={pages}
                    resource="role"
                    addText="Add New Role"
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    paginationData={pagination}
                    onChangePagination={onChangePagination}
                    searchByNames={[
                      {
                        value: 'email',
                        text: t('common.email'),
                      },
                      {
                        value: 'idNumber',
                        text: t('common.idNumber'),
                      },
                      {
                        value: 'applicationNumber',
                        text: t('applications.applicationNumber'),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? 'Edit Role' : 'Add Role'}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Role Id</Label>
                      <Input
                        name="memberId"
                        type="text"
                        placeholder="Insert Role Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.memberId || ''}
                        invalid={
                          validation.touched.memberId &&
                          validation.errors.memberId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.memberId &&
                      validation.errors.memberId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.memberId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ''}
                        invalid={
                          validation.touched.billingName &&
                          validation.errors.billingName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.billingName &&
                      validation.errors.billingName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.billingName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Role Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="memberdate"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) =>
                          validation.setFieldValue(
                            'memberdate',
                            moment(date[0]).format('DD MMMM, YYYY'),
                          )
                        }
                        value={validation.values.memberdate}
                      />
                      {validation.touched.memberdate &&
                      validation.errors.memberdate ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.memberdate}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ''}
                        invalid={
                          validation.touched.total && validation.errors.total
                            ? true
                            : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">
                          {validation.errors.total}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentStatus || ''}
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus &&
                      validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentStatus}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ''}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass &&
                      validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.badgeclass}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentMethod || ''}
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod &&
                      validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentMethod}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Role.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default Role;

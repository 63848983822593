import React, { useEffect, useState } from 'react';
import { VectorMap } from '@south-paw/react-vector-maps';
import {
  Row,
  Col,
  Card,
  CardBody,
  Tooltip,
  CardTitle,
  UncontrolledTooltip,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import './map.scss';
import { getAllCountiesApi, getConstituencyApi } from 'api/report';
// import PopularCard from '../../commons/PopularCard';
import { numberWithCommas, upperFirstChar, lowerAllChar } from 'utils/tools';
import MapInformation from './MapInformation';
import kenyaMap from 'configs/kenya.json';

const popularityData = [
  {
    key: 'strong-holds',
    value: 24,
    title: 'STRONG HOLDS',
    color: '#0A2458',
    textColor: '#fff',
  },
  {
    key: 'neutral',
    value: 7,
    title: 'NEUTRAL',
    textColor: '#0A2458',
  },
  {
    key: 'opposition',
    value: 10,
    title: 'OPPOSITION',
    color: '#d86921',
    textColor: '#0A2458',
  },
];

export default function MapList() {
  // const { t } = useTranslation();
  const [countyMap, setCountyMap] = useState({});
  const [county, setCounty] = useState({});
  const [subCounty, setSubCounty] = useState({});
  const [ward, setWard] = useState({});
  const [current, setCurrent] = useState('Nairobi');
  const [countyKey, setCountyKey] = useState('ke-47');
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const queryClient = useQueryClient();

  const {
    mutate: mutateCounties,
    isLoading: isLoadingCounties,
    data: countiesData,
  } = useMutation((payload) => getAllCountiesApi(payload), {
    onSuccess: (res) => {
      setCounty(res.find((c) => c.id === 47));
    },
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  useEffect(() => {
    if (countyKey) {
      setCurrentCounty(countyKey);
    }
    mutateCounties({
      countryId: 1,
    });
  }, []);

  const setCurrentCounty = (key) => {
    const county = kenyaMap.layers.find((c) => c.id === key);
    let countyMap = {};
    countyMap['id'] = lowerAllChar(county.name);
    countyMap['name'] = county.name;
    countyMap['viewBox'] = '0 0 457.63434 580.54065';
    countyMap['transform'] = 'scale(2)';
    countyMap['layers'] = [county];
    setCountyMap(countyMap);
  };

  const onMouseOver = (e) => {
    setCurrent(e.target.attributes['aria-label'].nodeValue);
  };

  const onMouseMove = () => setIsTooltipVisible(true);

  const onMouseOut = () => {
    setCurrent('');
    setIsTooltipVisible(false);
  };

  const onClick = (e) => {
    const id = e.target.attributes.id.nodeValue;
    setCountyKey(id);
    setCurrentCounty(id);
    setCounty(countiesData.find((c) => c.id === Number(id.replace(/\D/g, ''))));
  };

  const spanAreas = ['ke-11', 'ke-13', 'ke-17', 'ke-29', 'ke-32'];

  const layerProps = {
    onMouseOver,
    onMouseMove,
    onMouseOut,
    onClick,
  };

  return (
    <div className="page-wrapper">
      <Row>
        <Col>
          {/* <Row>
                        <Col span={24}>
                            <PageHeader
                                title={t('votersMap.header')}
                                breadCrumbs={breadCrumbs}
                            />
                        </Col>
                        <Col span={20}>
                            <CountiesFilter onChangePagination={onChangePagination} />
                        </Col>
                    </Row> */}
        </Col>
        <Col flex={1}>
          <Row gutter={[8, 15]} xs={6} align="middle">
            {popularityData.map((item) => (
              <Col span={8} md={8} xs={12} key={item.key}>
                {/* <PopularCard
                                    color={item.color}
                                    header={item.title}
                                    value={item.value}
                                    textColor={item.textColor}
                                /> */}
              </Col>
            ))}
          </Row>
        </Col>
        {/* <Col md={12}>
                    <hr style={{ margin: '14px 0' }} />
                </Col> */}
        <Col md={12}>
          <Row className="page-content">
            <Col md={6}>
              {countiesData && (
                <div className="map-wrapper">
                  <VectorMap
                    {...kenyaMap}
                    // checkedLayers={spanAreas}
                    currentLayers={[countyKey]}
                    layerProps={layerProps}
                  />
                </div>
              )}
              {countiesData &&
                countiesData[0] &&
                countiesData.map((item) => (
                  <div className="map-wrapper" key={JSON.stringify(item)}>
                    <MouseTooltip
                      visible={isTooltipVisible}
                      offsetX={15}
                      offsetY={10}
                      style={{
                        padding: '0.25rem',
                        background: 'white',
                        color: 'black',
                      }}
                    >
                      <span className="fs-5">{current}</span>
                    </MouseTooltip>
                  </div>
                ))}
            </Col>
            <Col md={6}>
              <MapInformation county={county} isLoading={isLoadingCounties} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

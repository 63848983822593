import {
  GET_BENEFIT_TYPES,
  GET_BENEFIT_TYPES_FAIL,
  GET_BENEFIT_TYPES_SUCCESS,
  ADD_NEW_BENEFIT_TYPE,
  ADD_BENEFIT_TYPE_SUCCESS,
  ADD_BENEFIT_TYPE_FAIL,
  UPDATE_BENEFIT_TYPE,
  UPDATE_BENEFIT_TYPE_SUCCESS,
  UPDATE_BENEFIT_TYPE_FAIL,
  DELETE_BENEFIT_TYPE,
  DELETE_BENEFIT_TYPE_SUCCESS,
  DELETE_BENEFIT_TYPE_FAIL,
} from './actionTypes';

export const getBenefitTypes = () => ({
  type: GET_BENEFIT_TYPES,
});

export const getBenefitTypesSuccess = (benefitTypes) => ({
  type: GET_BENEFIT_TYPES_SUCCESS,
  payload: benefitTypes,
});

export const getBenefitTypesFail = (error) => ({
  type: GET_BENEFIT_TYPES_FAIL,
  payload: error,
});

export const addNewBenefitType = (benefitType) => ({
  type: ADD_NEW_BENEFIT_TYPE,
  payload: benefitType,
});

export const addBenefitTypeSuccess = (benefitType) => ({
  type: ADD_BENEFIT_TYPE_SUCCESS,
  payload: benefitType,
});

export const addBenefitTypeFail = (error) => ({
  type: ADD_BENEFIT_TYPE_FAIL,
  payload: error,
});

export const updateBenefitType = (benefitType) => ({
  type: UPDATE_BENEFIT_TYPE,
  payload: benefitType,
});

export const updateBenefitTypeSuccess = (benefitType) => ({
  type: UPDATE_BENEFIT_TYPE_SUCCESS,
  payload: benefitType,
});

export const updateBenefitTypeFail = (error) => ({
  type: UPDATE_BENEFIT_TYPE_FAIL,
  payload: error,
});

export const deleteBenefitType = (benefitType) => ({
  type: DELETE_BENEFIT_TYPE,
  payload: benefitType,
});

export const deleteBenefitTypeSuccess = (benefitType) => ({
  type: DELETE_BENEFIT_TYPE_SUCCESS,
  payload: benefitType,
});

export const deleteBenefitTypeFail = (error) => ({
  type: DELETE_BENEFIT_TYPE_FAIL,
  payload: error,
});

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { getSearchColumns, sumArray } from 'utils/tools';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

import {
  addNewCommunication as onAddNewCommunication,
  updateCommunication as onUpdateCommunication,
} from 'store/actions';

import {
  CommunicationId,
  Content,
  Recipient,
  DeliveryMethod,
  Status,
  DateSent,
  MessageType,
} from './CommunicationCol';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { createSelector } from 'reselect';
import { getAllotificationsApi } from 'api/notification';
import CommunicationsModal from './CommunicationsModal';

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from 'reactstrap';
import moment from 'moment';
import Spinners from 'components/Common/Spinner';
import { ToastContainer } from 'react-toastify';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
import MainFilter from 'components/Common/MainFilter';
import { getCommunicationCountsApi } from 'api/report';

function Communication() {
  const queryClient = useQueryClient();
  const [pages, setPages] = useState(0);
  const { t } = useTranslation();

  const {
    isFacilitator,
    isSiteCord,
    isCountyCoordinator,
    isClusterLeader,
    isFinance,
    isDirector,
    isAdmin,
    isSuperAdmin,
    addresses,
  } = useRole();
  const { isAuthenticated } = useAuth();
  const { wardId, constituencyId, countyId, regionId } = isAuthenticated
    ? addresses[0]
    : {};

  const [addressData, setAddressData] = useState({});

  const dateFormat = 'DD-MM-YYYY';
  const [pagination, setPagination] = useState({
    pageSize: 100,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: true,
    wardIds: getSearchColumns()?.find((t) => t.name === 'wardIds')?.value,
    searchColumns: [
      ...(isFacilitator
        ? [
            {
              name: 'wardId',
              value: wardId,
            },
          ]
        : []),
      ...(isCountyCoordinator
        ? [
            {
              name: 'countyId',
              value: countyId,
            },
          ]
        : []),
      ...(isClusterLeader
        ? [
            {
              name: 'regionId',
              value: regionId,
            },
          ]
        : []),
      ...(isSiteCord
        ? [
            {
              name: 'constituencyId',
              value: constituencyId,
            },
          ]
        : []),
      {
        name: 'endDate',
        value: moment().format(dateFormat),
      },
      {
        name: 'startDate',
        value: moment().startOf('month').format(dateFormat),
      },
    ],
  });

  const {
    mutate: mutateCommunications,
    isLoading: isLoadingCommunications,
    data: communicationsData,
  } = useMutation((payload) => getAllotificationsApi(payload), {
    onSuccess: (res) => {
      res.pages > 0 && setPages(res.pages);
    },
    onSettled: () => {
      setLoading(false);
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const { mutate: mutateCommunicationCounts, data: communicationsSummary } =
    useMutation((payload) => getCommunicationCountsApi(payload), {
      onSuccess: (res) => {
        // dispatch(setApplicationSummary(res?.data));
      },
      onSettled: () => {
        queryClient.invalidateQueries('/report/application/status/counts');
      },
    });

  const communicastionsCardData = [
    {
      title: 'Total',
      iconClass: 'bx bx-message',
      description: communicationsSummary?.data?.length
        ? sumArray(communicationsSummary?.data, 'totalCommunications')
        : 0,
    },
    {
      title: 'SMS',
      iconClass: 'bx bx-message',
      description: communicationsSummary?.data?.length
        ? sumArray(communicationsSummary?.data, 'totalSMS')
        : 0,
    },
    {
      title: 'EMAILS',
      iconClass: 'bx bx-envelope',
      description: communicationsSummary?.data?.length
        ? sumArray(communicationsSummary?.data, 'totalEmails')
        : 0,
    },
    {
      title: 'Delivered',
      iconClass: 'bx bx-message',
      description: communicationsSummary?.data?.length
        ? sumArray(communicationsSummary?.data, 'totalDelivered')
        : 0,
    },
    {
      title: 'Undelivered',
      iconClass: 'bx bx-envelope',
      description: communicationsSummary?.data?.length
        ? sumArray(communicationsSummary?.data, 'totalUnDelivered')
        : 0,
    },
  ];

  //meta title
  document.title = 'Communications | SUN Welfare Administration System';

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [order, setCommunication] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || '',
      billingName: (order && order.billingName) || '',
      orderdate: (order && order.orderdate) || '',
      total: (order && order.total) || '',
      paymentStatus: (order && order.paymentStatus) || 'Paid',
      badgeclass: (order && order.badgeclass) || 'success',
      paymentMethod: (order && order.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Communication Id')
        .required('Please Enter Your Communication Id'),
      billingName: Yup.string().required('Please Enter Your Billing Name'),
      orderdate: Yup.string().required('Please Enter Your Communication Date'),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Amount')
        .required('Total Amount'),
      paymentStatus: Yup.string().required('Please Enter Your Payment Status'),
      badgeclass: Yup.string().required('Please Enter Your Badge Class'),
      paymentMethod: Yup.string().required('Please Enter Your Payment Method'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateCommunication = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        dispatch(onUpdateCommunication(updateCommunication));
        validation.resetForm();
      } else {
        const newCommunication = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values['orderId'],
          billingName: values['billingName'],
          orderdate: values['orderdate'],
          total: values['total'],
          paymentStatus: values['paymentStatus'],
          paymentMethod: values['paymentMethod'],
          badgeclass: values['badgeclass'],
        };
        // save new order
        dispatch(onAddNewCommunication(newCommunication));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  const selectEcommerceState = (state) => state.communication;
  const CommunicationProperties = createSelector(
    selectEcommerceState,
    (communication) => ({
      groups: [],
      // TO-DO MAKE SURE TO => return this for live data
      // groups: Ecommerce.groups,
      loading: communication.loading,
    }),
  );

  const { groups, loading } = useSelector(CommunicationProperties);

  const [isLoading, setLoading] = useState(loading);

  useEffect(() => {
    mutateCommunications({
      ...pagination,
      pageNumber: pagination.pageNumber - 1,
    });
  }, [pagination]);

  useEffect(() => {
    mutateCommunicationCounts({
      fromDate: moment().startOf('month').format('DD-MM-YYYY'),
      toDate: moment().format('DD-MM-YYYY'),
      wardIds: getSearchColumns()?.find((t) => t.name === 'wardIds')?.value,
      address: addresses[0],
    });
  }, []);

  const onChangePagination = ({ page, limit }, filters, sorter) => {
    let searchColumns = isEmpty(filters)
      ? []
      : [
          filters?.id && {
            id: filters?.id,
          },
          filters?.applicationNumber && {
            applicationNumber: filters?.applicationNumber,
          },
        ];
    setPagination({
      ...pagination,
      pageNumber: page || pagination.pageNumber,
      pageSize: limit || pagination.pageSize,
      searchColumns,
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setCommunication(null);
    } else {
      setModal(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
      },
      {
        Header: '#',
        accessor: 'id',
        width: '150px',
        style: {
          textAlign: 'center',
          width: '10%',
          background: '#0000',
        },
        filterable: true,
        Cell: (cellProps) => {
          return <CommunicationId {...cellProps} />;
        },
      },
      {
        Header: 'RECIPIENT',
        accessor: (row) =>
          `${row?.member?.firstName || ''} ${row?.member?.lastName || ''}`,
        filterable: true,
        Cell: (cellProps) => {
          return <Recipient {...cellProps} />;
        },
      },
      {
        Header: 'MESSAGE TYPE',
        accessor: 'messageType',
        filterable: true,
        Cell: (cellProps) => {
          return <MessageType {...cellProps} />;
        },
      },
      {
        Header: 'DELIVERY METHOD',
        accessor: 'deliveryMethod',
        filterable: true,
        Cell: (cellProps) => {
          return <DeliveryMethod {...cellProps} />;
        },
      },
      {
        Header: 'CONTENT',
        accessor: (row) => `${row?.message.slice(0, 50) + '...'}`,
        filterable: true,
        Cell: (cellProps) => {
          return <Content {...cellProps} />;
        },
      },
      {
        Header: 'DATE SENT',
        accessor: 'sentDateTime',
        Cell: (cellProps) => {
          const dateValue = cellProps.value;
          const formattedDate = dateValue
            ? moment(dateValue).format('YYYY MMM DD h:mm A')
            : '';
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
    ],
    [],
  );

  const onSearch = (data) => {
    const searchColumns = [];
    if (!isEmpty(data)) {
      if (isFacilitator) {
        searchColumns.push({ name: 'wardId', value: address?.wardId });
      }
      if (isSiteCord && data.wardId) {
        searchColumns.push(data?.wardId);
      } else {
        if (
          (isClusterLeader ||
            isFinance ||
            isDirector ||
            isAdmin ||
            isSuperAdmin) &&
          data?.regionId
        ) {
          searchColumns.push({ name: 'regionId', value: data?.regionId });
        }
        if (data.wardId) {
          searchColumns.push(data?.wardId);
        }
        if (data.constituencyId) {
          searchColumns.push(data?.constituencyId);
        }
        if (data.countyId) {
          searchColumns.push(data?.countyId);
        }
      }
      data.startDate && searchColumns.push(data.startDate);
      data.endDate && searchColumns.push(data.endDate);
    }

    setPagination({ ...pagination, searchColumns });

    let add = {};
    searchColumns.forEach((a, i) => {
      add[a.name] = a.value;
    });
    setAddressData(add);
  };

  return (
    <React.Fragment>
      <CommunicationsModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Communications" />
          <Row className="d-flex justify-content-between">
            {/* Reports Render */}
            {communicastionsCardData.map((report, key) => (
              <Col md="2" key={'_col_' + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{report.title}</p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              'bx ' + report.iconClass + ' font-size-24'
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <MainFilter onSearch={onSearch} showDateFilter />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={communicationsData?.list || []}
                      isGlobalFilter={true}
                      isAddOptions={true}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      // hasCreate
                      // showEdit
                      // showView
                      pages={pages}
                      resource="communication"
                      addText="Send New Communication"
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                      paginationData={pagination}
                      onChangePagination={onChangePagination}
                      searchByNames={[
                        {
                          value: 'email',
                          text: t('common.email'),
                        },
                        {
                          value: 'idNumber',
                          text: t('common.idNumber'),
                        },
                        {
                          value: 'applicationNumber',
                          text: t('applications.applicationNumber'),
                        },
                      ]}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? 'Edit Communication' : 'Add Communication'}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Communication Id</Label>
                      <Input
                        name="orderId"
                        type="text"
                        placeholder="Insert Communication Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.orderId || ''}
                        invalid={
                          validation.touched.orderId &&
                          validation.errors.orderId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.orderId &&
                      validation.errors.orderId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.orderId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ''}
                        invalid={
                          validation.touched.billingName &&
                          validation.errors.billingName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.billingName &&
                      validation.errors.billingName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.billingName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Communication Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="orderdate"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) =>
                          validation.setFieldValue(
                            'orderdate',
                            moment(date[0]).format('DD MMMM, YYYY'),
                          )
                        }
                        value={validation.values.orderdate}
                      />
                      {validation.touched.orderdate &&
                      validation.errors.orderdate ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.orderdate}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ''}
                        invalid={
                          validation.touched.total && validation.errors.total
                            ? true
                            : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">
                          {validation.errors.total}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentStatus || ''}
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus &&
                      validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentStatus}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ''}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass &&
                      validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.badgeclass}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentMethod || ''}
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod &&
                      validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentMethod}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Communication.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default Communication;

import {
  SET_CLAIM_SUMMARY,
  SET_GROUP_SUMMARY,
  SET_MEMBERS_SUMMARY,
  SET_APPLICATIONS_SUMMARY,
  SET_PAYMENTS_SUMMARY,
  SET_TRANSFER_SUMMARY,
} from './actionTypes';

export const setApplicationSummary = (data) => {
  return {
    type: SET_APPLICATIONS_SUMMARY,
    payload: data,
  };
};

export const setPaymentSummary = (data) => {
  return {
    type: SET_PAYMENTS_SUMMARY,
    payload: data,
  };
};

export const setClaimSummary = (data) => ({
  type: SET_CLAIM_SUMMARY,
  payload: data,
});

export const setTransferSummary = (data) => ({
  type: SET_TRANSFER_SUMMARY,
  payload: data,
});

export const setMemberSummary = (data) => ({
  type: SET_MEMBERS_SUMMARY,
  payload: data,
});

export const setGroupSummary = (data) => ({
  type: SET_GROUP_SUMMARY,
  payload: data,
});

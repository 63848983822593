import {
  GET_TRANSATIONS,
  GET_TRANSATIONS_FAIL,
  GET_TRANSATIONS_SUCCESS,
  ADD_NEW_TRANSATION,
  ADD_TRANSATION_SUCCESS,
  ADD_TRANSATION_FAIL,
  UPDATE_TRANSATION,
  UPDATE_TRANSATION_SUCCESS,
  UPDATE_TRANSATION_FAIL,
  DELETE_TRANSATION,
  DELETE_TRANSATION_SUCCESS,
  DELETE_TRANSATION_FAIL,
} from './actionTypes';

export const getTransactions = () => ({
  type: GET_TRANSATIONS,
});

export const getTransactionsSuccess = (orders) => ({
  type: GET_TRANSATIONS_SUCCESS,
  payload: orders,
});

export const getTransactionsFail = (error) => ({
  type: GET_TRANSATIONS_FAIL,
  payload: error,
});

export const addNewTransaction = (order) => ({
  type: ADD_NEW_TRANSATION,
  payload: order,
});

export const addTransactionSuccess = (order) => ({
  type: ADD_TRANSATION_SUCCESS,
  payload: order,
});

export const addTransactionFail = (error) => ({
  type: ADD_TRANSATION_FAIL,
  payload: error,
});

export const updateTransaction = (order) => ({
  type: UPDATE_TRANSATION,
  payload: order,
});

export const updateTransactionSuccess = (order) => ({
  type: UPDATE_TRANSATION_SUCCESS,
  payload: order,
});

export const updateTransactionFail = (error) => ({
  type: UPDATE_TRANSATION_FAIL,
  payload: error,
});

export const deleteTransaction = (order) => ({
  type: DELETE_TRANSATION,
  payload: order,
});

export const deleteTransactionSuccess = (order) => ({
  type: DELETE_TRANSATION_SUCCESS,
  payload: order,
});

export const deleteTransactionFail = (error) => ({
  type: DELETE_TRANSATION_FAIL,
  payload: error,
});

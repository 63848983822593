import { data } from 'autoprefixer';
import { get, post } from './utils';

export async function getAllClaimApi(data) {
  return post('/api/claim/search', data);
}

export async function getClaimDetailsByIdApi(data) {
  return get(`/api/claim/id`, data);
}

export async function approveClaimApi(data) {
  return post(`/api/claim/status/approve`, data);
}

export async function rejectClaimApi(data) {
  return post(`/api/claim/status/reject`, data);
}

export async function addClaim(data) {
  return post(`/api/claim/save`, data);
}

export async function getClaimAttachmentsByIdApi(id) {
  return get(`/api/claim/attachment/?claimId=${id}`);
}

// export async function getAssignedClaimsByAreaIdApi(id)
//  {
//   return get(`/claim/status/assigned?wardId=${id}`);
// }

export async function getAssignedClaimsByAreaIdApi(params) {
  let endpoint = '/api/claim/status/assigned?';

  Object.entries(params).forEach(([key, value]) => {
    endpoint += `${key}=${value}&`;
  });

  if (endpoint.endsWith('&')) {
    endpoint = endpoint.slice(0, -1);
  }

  return get(endpoint);
}

export async function getDependentsByIdApi(memberId) {
  try {
    const response = await get(`/api/um/dependent/?memberId=${memberId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching dependents:', error);
    throw error;
  }
}

export async function getNextOfKinByIdApi(memberId) {
  try {
    const response = await get(`/api/um/nextofkin/?memberId=${memberId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching nextOfKin:', error);
    throw error;
  }
}

export async function uploadMedia(entityId, data, type) {
  let url = `${process.env.REACT_APP_SERVER_URL}/shofco/api/claim/attachment/add?claimId=${entityId}&type=${type}`;
  const formData = new FormData();
  formData.append('file', data);
  const token = localStorage.getItem('sessionToken');
  if (token) {
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }).then((res) => res.json());
  }
}

import React, { useState, useEffect } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Table,
  Input,
  Label,
  Row,
} from 'reactstrap';
import logo from '../../assets/images/logo-dark2.png';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getReceiptDetailsByIdApi } from 'api/transaction';
import { getMemberByIdApi } from 'api/member';
import Barcode from 'react-barcode';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const ReceiptDetails = () => {
  const [receiptData, setReceiptData] = useState({});
  const [memberData, setMemberData] = useState({});

  const { id } = useParams();

  useEffect(() => {
    const fetchReceiptDetails = async () => {
      try {
        const response = await getReceiptDetailsByIdApi(id);
        setReceiptData(response);
        const memberId = response?.invoiceDTO?.toMemberId;
        if (memberId) {
          const memberResponse = await getMemberByIdApi(memberId);
          setMemberData(memberResponse);
        }
      } catch (error) {
        console.error('Error fetching receipt details:', error);
      }
    };

    fetchReceiptDetails();
  }, [id]);

  const downloadReceipt = () => {
    const receiptElement = document.getElementById('receipt-content');

    html2canvas(receiptElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${receiptData?.receiptNumber}.pdf`);
    });
  };

  //meta title
  document.title = 'Receipt Details | SUN Welfare Administration System';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Receipts" breadcrumbItem="Receipt Details" />
          <Link to="/receipts">
            <Button type="button" color="primary" className="btn">
              <i className="bx bx-arrow-back"></i> Back
            </Button>
          </Link>
          <Row>
            <Col md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
              <Button color="primary" onClick={downloadReceipt}>
                Download Receipt
              </Button>
            </Col>
          </Row>
          <Card
            id="receipt-content"
            style={{ maxWidth: '40%', margin: '0 auto' }}
          >
            <CardBody>
              <Row>
                <Col md={12}>
                  <img
                    src={logo}
                    alt="Company Logo"
                    style={styles.receiptLogo}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p style={styles.receiptDate}>
                    {moment(receiptData?.receiptDateTime).format(
                      'ddd, MMM DD, YYYY · h:mm A',
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p style={styles.receiptToken}>
                    {receiptData?.receiptNumber}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6} style={styles.receiptMDetails}>
                  SHOFCO ID:
                </Col>
                <Col md={6} style={styles.receiptMDetails}>
                  {memberData?.shofcoId}
                </Col>
              </Row>
              <Row>
                <Col md={12} style={styles.receiptDottedLine}></Col>
              </Row>
              <Row>
                <Col md={6} style={styles.receiptDetails}>
                  Payment Method:
                </Col>
                <Col md={6} style={styles.receiptDetails}>
                  {receiptData?.paymentMethod}
                </Col>
              </Row>
              <Row>
                <Col md={6} style={styles.receiptDetails}>
                  Member Name:
                </Col>
                <Col md={6} style={styles.receiptDetails}>
                  {`${memberData?.firstName || ''} ${
                    memberData?.middleName || ''
                  } ${memberData?.lastName || ''}`}
                </Col>
              </Row>
              <Row>
                <Col md={6} style={styles.receiptDetails}>
                  Member Group:
                </Col>
                <Col md={6} style={styles.receiptDetails}>
                  {memberData?.groupName || 'Individual Member'}
                </Col>
              </Row>
              <Row>
                <Col md={6} style={styles.receiptDetails}>
                  Address:
                </Col>
                <Col md={6} style={styles.receiptDetails}>
                  {`${memberData?.address?.wardName || ''}, ${
                    memberData?.address?.constituencyName || ''
                  }`}
                  <br />
                  {memberData?.address?.countyName}
                </Col>
              </Row>
              <Row>
                <Col md={12} style={styles.receiptDottedLine}></Col>
              </Row>
              <Row>
                <Col md={6} style={styles.receiptMDetails}>
                  Operator:
                </Col>
                <Col md={6} style={styles.receiptMDetails}>
                  SUN WELFARE
                </Col>
              </Row>
              <Row>
                <Col md={12} style={styles.receiptDottedLine}></Col>
              </Row>
              <Row>
                <Col md={6} style={styles.receiptDetails}>
                  Amount:
                </Col>
                <Col md={6} style={styles.receiptDetails}>
                  KES. {receiptData?.amount}
                </Col>
              </Row>
              <Row>
                <Col md={6} style={styles.receiptDetails}>
                  Tax:
                </Col>
                <Col md={6} style={styles.receiptDetails}>
                  KES. 00.00
                </Col>
              </Row>
              <Row>
                <Col md={6} style={styles.receiptDetails}>
                  Discount:
                </Col>
                <Col md={6} style={styles.receiptDetails}>
                  KES. 00.00
                </Col>
              </Row>
              <Row>
                <Col md={6} style={styles.receiptMDetails}>
                  Total:
                </Col>
                <Col md={6} style={styles.receiptMDetails}>
                  KES. {receiptData?.amount}
                </Col>
              </Row>
              <Row>
                <Col md={6} style={styles.receiptDetails}>
                  Being Payment For:
                </Col>
                <Col md={6} style={styles.receiptDetails}>
                  {moment(receiptData?.receiptDateTime).format('MMMM')} SUN
                  Premium Payment
                </Col>
              </Row>
              <Row>
                <Col md={12} style={styles.receiptDottedLine}></Col>
              </Row>
              <Row>
                <Col md={6} style={styles.thankText}>
                  THANK YOU
                </Col>
              </Row>
              <Row>
                <Col md={12} style={styles.receiptDottedLine}></Col>
              </Row>
              <Row style={styles.barcodeContainer}>
                <Col md={12}>
                  <Barcode value={receiptData?.receiptNumber} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const styles = {
  receiptContainer: {
    maxWidth: '350px',
    margin: '0 auto',
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  receiptCard: {
    border: 'none',
    padding: '0',
    margin: '0',
  },
  receiptLogo: {
    maxWidth: '200px',
    margin: '0 auto',
    display: 'block',
  },
  thankText: {
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
    maxWidth: '400px',
    margin: '0 auto',
    display: 'block',
  },
  receiptDate: {
    fontSize: '12px',
    color: '#666',
    textAlign: 'center',
    marginTop: '10px',
  },
  receiptToken: {
    fontWeight: 'bold',
    fontSize: '16px',
    border: '1.5px dashed #000',
    borderRadius: '9px',
    padding: '10px',
    textAlign: 'center',
    margin: '20px 60px',
  },
  receiptDetails: {
    fontSize: '14px',
    margin: '5px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  receiptMDetails: {
    fontWeight: 'BOLD',
    fontSize: '14px',
    margin: '5px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  receiptDottedLine: {
    borderBottom: '1px dotted rgba(0, 0, 0, 0.5)',
    margin: '10px Auto',
    maxWidth: '95%',
  },
  receiptCompanyName: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '30px',
  },
  receiptBackButton: {
    display: 'block',
    width: '100%',
    marginTop: '20px',
  },
  barcodeContainer: {
    textAlign: 'center',
    marginTop: '10px',
  },
};

export default ReceiptDetails;

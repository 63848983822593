/* COVERS */
export const GET_COVERS = 'GET_COVERS';
export const GET_COVERS_SUCCESS = 'GET_COVERS_SUCCESS';
export const GET_COVERS_FAIL = 'GET_COVERS_FAIL';

/**
 * add COVER
 */
export const ADD_NEW_COVER = 'ADD_NEW_COVER';
export const ADD_COVER_SUCCESS = 'ADD_COVER_SUCCESS';
export const ADD_COVER_FAIL = 'ADD_COVER_FAIL';

/**
 * Edit COVER
 */
export const UPDATE_COVER = 'UPDATE_COVER';
export const UPDATE_COVER_SUCCESS = 'UPDATE_COVER_SUCCESS';
export const UPDATE_COVER_FAIL = 'UPDATE_COVER_FAIL';

/**
 * Delete COVER
 */
export const DELETE_COVER = 'DELETE_COVER';
export const DELETE_COVER_SUCCESS = 'DELETE_COVER_SUCCESS';
export const DELETE_COVER_FAIL = 'DELETE_COVER_FAIL';

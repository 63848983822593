import React from 'react';
import ReactApexChart from 'react-apexcharts';
import getChartColorsArray from '../../components/Common/ChartsDynamicColor';

const Spinearea = ({
  dataColors,
  claims = [],
  premiums = [],
  members = [],
}) => {
  const spineareaChartColors = getChartColorsArray(dataColors);
  const series = [
    {
      name: 'Premium Paid',
      data: premiums?.map((p) => p?.totalPremiums) || [],
    },
    {
      name: 'Claims Reported',
      data: claims?.map((c) => c?.totalClaims) || [],
    },
    {
      name: 'New Members',
      data: members?.map((m) => m?.newMembers) || [],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },

    colors: spineareaChartColors,
    xaxis: {
      type: 'datetime',
      categories: claims.map((c) => c.monthEnd) || [],
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    tooltip: {
      x: {
        format: 'MMM',
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
    />
  );
};

export default Spinearea;

import { get, post } from './utils';

export async function getNewApplicationsApi(data) {
  return post(`/api/um/workflow/search`, data);
}

export async function approveApplicationApi(data) {
  return post(`/api/um/workflow/approveApplication`, data);
}

export async function rejectApplicationApi(data) {
  return post(`/api/um/workflow/rejectApplication`, data);
}

export async function getApplicationApi(data) {
  return get(`/api/um/workflow/id`, data);
}

export async function getClaimByIdApi(data) {
  return get(`/api/um/workflow/all/1`);
}

export async function getApplicationByIdApi(data) {
  return get(`/api/um/workflow/id`, data);
}

import { get, post, put } from './utils';

export async function getAllGroupApi(data) {
  return post(`/api/public/um/group/search`, data);
}
export async function getGroupByIdApi(data) {
  return get(`/api/public/um/group/all/1`);
}

export async function getGroupDetailsByIdApi(groupId) {
  return get(`/api/um/group/id?groupId=${groupId}`);
}

export async function addGroup(data) {
  return post(`/api/um/group/register`, data);
}

export async function updateGroup(data) {
  return put(`/api/um/group/update`, data);
}

export async function getAssignedTransfersApi(data) {
  return get(`/api/um/member/assigned`, data);
}

export async function getAssignedGroupTransfersApi(data) {
  return get(`/api/um/group/assigned`, data);
}

export async function getAllGroupTransfersApi(data) {
  return post(`/api/um/member/transfer/pendingApprovalList`, data);
}

export async function getAllGroupMembersApi(data) {
  return get(`/api/um/group/member/all`, data);
}

export async function memberTransferRequestApi(data) {
  return post(`/api/um/member/transfer`, data);
}

export async function groupTransferRequestApi(data) {
  return post(`/api/um/group/transfer`, data);
}

export async function getGroupByNameApi(name) {
  return get('/api/um/group/name?groupName=' + name);
}

export async function getTransferByIdApi(transferId) {
  return get(`/api/um/member/transfer/id?id=${transferId}`);
}
export async function getGroupTransferByIdApi(data) {
  return get(`/api/um/group/transfer/id`, data);
}

export async function memberTransferApproveApi(data) {
  return post(`/api/um/member/transfer/approve`, data);
}
export async function groupTransferApproveApi(data) {
  return post(`/api/um/group/transfer/approve`, data);
}

export async function memberTransferRejectApi(data) {
  return post(`/api/um/member/transfer/reject`, data);
}
export async function groupTransferRejectApi(data) {
  return post(`/api/um/group/transfer/reject`, data);
}

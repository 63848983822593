import React, { useEffect, useMemo, useState } from 'react';

import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';

import classnames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { has, every, partial, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useMutation, useQueryClient } from 'react-query';

import { loginUser, setMember, editMember } from 'store/actions';
import { useRole } from 'hooks/useRole';
import { getAllRelationshipsApi } from 'api/report';
import { getMemberByIdApi, updateMember } from 'api/member';
// import AddressTab from './wizard/AddressTab';
import MemberDetailsTab from './wizard/MemberDetailsTab';
import AddNextOfKin from './wizard/AddNextOfKin';
import AddDependants from './wizard/AddDependant';
import CompleteRegister from './wizard/CompleteRegister';

const FormWizard = () => {
  const {
    isFacilitator,
    isSiteCord,
    isCountyCoordinator,
    isClusterLeader,
    isFinance,
    isDirector,
    isAdmin,
    isSuperAdmin,
    isData,
    addresses,
  } = useRole();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [memberId, setMemberId] = useState(id);
  const [memberData, setMemberData] = useState(null);
  const [nextOfKin, setNextOfKin] = useState(null);
  const [notNew, setNotNew] = useState(!!id);

  //meta title
  document.title = 'Member Form Wizard | SUN Welfare Administration System';

  const [address, setAddress] = useState({});

  const [activeTab, setactiveTab] = useState(1);

  const [passedSteps, setPassedSteps] = useState([1]);

  const queryClient = useQueryClient();

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    if (activeTab === 4 && tab === 5) {
      const data = {
        ...member,
        status: 'Pending',
      };
      updateMember(data)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const {
    mutate: mutateMember,
    isLoading: isLoadingMember,
    data: member,
  } = useMutation((payload) => getMemberByIdApi(payload), {
    onSuccess: (res) => {
      setMember(res);
      setAddress(res?.addresses?.[0]);
    },
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  const {
    mutate: mutateRelationships,
    isLoading: isLoadingRelationships,
    data: relationshipsData,
  } = useMutation((payload) => getAllRelationshipsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  useEffect(() => {
    memberId && mutateMember(memberId);
  }, [memberId]);

  useEffect(() => {
    mutateRelationships();
  }, []);

  const onChange = (e, key) => {
    const value = e.target.value;
    if (key === 'nextOfKin') {
      dispatch(editMember({ [e.target.name]: value }, 'nextOfKin'));
    } else if (key === 'address') {
      dispatch(editMember({ [e.target.name]: value }, 'address'));
    } else {
      dispatch(editMember({ [e.target.name]: value }, 'member'));
    }
  };

  const onChangeAddress = (values) => {
    setAddress({ ...address, ...values });
  };

  const onGoNext = () => {
    toggleTab(activeTab + 1);
  };

  const onChangeMemberId = (id) => {
    setMemberId(id);
  };
  const onSetMember = (data) => {
    setMemberData(data);
  };
  const isDisabled =
    activeTab === 4 ||
    (activeTab === 1 && isEmpty(member)) ||
    (activeTab === 2 && !nextOfKin) ||
    (activeTab === 1 &&
      !every(
        ['constituencyId', 'countyId', 'wardId', 'regionId'],
        partial(has, notNew ? member?.addresses[0] : {}),
      )) ||
    (activeTab === 2 &&
      !every(
        ['constituencyId', 'countyId', 'wardId', 'regionId'],
        partial(has, notNew ? member?.addresses[0] : {}),
      ));
  const steps = [
    // { step: 1, label: 'Address Details', check: () => true, disableCheck: 1 },
    {
      step: 1,
      label: 'Member Details',
      check: () => address?.wardId,
      disableCheck: 1,
    },
    {
      step: 2,
      label: 'Next Of Kin Details',
      check: () => address?.wardId && member?.id,
      disableCheck: 2,
    },
    {
      step: 3,
      label: 'Dependant Details',
      check: () => address?.wardId && member?.id,
      disableCheck: 3,
    },
    { step: 4, label: 'Confirm', check: () => true, disableCheck: 4 },
  ];

  const renderNavItem = (step, label, check, disableCheck) => (
    <NavItem className={classnames({ current: activeTab === step })} key={step}>
      <NavLink
        className={classnames({ active: activeTab === step })}
        onClick={() => check() && setactiveTab(step)}
        disabled={!(passedSteps || []).includes(disableCheck)}
      >
        <span className="number">{step}.</span> {label}
      </NavLink>
    </NavItem>
  );

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumbs title="Mmember" breadcrumbItem="New Member" />
        <Row>
          <Link to="/members">
            <Button type="button" color="primary" className="btn">
              <i className="bx bx-arrow-back"></i> Back
            </Button>
          </Link>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4 className="card-title mb-4">Add/Edit Member</h4>
                </div>
                <div className="wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      {steps.map(({ step, label, check, disableCheck }) =>
                        renderNavItem(step, label, check, disableCheck),
                      )}
                      {/* <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Member Region
                          </NavLink>
                        </NavItem> */}
                      {/* <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              address?.wardId ? setactiveTab(1) : () => { }
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">1.</span> Member Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              address?.wardId && member?.id && setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">2.</span> Next Of Kin Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              address?.wardId && member?.id && setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span> Dependant Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">4.</span> Confirm
                          </NavLink>
                        </NavItem> */}
                    </ul>
                  </div>
                  <div className="content clearfix">
                    <TabContent activeTab={activeTab} className="body">
                      {/* <TabPane tabId={1}>
                        <AddressTab isNew={notNew} onChangeAddress={onChangeAddress} member={member} />
                      </TabPane> */}
                      <TabPane tabId={1}>
                        <MemberDetailsTab
                          onChange={onChange}
                          member={member}
                          onChangeMemberId={onChangeMemberId}
                          onNext={onGoNext}
                          address={notNew ? member?.address : address}
                          onSetMember={onSetMember}
                          mutateMember={mutateMember}
                        />
                      </TabPane>
                      <TabPane tabId={2}>
                        <AddNextOfKin
                          onNext={onGoNext}
                          onChange={onChange}
                          onChangeMemberId={onChangeMemberId}
                          member={member}
                          memberData={memberData}
                          kin={nextOfKin}
                          setKin={setNextOfKin}
                          mutateMember={mutateMember}
                          relationshipsData={relationshipsData}
                        />
                      </TabPane>
                      <TabPane tabId={3}>
                        <AddDependants
                          onChange={onChange}
                          member={member}
                          relationshipsData={relationshipsData}
                          isNew={true}
                          onNext={onGoNext}
                        />
                      </TabPane>
                      <TabPane tabId={4}>
                        <CompleteRegister member={member} />
                      </TabPane>
                    </TabContent>
                  </div>
                  <div className="mx-20"></div>
                  <div className="actions clearfix">
                    <ul>
                      <li
                        className={
                          activeTab === 1 ? 'previous disabled' : 'previous'
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab - 1);
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                      <li className={isDisabled ? 'next disabled' : 'next'}>
                        <Link
                          to="#"
                          onClick={() => {
                            !isDisabled && toggleTab(activeTab + 1);
                          }}
                        >
                          {activeTab === 4 ? 'FINISH' : 'NEXT'}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FormWizard;

import React, { useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';
import { useRole } from 'hooks/useRole';

// MetisMenu
import MetisMenu from 'metismenujs';
import withRouter from 'components/Common/withRouter';
import { Link } from 'react-router-dom';

//i18n
import { withTranslation } from 'react-i18next';

const SidebarContent = (props) => {
  const {
    isFacilitator,
    isSiteCord,
    isCountyCoordinator,
    isClusterLeader,
    isFinance,
    isDirector,
    address,
    isAdmin,
    isSuperAdmin,
  } = useRole();

  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains('active')) {
        item.classList.remove('active');
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.remove('mm-show');
        }

        parent.classList.remove('mm-active');
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove('mm-show');

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('mm-active'); // li
            parent3.childNodes[0].classList.remove('mm-active');

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove('mm-show'); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('mm-show'); // li
                parent5.childNodes[0].classList.remove('mm-active'); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu('#side-menu');
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/#">
                <i className="bx bx-home-circle"></i>
                <span>{props.t('common.dashboard')}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-group"></i>
                <span>{props.t('common.memberManager')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#" className="has-arrow">
                    <span>{props.t('applications.header')}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/applications">
                        {props.t('Principal Members')}
                      </Link>
                    </li>
                    {(isSiteCord || isDirector || isSuperAdmin || isAdmin) && (
                      <li>
                        <Link to="/kin-applications">
                          {props.t('Next Of Kin')}
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link to="/new-dependents">{props.t('Dependants')}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow">
                    <span>{props.t('members.header')}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/members">{props.t('Principal Members')}</Link>
                    </li>
                    <li>
                      <Link to="/member-transfers">
                        {props.t('Member Transfers')}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow">
                    <span>{props.t('groups.header')}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/groups">{props.t('All Groups')}</Link>
                    </li>
                    <li>
                      <Link to="/groupTransfers">
                        {props.t('Group Transfers')}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-basket"></i>
                <span>{props.t('common.welfareProducts')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/products">{props.t('products.header')}</Link>
                </li>
                <li>
                  <Link to="/categories">{props.t('categories.header')}</Link>
                </li>
                <li>
                  <Link to="/risk-class">{props.t('riskClass.header')}</Link>
                </li>
                <li>
                  <Link to="/cover">{props.t('covers.header')}</Link>
                </li>
                <li>
                  <Link to="/benefit-types">
                    {props.t('benefitTypes.header')}
                  </Link>
                </li>
                <li>
                  <Link to="/benefits">{props.t('benefits.header')}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/transactions" className="has-arrow">
                <i className="bx bx-transfer"></i>
                <span>{props.t('transactions.header')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/payments">{props.t('payments.header')}</Link>
                </li>
                <li>
                  <Link to="/invoices">{props.t('invoices.header')}</Link>
                </li>
                <li>
                  <Link to="/receipts">{props.t('receipts.header')}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/claims">
                <i className="bx bx-file"></i>
                <span>{props.t('claims.header')}</span>
              </Link>
            </li>

            <li>
              <Link to="/reports" className="has-arrow">
                <i className="bx bx-chart"></i>
                <span>{props.t('reports.header')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/reports/member-reports">
                    {props.t('reports.memberReports')}
                  </Link>
                </li>
                <li>
                  <Link to="/reports/group-reports">
                    {props.t('reports.groupReports')}
                  </Link>
                </li>
                <li>
                  <Link to="/reports/claim-reports">
                    {props.t('reports.claimReports')}
                  </Link>
                </li>
                <li>
                  <Link to="/reports/payment-reports">
                    {props.t('reports.paymentReports')}
                  </Link>
                </li>
                {!isFacilitator && !isSiteCord && (
                  <li>
                    <Link to="/reports/map">{props.t('map.header')}</Link>
                  </li>
                )}
              </ul>
            </li>

            <li>
              <Link to="/communications">
                <i className="bx bx-message"></i>
                <span>{props.t('communications.header')}</span>
              </Link>
            </li>
            {(isAdmin || isSuperAdmin) && (
              <>
                <li className="menu-title">{props.t('common.configPanel')}</li>

                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-user"></i>
                    <span>{props.t('users.userManagement')}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/users">{props.t('users.header')}</Link>
                    </li>
                    <li>
                      <Link to="/roles">{props.t('roles.header')}</Link>
                    </li>
                  </ul>
                </li>

                {/* <li>
                  <Link to="/settings" >
                    <i className="bx bx-cog"></i>
                    <span>{props.t("common.settings")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/product-settings" >
                    <i className="bx bx-box"></i>
                    <span>{props.t("common.productSettings")}</span>
                  </Link>
                </li> */}

                <li>
                  <Link to="/audit-logs">
                    <i className="bx bx-list-check"></i>
                    <span>{props.t('common.auditLogs')}</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));

import { loginApi, getCurrentUserApi } from 'api/auth';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { StoreContext } from './useStore';
import { ROLES } from 'configs/constants';

export const useAuth = () => {
  const { dispatch, state } = useContext(StoreContext);
  const [lerror, setLerror] = useState(null);
  const queryClient = useQueryClient();

  const setUser = useCallback((data) => {
    dispatch({
      type: 'setUser',
      payload: data,
    });
  }, []);

  const logout = useCallback(() => {
    dispatch({
      type: 'logout',
    });
    window.location = '/';
  }, []);

  const loginMutation = useMutation((payload) => loginApi(payload), {
    onSuccess: (res) => {
      if (res.addresses.length && Object.values(ROLES).includes(res.role)) {
        dispatch({
          type: 'loginSuccess',
          payload: res,
        });
      } else {
        setLerror('Account Error - Please contact support');
        dispatch({
          type: 'loginFail',
        });
      }
    },
    onError: () => {
      dispatch({
        type: 'loginFail',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries('auth/signin');
    },
  });

  const login = async (data) => {
    try {
      dispatch({
        type: 'login',
      });
      await loginMutation.mutateAsync(data);
    } catch (error) {
      //
    }
  };

  const getCurrentUser = async (data) => {
    try {
      dispatch({
        type: 'currentUser',
      });
      await currentUserMutation.mutateAsync(data);
    } catch (error) {
      //
    }
  };

  return {
    user: state.user,
    login,
    logout,
    setUser,
    isAuthenticated: state.isAuthenticated,
    loading: state.loading,
    loginError: loginMutation.error,
    lerror,
    roleId: state.roleId,
  };
};

export const useHasRole = (roles) => {
  const { state } = useContext(StoreContext);
  const [hasRole, setHasRole] = useState(false);

  useEffect(() => {
    setHasRole(!!roles?.find((e) => e === state.user?.role));
  }, [state.user]);

  return hasRole || !roles;
};

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

import {
  addNewClaim as onAddNewClaim,
  updateClaim as onUpdateClaim,
  setClaimSummary,
} from 'store/actions';

import { PaymentStatus } from './ClaimCol';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import ClaimsModal from './ClaimModal';

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from 'reactstrap';
import moment from 'moment';
import { useAuth } from 'hooks/useAuth';
import { getSearchColumns, sumArray } from 'utils/tools';
import { ToastContainer } from 'react-toastify';
import { getAllClaimApi } from 'api/claim';
import { getClaimStatusCountsApi } from 'api/report';
import { useRole } from 'hooks/useRole';
import MainFilter from 'components/Common/MainFilter';

function Claim() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const [pages, setPages] = useState(0);
  const queryClient = useQueryClient();
  const {
    isFacilitator,
    isSiteCord,
    isCountyCoordinator,
    isClusterLeader,
    isFinance,
    isDirector,
    isAdmin,
    isSuperAdmin,
    isData,
    addresses,
  } = useRole();
  const { wardId, constituencyId, countyId, regionId } = isAuthenticated
    ? addresses[0]
    : {};
  const [addressData, setAddressData] = useState({});

  const { claimSummary } = useSelector((state) => ({
    claimSummary: state.reports.claims,
  }));

  const claimsCardData = [
    {
      title: 'Total Claims',
      iconClass: 'bx bx-user',
      description: claimSummary?.length
        ? sumArray(claimSummary, 'totalClaims')
        : 0,
    },
    {
      title: 'Approved',
      iconClass: 'bx bx-check-circle',
      description: claimSummary?.length
        ? sumArray(claimSummary, 'totalProcessed')
        : 0,
    },
    {
      title: 'Processing',
      iconClass: 'bx bx-pause-circle',
      description: claimSummary?.length
        ? sumArray(claimSummary, 'totalPending')
        : 0,
    },
    {
      title: 'Rejected',
      iconClass: 'bx bx-error-circle',
      description: claimSummary?.length
        ? sumArray(claimSummary, 'totalDeclined')
        : 0,
    },
  ];
  const dateFormat = 'DD-MM-YYYY';
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: false,
    wardIds: getSearchColumns()?.find((t) => t?.name === 'wardIds')?.value,
    searchColumns: [
      ...(isFacilitator
        ? [
            {
              name: 'wardId',
              value: wardId,
            },
          ]
        : []),
      ...(isCountyCoordinator
        ? [
            {
              name: 'countyId',
              value: countyId,
            },
          ]
        : []),
      ...(isClusterLeader
        ? [
            {
              name: 'regionId',
              value: regionId,
            },
          ]
        : []),
      ...(isSiteCord
        ? [
            {
              name: 'constituencyId',
              value: constituencyId,
            },
          ]
        : []),
      {
        name: 'endDate',
        value: moment().format(dateFormat),
      },
      {
        name: 'startDate',
        value: moment().startOf('month').format(dateFormat),
      },
    ],
  });

  //meta title
  document.title = 'Claims | SUN Welfare Administration System';

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [member, setClaim] = useState(null);

  const { mutate: mutateClaimCounts, data } = useMutation(
    (payload) => getClaimStatusCountsApi(payload),
    {
      onSuccess: (res) => {
        dispatch(setClaimSummary(res?.data));
      },
      onSettled: () => {
        queryClient.invalidateQueries('get-all-counties');
      },
    },
  );

  const {
    mutate: mutateClaims,
    isLoading: isLoadingClaims,
    data: claimsData,
  } = useMutation((payload) => getAllClaimApi(payload), {
    onSuccess: (res) => {
      res.pages > 0 && setPages(res.pages);
    },
    onSettled: () => {
      queryClient.invalidateQueries('get-all-counties');
    },
  });

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullNames: (member && member.fullNames) || '',
      reportedDate: (member && member.reportedDate) || '',
      groupName: (member && member.groupName) || '',
      deceasedName: (member && member.deceasedName) || '',
      status: (member && member.status) || 'Paid',
      badgeclass: (member && member.badgeclass) || 'success',
      memberStatus: (member && member.memberStatus) || 'Mastercard',
    },
    validationSchema: Yup.object({
      fullNames: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Claim Id')
        .required('Please Enter Your Claim Id'),
      reportedDate: Yup.string().required('Please Enter Your Billing Name'),
      groupName: Yup.string().required('Please Enter Your Claim Date'),
      deceasedName: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, 'Please Enter Valid Amount')
        .required('Total Amount'),
      status: Yup.string().required('Please Enter Your Payment Status'),
      badgeclass: Yup.string().required('Please Enter Your Badge Class'),
      memberStatus: Yup.string().required('Please Enter Your Payment Method'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateClaim = {
          id: member ? member.id : 0,
          fullNames: values.fullNames,
          reportedDate: values.reportedDate,
          groupName: values.groupName,
          deceasedName: values.deceasedName,
          status: values.status,
          memberStatus: values.memberStatus,
          badgeclass: values.badgeclass,
        };
        // update member
        dispatch(onUpdateClaim(updateClaim));
        validation.resetForm();
      } else {
        const newClaim = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          fullNames: values['fullNames'],
          reportedDate: values['reportedDate'],
          groupName: values['groupName'],
          deceasedName: values['deceasedName'],
          status: values['status'],
          memberStatus: values['memberStatus'],
          badgeclass: values['badgeclass'],
        };
        // save new member
        dispatch(onAddNewClaim(newClaim));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  const selectClaimState = (state) => state.members;
  const ClaimProperties = createSelector(selectClaimState, (Claim) => ({
    members: generateFakeData(),
    // TO-DO return this for live data
    // members: Claim.members,
    loading: Claim.loading,
  }));

  useEffect(() => {
    mutateClaimCounts({
      fromDate: pagination.searchColumns?.find((r) => r.name === 'startDate')
        ?.value,
      toDate: moment().format('DD-MM-YYYY'),
      address: addresses[0],
    });
  }, []);

  useEffect(() => {
    mutateClaims({ ...pagination, pageNumber: pagination.pageNumber - 1 });
  }, [pagination]);

  const onChangePagination = ({ page, limit }, filters, sorter) => {
    let searchColumns = isEmpty(filters)
      ? pagination.searchColumns
      : [
          ...pagination.searchColumns,
          ...(filters?.id
            ? [
                {
                  name: 'id',
                  value: filters?.id,
                },
              ]
            : []),
          ...(filters?.firstName
            ? [
                {
                  name: 'firstName',
                  value: filters?.firstName,
                },
              ]
            : []),
          ...(filters?.lastName
            ? [
                {
                  name: 'lastName',
                  value: filters?.lastName,
                },
              ]
            : []),
          ...(filters?.email
            ? [
                {
                  name: 'email',
                  value: filters?.email,
                },
              ]
            : []),
          ...(filters?.claimNumber
            ? [
                {
                  name: 'claimNumber',
                  value: filters?.claimNumber,
                },
              ]
            : []),
          ...(filters?.mobileNumber1
            ? [
                {
                  name: 'mobileNumber1',
                  value: filters?.mobileNumber1,
                },
              ]
            : []),
        ];
    setPagination({
      ...pagination,
      pageNumber: page || pagination.pageNumber,
      pageSize: limit || pagination.pageSize,
      searchColumns,
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setClaim(null);
    } else {
      setModal(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
      },
      {
        Header: 'ID',
        accessor: 'id',
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        },
      },
      {
        Header: 'PRINCIPAL MEMBER',
        accessor: (row) =>
          `${row.member?.firstName || ''} ${row.member.lastName || ''}`,
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        },
      },
      {
        Header: 'REPORTED DATE',
        accessor: 'reportedDateTime',
        filterable: true,
        Cell: (cellProps) => {
          return (
            <span>{moment(cellProps.value).format('YYYY MMM DD h:mm A')}</span>
          );
        },
      },
      {
        Header: 'Claim Number',
        accessor: 'claimNumber',
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        },
      },
      {
        Header: 'DECEASED NAME',
        accessor: (row) =>
          `${row.deathCertificate?.firstName || ''} ${
            row.deathCertificate?.lastName || ''
          }`,
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        },
      },
      {
        Header: 'APPROVAL STATUS',
        accessor: 'status',
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        },
      },
      {
        Header: 'APPROVED BY',
        accessor: 'claimStatus.destinationRole',
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        },
      },
    ],
    [],
  );

  const onSearch = (data) => {
    const searchColumns = [];
    if (!isEmpty(data)) {
      if (isFacilitator) {
        searchColumns.push({ name: 'wardId', value: address?.wardId });
      }
      if (isSiteCord && data.wardId) {
        searchColumns.push(data?.wardId);
      } else {
        if (
          (isClusterLeader ||
            isFinance ||
            isDirector ||
            isAdmin ||
            isSuperAdmin) &&
          data?.regionId
        ) {
          searchColumns.push(data?.regionId);
        }
        if (data.wardId) {
          searchColumns.push(data?.wardId);
        }
        if (data.constituencyId) {
          searchColumns.push(data?.constituencyId);
        }
        if (data.countyId) {
          searchColumns.push(data?.countyId);
        }
      }
      data.startDate && searchColumns.push(data.startDate);
      data.endDate && searchColumns.push(data.endDate);
    }

    setPagination({ ...pagination, searchColumns });

    let add = {};
    searchColumns.forEach((a, i) => {
      add[a.name] = a.value;
    });
    setAddressData(add);
  };

  return (
    <React.Fragment>
      <ClaimsModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Claims" />
          <Row>
            {/* Reports Render */}
            {claimsCardData.map((report, key) => (
              <Col md="3" key={'_col_' + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{report.title}</p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              'bx ' + report.iconClass + ' font-size-24'
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <MainFilter
            hasExport
            pagination={pagination}
            onSearch={onSearch}
            showDateFilter
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={claimsData?.list || []}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    hasCreate={!isData}
                    isPagination={true}
                    filterable={false}
                    iscustomPageSizeOptions={true}
                    // showDelete
                    hasSearch
                    // showEdit={!isData}
                    pages={pages}
                    showView
                    loading={isLoadingClaims}
                    resource="claim"
                    addText="Create New claim"
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    paginationData={pagination}
                    onChangePagination={onChangePagination}
                    searchByNames={[
                      {
                        value: 'claimNumber',
                        text: t('claims.claimNumber'),
                      },
                      {
                        value: 'email',
                        text: t('common.email'),
                      },
                      {
                        value: 'idNumber',
                        text: t('common.idNumber'),
                      },
                      {
                        value: 'firstName',
                        text: t('users.firstName'),
                      },
                      {
                        value: 'lastName',
                        text: t('users.lastName'),
                      },
                      {
                        value: 'mobileNumber1',
                        text: t('common.mobileNumber'),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? 'Edit Claim' : 'Add Claim'}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Claim Id</Label>
                      <Input
                        name="fullNames"
                        type="text"
                        placeholder="Insert Claim Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fullNames || ''}
                        invalid={
                          validation.touched.fullNames &&
                          validation.errors.fullNames
                            ? true
                            : false
                        }
                      />
                      {validation.touched.fullNames &&
                      validation.errors.fullNames ? (
                        <FormFeedback type="invalid">
                          {validation.errors.fullNames}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="reportedDate"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.reportedDate || ''}
                        invalid={
                          validation.touched.reportedDate &&
                          validation.errors.reportedDate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.reportedDate &&
                      validation.errors.reportedDate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.reportedDate}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Claim Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="groupName"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) =>
                          validation.setFieldValue(
                            'groupName',
                            moment(date[0]).format('DD MMMM, YYYY'),
                          )
                        }
                        value={validation.values.groupName}
                      />
                      {validation.touched.groupName &&
                      validation.errors.groupName ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.groupName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="deceasedName"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.deceasedName || ''}
                        invalid={
                          validation.touched.deceasedName &&
                          validation.errors.deceasedName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.deceasedName &&
                      validation.errors.deceasedName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.deceasedName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="status"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ''}
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ''}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass &&
                      validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.badgeclass}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="memberStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.memberStatus || ''}
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.memberStatus &&
                      validation.errors.memberStatus ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.memberStatus}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Claim.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default Claim;

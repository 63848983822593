/* BENEFITS */
export const GET_BENEFITS = 'GET_BENEFITS';
export const GET_BENEFITS_SUCCESS = 'GET_BENEFITS_SUCCESS';
export const GET_BENEFITS_FAIL = 'GET_BENEFITS_FAIL';

/**
 * add BENEFIT
 */
export const ADD_NEW_BENEFIT = 'ADD_NEW_BENEFIT';
export const ADD_BENEFIT_SUCCESS = 'ADD_BENEFIT_SUCCESS';
export const ADD_BENEFIT_FAIL = 'ADD_BENEFIT_FAIL';

/**
 * Edit BENEFIT
 */
export const UPDATE_BENEFIT = 'UPDATE_BENEFIT';
export const UPDATE_BENEFIT_SUCCESS = 'UPDATE_BENEFIT_SUCCESS';
export const UPDATE_BENEFIT_FAIL = 'UPDATE_BENEFIT_FAIL';

/**
 * Delete BENEFIT
 */
export const DELETE_BENEFIT = 'DELETE_BENEFIT';
export const DELETE_BENEFIT_SUCCESS = 'DELETE_BENEFIT_SUCCESS';
export const DELETE_BENEFIT_FAIL = 'DELETE_BENEFIT_FAIL';

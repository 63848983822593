import {
  GET_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAIL,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
} from './actionTypes';

const INIT_STATE = {
  products: [],
  product: {},
  groups: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true,
};

const Group = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        loading: true,
      };

    case GET_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_GROUP_SUCCESS:
      return {
        ...state,
        groups: [...state.groups, action.payload],
      };

    case ADD_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        groups: state.groups.map((group) =>
          group.id + '' === action.payload.id + ''
            ? { group, ...action.payload }
            : group,
        ),
      };

    case UPDATE_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        groups: state.groups.filter((group) => group.id !== action.payload),
      };
    case DELETE_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Group;

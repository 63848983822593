import { get, post } from './utils';

export async function getAllPolicyApi(data) {
  return get(`/api/policy/all`);
}
export async function getGroupByIdApi(data) {
  return get(`/api/public/um/group/all/1`);
}

export async function getMemberPolicyApi(data) {
  return get(`/api/policy/all`);
}
